import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "./product.css";
import axios from "axios";
import url from "../../Url";
import new_logo from "../../assets/images/icons/new-logo.png";
import { toast } from "react-toastify";
import { getFev } from "../../store/slices/FavSlices";
import { useDispatch, useSelector } from "react-redux";
import NoProductPage from "./NoProductPage";
import SidebarFilters from "../SidebarFilters";
import Pagination01 from "../Pagination01";
import SortBy from "../sortBy";
import ProductCard from "../ProductCard";

function BrandProductPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);

  const [Product, setProduct] = useState([]);
  const [showText, setShowText] = useState(false);
  const [fetchedProduct, setFetchedProduct] = useState([]);
  const [CatDetails, setCatDetails] = useState([]);
  const [brandName, setBrandName] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [refresh, setRefresh] = useState(false);

  const cardsPerPage = 20;
  const [currentItems, setCurrentItems] = useState([]);
  const [ParamsCatName, setParamsCatName] = useState("");
  const [selectedSortingOption, setSelectedSortingOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const [proLength, setproLength] = useState([]);
  const fevProduct = useSelector((state) => state.favroite.data);

  // ********************* filter option check names match the dictionary name/mongodb name *********************************************
  const [filters, setFilters] = useState({
    name: "",
    cat: "",
    sub_cat: "",
    minprice: "",
    maxprice: "",
    color_name: "",
    BrandName: "",
    length_cm: "",
    Collection_Name: "",
    installation_method: "",
    thickness: "",
    widthinch: "",
    lengthinch: "",
    wearlayerthickness: "",
    Species: "",
    Finish: "",
    Surface_Texture: "",
    ApproximateSize: "",
    Material: "",
    Onces: "",
    Density: "",
    Shape: "",
    edgeType: "",
    PEI_Rating: "",
    covrage: "",
    volime: "",
    ac: "",
  });

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 20,
  });
  //*** Pagination next or previous function ***

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo(0, 100);
    getProducts(filters, currentPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    window.scrollTo(0, 100);
    getProducts(filters, currentPage - 1);
  };
  //*** Sidebar filters function ***
  const handleFilterChange = (filterName, value) => {
    let tempFilter = { ...filters, [filterName]: value };
    setFilters(tempFilter);
    getProducts(tempFilter);
    setCurrentPage(1);
  };
  //*** Price filters function ***
  const priceFilterApply = (minPrice, maxPrice) => {
    console.log(minPrice, "minprice");
    console.log(maxPrice, "maxprice");
    let tempFilter = {
      ...filters,
      minprice: minPrice,
      maxprice: maxPrice,
    };
    setFilters(tempFilter);
    getProducts(tempFilter);
    setShowText(true);
  };
  const priceFilterClear = () => {
    setMaxPrice("");
    setMinPrice("");
    getProducts();
    setShowText(false);
  };
  useEffect(() => {
    getProductLength();
  }, []);
  useEffect(() => {
    getBrandById();
    // getProducts();
  }, [id]);
  //*** Get Products function ***
  const getProducts = async (tempFilters = filters, pageNo = 1) => {
    try {
      console.log("Fetching data with filters:", tempFilters);

      // Fetch data for the current page
      const response = await axios.get(`${url}/api/product/getAll`, {
        params: {
          ...tempFilters,
          page: pageNo,
          limit: pagination.limit,
        },
      });
      const products = response.data;
      console.log("Fetched products count:", products);
      const sortedProducts = products.data && products.data.sort(
        (a, b) => a.sale_price - b.sale_price
      );
      setProduct(products);
      setFetchedProduct(sortedProducts);
    } catch (error) {
      console.log(error, "err");
      toast.warn(error?.response?.data?.message,{   autoClose: 1500});
    }
  };
  //*** Get Brands by Id function ***
  const getBrandById = async () => {
    try {
      const response = await axios.get(`${url}/api/brand/get/${id}`);
      console.log("Response data:", response.data[0]);
      setBrandName(response.data);
      // getSUbCats(response.data[0]._id);
      getProducts({ ...filters, BrandName: response?.data[0]?.brand_name });
      setFilters({
        name: "",
        cat: "",
        sub_cat: "",
        minprice: "",
        maxprice: "",
        color_name: "",
        BrandName: response.data[0].brand_name,
        length_cm: "",
        Collection_Name: "",
        installation_method: "",
        thickness: "",
        widthinch: "",
        lengthinch: "",
        wearlayerthickness: "",
        Species: "",
        Finish: "",
        Surface_Texture: "",
        ApproximateSize: "",
        Material: "",
        Onces: "",
        Density: "",
        Shape: "",
        edgeType: "",
        PEI_Rating: "",
        covrage: "",
        volime: "",
        ac: "",
      });
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  //*** Get All Products Length function ***
  const getProductLength = async () => {
    try {
      // Fetch additional data (e.g., product length)
      const responseAllData = await axios.get(
        `https://allinonetables.com/api/product/getAll/productlength`,
        {
          params: {
            ...filters,
          },
        }
      );
      const resAllData = responseAllData.data.data;
      console.log("Additional data:", resAllData);
      setproLength(resAllData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //-------- SORTING PRODUCTS START ---------------------------
  const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedSortingOption(selectedOption);

    // Update the Product array based on the selected sorting option
    let sortedProducts = [...Product.data];
    console.log(fetchedProduct, "fetch");
    switch (selectedOption) {
      case "lowToHigh":
        sortedProducts = fetchedProduct.sort((a, b) => a.price - b.price);
        break;
      case "highToLow":
        sortedProducts = fetchedProduct.sort((a, b) => b.price - a.price);
        break;
      case "BestSeller":
        sortedProducts = sortedProducts.filter(
          (item) => item.BestSeller === true
        );
        break;
      case "MostView":
        sortedProducts = sortedProducts.filter(
          (item) => item.IsmostViewed === true
        );
        break;
      case "NewProduct":
        sortedProducts = sortedProducts.filter(
          (item) => item.newProduct === true
        );
        break;
      case "Default":
        {
          if (brandName[0]?.Type == "sub_cat") {
            setCurrentPage(1);
            setFilters({
              ...filters,
              sub_cat: ParamsCatName,
            });
            setCurrentPage(1);
          } else {
            setCurrentPage(1);
            setFilters({
              ...filters,
              cat: ParamsCatName,
            });
            setCurrentPage(1);
          }
        }
        break;
    }
    console.log(sortedProducts, "sorted");
    setProduct(sortedProducts);
  };
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}
      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {">"}
            <li>
              <Link to="/">Brand</Link>
            </li>
            {">"}
            <li>{brandName[0]?.brand_name}</li>
          </ul>
        </div>
      </div>

      {/* ************************** Heading ************************ */}

      <div className="container">
        <div className="heading">
          <h1>{brandName[0]?.name ? brandName[0]?.name : ""}</h1>
          <p>
            Explore our extensive collection of flooring products and tiles from
            the comfort of your home.
          </p>
        </div>
      </div>

      <div className="">
        <div className="container">
          <div className="row ">
            <div className="col-lg-3">
              {/* Sidebar Filters should go in this column */}
              <SidebarFilters
                onFilterChange={handleFilterChange}
                getMaxPrice={setMaxPrice}
                maxPrice={maxPrice}
                minPrice={minPrice}
                getMinPrice={setMinPrice}
                applyFunc={() => priceFilterApply(minPrice, maxPrice)}
                clearFunc={() => priceFilterClear()}
                resetPrice={showText}
                catDetails={CatDetails}
              />
            </div>
            <div className="col-lg-9">
              <div className="container ps-4 pe-4">
                <div className="shop-topbar-wrapper pb-2 ps-2 pe-2">
                  <div className="shop-topbar-left">
                    <div className="showing-item">
                      <span>{Product.total} Products listed</span>
                    </div>
                  </div>
                  <SortBy
                    selectedSortingOption={selectedSortingOption}
                    handleSortChange={handleSortChange}
                  />
                </div>
              </div>
              <ProductCard Product={Product.data} />
            </div>
            <Pagination01
              productLength={Product.total}
              onNextPage={handleNextPage}
              onPreviousPge={handlePrevPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandProductPage;
