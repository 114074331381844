import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const getCart = createAsyncThunk("cart/getUserCard", async () => {
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  try {
    const response = await axios.get(
      `https://allinonetables.com/api/cart/${userData.user.email}`
    );
    console.log(25, response.data);

    // Retrieve cart data from localStorage
    const storedCart = localStorage.getItem("cart");
    let localCart = storedCart ? JSON.parse(storedCart) : [];

    // Combine server cart data with localStorage cart data
    const combinedCart = [...response.data, ...localCart];
console.log(combinedCart,"combine")
    return combinedCart; //response.data;
  } catch (error) {
    console.error(error);
    // toast.error(error.response?.data?.error || "An error occurred");
    throw error;
  }
});

const initialState = {
  data: [],
  error: null,
  loading: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getCart.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      });
  },
});

export default cartSlice.reducer;
