import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getUser = createAsyncThunk(
    "user/address",
    async () => {
        const item = localStorage.getItem("user");
        const userData = JSON.parse(item);
        
        console.log(10, userData.user._id);
        try {
            const response = await axios.get(`https://allinonetables.com/api/user/getsingle/${userData.user._id}`);
            console.log(14,response)
            return response.data;
        } catch (error) {
            console.error(error);
            // toast.error(error.response?.data?.error || "An error occurred");
            throw error;
        }
    }
);

const initialState = {
    data: null,
    error: null,
    loading: false
};

const getuserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.error = action.error;
                state.loading = false;
            });
    },
});

export default getuserSlice.reducer;
