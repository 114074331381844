import React from "react";

function NoProductPage() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <p className="text-center">
              There is no product 
              here. <br />

            </p>
            <div className="new-estimation ptb-20 d-flex justify-content-center">
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </>
  );
}

export default NoProductPage;
