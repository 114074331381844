import React from "react";
import { Link } from "react-router-dom";

const testimonialData = [
  {
    id: 1,
    text: "I have purchased from FlooringDeals 5 times and recommend them to everyone. Their prices or customer service cannot be beat. It's hard to find businesses that actually look out for their customers and stand behind their product, FlooringDeals does all that and much more.",
    author: "Michael Bay",
    rating: 5,
  },
  {
    id: 2,
    text: "The FlooringDeals has one of the best websites for flooring and tiles! Their customer service and professionalism goes above and beyond. They provide materials at great value. I will be doing a lot of business with FlooringDeals. Thanks Evren and Colin!",
    author: "Jedi Master",
    rating: 5,
  },
  {
    id: 3,
    text: "Exceptional service and quality products! I renovated my entire house with flooring from FlooringDeals, and I couldn't be happier with the results. Their team was incredibly helpful throughout the process.",
    author: "Sarah Johnson",
    rating: 5,
  },
  {
    id: 4,
    text: "As a contractor, I've worked with many flooring suppliers, but FlooringDeals stands out. Their wide selection, competitive prices, and reliable delivery make my job so much easier. Highly recommended!",
    author: "Tom Builder",
    rating: 5,
  },
  {
    id: 5,
    text: "I was hesitant about ordering flooring online, but FlooringDeals made it a breeze. The samples they sent were high-quality, and the final product exceeded my expectations. Great experience overall!",
    author: "Emily Chen",
    rating: 4,
  },
  {
    id: 6,
    text: "FlooringDeals saved my renovation project! When another supplier let me down, they came through with exactly what I needed in record time. Their customer service is truly outstanding.",
    author: "Alex Rodriguez",
    rating: 5,
  },
  {
    id: 7,
    text: "I've been in the flooring business for 20 years, and FlooringDeals is by far the best supplier I've worked with. Their product knowledge and attention to detail are unmatched.",
    author: "Frank's Flooring",
    rating: 5,
  },
  {
    id: 8,
    text: "The variety of options at FlooringDeals is impressive. I found exactly what I was looking for at a price that fit my budget. The ordering process was smooth, and delivery was right on schedule.",
    author: "Lisa Homeowner",
    rating: 5,
  },
  {
    id: 9,
    text: "As an interior designer, I rely on quality and consistency. FlooringDeals delivers on both fronts. Their products have elevated my designs, and my clients are always thrilled with the results.",
    author: "Design by Diana",
    rating: 5,
  },
  {
    id: 10,
    text: "FlooringDeals went above and beyond when I had an issue with my order. They quickly resolved the problem and even followed up to ensure I was satisfied. That's the kind of service that earns loyal customers.",
    author: "Robert Green",
    rating: 4,
  },
];


const TestimonialCard = ({ text, author, rating }) => (
  <div className="col-sm-12 col-md-6 col-lg-6 appearence">
    <div className="install-box d-flex flex-column justify-content-between h-100">
      <img
        className="img-fluid"
        src="assets/images/product/testimonial.jpeg"
        alt=""
      />
      <div className="caption position-absolute">
        <h4 className="testimonial-text text-white m-4">{text}</h4>
        <div className="d-flex align-items-center justify-content-center m-4">
          <span className="text-white me-2 fs-5 text-end flex-grow-1">
            {author}
          </span>
          <span className="text-white">|</span>
          <span className="ms-2 text-warning fs-5 text-start flex-grow-1">
            {"★".repeat(rating)}
          </span>
        </div>
      </div>
    </div>
  </div>
);

function Testimonials() {
  return (
    <div>
      <nav aria-label="breadcrumb" className="breadcrumb mb-0">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Testimonials
            </li>
          </ol>
        </div>
      </nav>

      <section className="testimonials pt-2 pb-5">
        <div className="container">
          <h1>What Pros Are Saying About Us</h1>
          <div className="row gy-4 pt-3">
            {testimonialData.map((testimonial) => (
              <TestimonialCard key={testimonial.id} {...testimonial} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testimonials;