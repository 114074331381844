import React from 'react';
import PropTypes from 'prop-types';

const Pagination01 = ({
  productLength,
  onNextPage,
  onPreviousPage,
  currentPage,
  pageCount,
  onPageSelect
}) => {
  const getVisiblePages = () => {
    const visiblePages = [];
    const range = 1; // number of pages to show around the current page
    const lastPage = pageCount;

    for (let i = Math.max(1, currentPage - range); i <= Math.min(currentPage + range, lastPage); i++) {
      visiblePages.push(i);
    }

    if (visiblePages[0] > 1) {
      visiblePages.unshift('...');
      if (visiblePages[0] > 2) visiblePages.unshift(1);
    }

    if (visiblePages[visiblePages.length - 1] < lastPage) {
      if (visiblePages[visiblePages.length - 1] < lastPage - 1) visiblePages.push('...');
      visiblePages.push(lastPage);
    }

    return visiblePages;
  };

  const visiblePages = getVisiblePages();

  return (
    <div className="d-flex align-items-center justify-content-center my-3">
      <button
        className="pagination-btn page-link border p-2 px-3"
        style={{
          color: currentPage === 1 ? 'gray' : 'white',
          backgroundColor: currentPage === 1 ? '#cccccc' : '#e67929',
          borderRadius: '10px 0 0 10px',
          cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
        }}
        onClick={onPreviousPage}
        disabled={currentPage === 1}
        aria-label="Previous Page"
      >
        <span aria-hidden="true">Previous</span>
      </button>

      {/* Render visible page numbers */}
      {visiblePages.map((page, index) => (
       <button
       key={index}
       className={`pagination-btn page-link border p-2 mx-1 ${currentPage === page ? 'active' : ''}`}
       style={{
        display:"flex",justifyContent:"center",alignItems:"center",
         borderRadius: "50%",  // Change to 50% for a round button
         width: "30px",
         height: "30px",  // Set height equal to width
         color: currentPage === page ? '#fff' : '#000',
         backgroundColor: currentPage === page ? '#e67929' : '#f8f9fa',
         cursor: page === '...' ? 'default' : 'pointer',
       }}
       onClick={() => page !== '...' && onPageSelect(page)}
       aria-label={typeof page === 'number' ? `Go to page ${page}` : 'More pages'}
       disabled={page === '...'}
     >
       {page}
     </button>
     
      ))}

      <button
        className="pagination-btn page-link border p-2 px-3"
        style={{
          color: currentPage === pageCount || productLength === 0 ? 'gray' : 'white',
          backgroundColor: currentPage === pageCount || productLength === 0 ? '#cccccc' : '#e67929',
          borderRadius: '0 10px 10px 0',
          cursor: currentPage === pageCount || productLength === 0 ? 'not-allowed' : 'pointer',
        }}
        onClick={onNextPage}
        disabled={currentPage === pageCount || productLength === 0}
        aria-label="Next Page"
      >
        <span aria-hidden="true">Next</span>
      </button>
    </div>
  );
};

Pagination01.propTypes = {
  productLength: PropTypes.number.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageSelect: PropTypes.func.isRequired,
};

export default Pagination01;
