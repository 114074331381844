const { createAsyncThunk, createSlice, isRejectedWithValue } = require("@reduxjs/toolkit");
const { default: ApiUtil } = require("../../utils/apiUtils");

export const fetchAllCategories = createAsyncThunk(
	'categories/fetchAllCategories',
	async (_, { rejectWithValue }) => {
		try {
			const response = await ApiUtil.SendAsync({
					url: "/category/get",
					method: 'GET',				
			})
			return response.data.categories;
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
)  

const initialState = {
	categories: [],
	loading: false,
  error: null,
}

const categorySlice = createSlice({
	name: 'categories',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
		.addCase(fetchAllCategories.pending, (state, action) => {
			state.loading = true
		})
		.addCase(fetchAllCategories.fulfilled, (state, action) => {
			state.loading = false;
			state.categories = action.payload
		})
		.addCase(fetchAllCategories.rejected, (state, action) => {
			state.loading = false;
			state.categories = [];
			state.error = action.payload || 'Failed to fetch categories';
		});
	}
})

export default categorySlice.reducer;