import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getFev } from "../../store/slices/FavSlices";

function FavoriteProducts() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const fevProduct = useSelector((state) => state.favroite.data);
  console.log(11, fevProduct);
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  // const [fevProduct, setFevProduct] = useState(FevProduct);
  const [refresh, setRefresh] = useState(false);
  const [fevPro, setfevPro] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getFev());
    if (userData == null || userData === undefined || userData == "") {
      Navigate("/login");
    } else {
      axios
        .get(`https://allinonetables.com/api/fev/get/${userData.user.email}`)
        .then((res) => {
          setfevPro(res.data);
          setTotalPages(Math.ceil(res.data.length / itemsPerPage));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [refresh]);

  const removeFaverites = (id) => {
    axios
      .delete(`https://allinonetables.com/api/fev/delete/${id}`)
      .then((res) => {
        dispatch(getFev());
        setRefresh(!refresh);
        toast.error("Favorite Product Deleted From List..");
        // Check if the current page should be adjusted
      const newTotalPages = Math.ceil((fevProduct.length - 1) / itemsPerPage);
      if (currentPage > newTotalPages) {
        setCurrentPage(newTotalPages || 1);
      }
      })
      .catch((err) => console.log(err));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderOrders = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, fevProduct?.length);
    if (!fevProduct || fevProduct.length === 0) {
      return (
        <div className="py-5">
          <h4>No favorite products are added</h4>
        </div>
      );
    }
    return fevProduct.slice(startIndex, endIndex).map((item) => (
      <div key={item._id} className="ProductContent">
        <div className="row pt-2 pb-2 d-flex align-items-lg-center">
          <div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2 ">
          <Link to={`/productdetail/${item.product[0]?.slug}`}>
            <div className="productImg">
              <img
                src={
                  item.product[0]?.productPictures[0] ||
                  "https://m.media-amazon.com/images/I/51-JlnU14YL._AC_UF1000,1000_QL80_.jpg"
                }
                className="w-100 rounded"
                alt=""
              />
            </div>
            </Link>
          </div>
          <div className="col-9 col-sm-10 col-md-10 col-lg-4 col-xl-4">
            <div className="ProductName">
            <Link to={`/productdetail/${item.product[0]?.slug}`}>
              <h6 style={{cursor:"pointer"}}>{item.product[0]?.name}</h6>
              </Link>
              <p>SKU: {item.product[0]?.sku}</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2 d-none d-sm-none d-md-none d-lg-block">
            <div className="inStock">
              <p className="InStock">In Stock</p>
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2">
            <div className="price">
              <h5>
              <b>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(item.product[0]?.price)+" / sq. ft."}</b>


              </h5>
            </div>
          </div>
          <div className="col-8 col-sm-8 col-md-8 col-lg-2 col-xl-2">
            <div className="action text-center">
              <Link
                to={`/productdetail/${item.product[0]?.slug}`}
                className="btn"
              >
                View
              </Link>
              <button
                type="button"
                className="btn"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-custom-class="custom-tooltip"
                data-bs-title="Delete this Product."
                onClick={() => removeFaverites(item._id)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  // useEffect(() => {
  //   setTotalPages(Math.ceil((fevPro || []).length / itemsPerPage));
  // }, [fevPro]);

  return (
    <>
      <div className="row">
        <h3 className="text-start">Favorite products</h3>
        <p className="border-bottom">
          Create personalized collections of products you want to buy and save
          them for future reference.
        </p>
        {/* *************************** */}
        <div className="d-none d-sm-none d-md-none d-lg-block favoriteHeadings">
          <div className="row ">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              Product
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"></div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              Availability
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              Current price
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
              Actions
            </div>
          </div>
        </div>
        {renderOrders()}
        {/* *************************** */}
        <div className=" FavoriteBottom ">
          <div className="row p-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="myProfilePagination">
                <nav aria-label="Page navigation example d-sm-none d-md-block">
                  <ul className="pagination justify-content-end mt-4">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{
                          backgroundColor: "#f8f9fa", // Change this to your desired background color
                          color: "black",
                          border: "1px solid #dee2e6" // Example border style
                          
                        }}
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                        aria-label="Previous"
                      >
                        Previous
                      </button>
                    </li>

                    <li className="page-item">
  <span className="page-link">
    {totalPages > 1 ? `Page ${currentPage} of ${totalPages}` : `${currentPage}`}
  </span>
</li>

                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{
                          backgroundColor: "#f8f9fa", // Match background color with previous button
                          color: "black",
                          border: "1px solid #dee2e6" // Match border style with previous button
                        }}
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        aria-label="Next"
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* ********************************* */}
      </div>
    </>
  );
}

export default FavoriteProducts;
