import React from 'react'

function NoQuoteSummary() {
  return (
    <div>

      <div className="container">
        <div className="row">
       
          <div className="quote-summery-heading ps-3">
            <h2>
              <strong>Your Quotation is empty.</strong>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <p className="text-center">
              There is no product yet in your cart. 
              {}
              {}
            </p>
            
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  )
}

export default NoQuoteSummary
