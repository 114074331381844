import React from "react";

function Faq() {
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>FAQ's</li>
          </ul>
        </div>
      </div>

      {/* ************************* FAQ's ************************ */}

      <div className="faq">
        <div className="container">
          <h1>Frequently Asked Questions</h1>
          <br />
          <div className="row">
            <div className="col-lg-4 col-sm-5">
              <div className="product-list-img">
                <a href="" className="text-center">
                  <img
                    className="img-fluid rounded-4"
                    src="https://media.istockphoto.com/id/1398692686/photo/kitchen-and-living-room-interior-in-new-farmhouse-style-luxury-home-with-open-concept-floor.jpg?s=612x612&w=0&k=20&c=f_sh1dH5wrrIwmeLctl1amBQgOdNyT8cvaG4Qt9QnQg="
                    alt="Product Style"
                  />
                </a>
                <div className="product-list-quickview">
                  <button
                    className="product-action-btn-2"
                    title="Quick View"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="pe-7s-look"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-sm-7">
              <div className="shop-list-content">
                <h3>
                  <a href="" className="text-dark">
                    {" "}
                    Where will my order be left if I live in a house?{" "}
                  </a>
                </h3>
                <p className="text-dark">
                Typically, we offer curbside delivery, leaving your items in your driveway or in your garage if it's accessible from the driveway.


                </p>
              </div>
              <div className="shop-list-content">
                <h3>
                  <a href="" className="text-dark">
                    {" "}
                    Where will my order be left if I live in a condo or apartment?{" "}
                  </a>
                </h3>
                <p className="text-dark">
                For condos and apartments, we also provide curbside delivery at the nearest driveway entrance to the building's first accessible entrance.

                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-5">
              <div className="product-list-img">
                <a href="" className="text-center">
                  <img
                    className="img-fluid rounded-4"
                    src="https://cdn.shopify.com/s/files/1/1112/9712/collections/custom-hardwood-flooring.jpg?v=1515349321"
                    alt="Product Style"
                  />
                </a>
                <div className="product-list-quickview">
                  <button
                    className="product-action-btn-2"
                    title="Quick View"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="pe-7s-look"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-sm-7">
              <div className="shop-list-content">
                <h3>
                  <a href="" className="text-dark">
                    {" "}
                    Can I request delivery inside my home or to a specific room?{" "}
                  </a>
                </h3>
                <p className="text-dark">
                You can request indoor delivery or delivery to a specific room by notifying us at least 24 hours before your delivery date. Note that additional charges apply and this service may not be available in all areas.
                </p>
              </div>
              <div className="shop-list-content">
                <h3>
                  <a href="" className="text-dark">
                    {" "}
                    Can I change my delivery date?{" "}
                  </a>
                </h3>
                <p className="text-dark">
                You can reschedule your delivery up to 24 hours before the scheduled date. Please be aware that changing the delivery within 24 hours of the scheduled time will incur additional fees.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-5">
              <div className="product-list-img">
                <a href="" className="text-center">
                  <img
                    className="img-fluid rounded-4"
                    src="https://media.istockphoto.com/id/1464814939/photo/home-remodel-floor-installation.webp?b=1&s=170667a&w=0&k=20&c=wL0yB8p3_T436wMwX5ZnU5p6DO2x6L-6rrODHxPK7sU="
                    alt="Product Style"
                  />
                </a>
                <div className="product-list-quickview">
                  <button
                    className="product-action-btn-2"
                    title="Quick View"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="pe-7s-look"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-sm-7">
              <div className="shop-list-content">
                <h3>
                  <a href="" className="text-dark">
                    {" "}
                    What happens if no one is home at the time of delivery?{" "}
                  </a>
                </h3>
                <p className="text-dark">
                If no one is available to receive the order, it will be necessary to charge for rescheduling.
                </p>
              </div>
              <div className="shop-list-content">
                <h3>
                  <a href="" className="text-dark">
                    {" "}
                    Do I need to help with unloading or moving the delivery?{" "}
                  </a>
                </h3>
                <p className="text-dark">
                We want you to be completely satisfied with your purchase. If for any reason you're not happy with your flooring or tile products, you may return them within 30 days of delivery for a refund or exchange. Please review our Returns & Exchanges policy for more information on eligibility and the return process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
