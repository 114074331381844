import React, { useState } from "react";
import "./productsearch.css";
import { useNavigate } from "react-router-dom";

function ProductSearch(props) {
    const navigate=useNavigate()
  return (
    <>
            {}
             <div className="search-grid" onClick={(e)=>navigate(`productdetail/${props.id}`)}>
              <div className="imageSearchProduct mt-2">
                <img
                  className="img-fluid rounded-4"
                  src={props.img}
                  alt=""
                />
              </div>
              <div className=" d-flex align-item-center">
                <div className="discription-search mt-2">
                  <p className="m-0 search-head">
                    {props.name}
                  </p>
                  <p className="m-0 search-number">BYKRCHY50CA</p>
                </div>
              </div>
            </div>
{}  
    </>
  );
}
export default ProductSearch;
