import React from 'react';

const Table = ({ data, showHeaders = false }) => {
  const entries = Object.entries(data);

  // Helper function to format keys
  const formatKey = (key) => {
    return key
      .replace(/_/g, ' ') // Replace underscores with spaces
      .toLowerCase() // Convert to lowercase
      .replace(/^\w|\s\w/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
  };

  return (
    <table className="specs-table table table-striped">
      {showHeaders && (
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
      )}

      <tbody>
        {entries.map(([key, value], index) => (
          <tr key={index}>
            <td><strong>{formatKey(key)}</strong></td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
