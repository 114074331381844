import React from 'react'

function PaymentCancel() {
  return (
    <div>
      cancell
    </div>
  )
}

export default PaymentCancel
