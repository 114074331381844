import React, { useEffect } from "react";
import "./profile.css";
import MyProfile from "./MyProfile";
import OrderHistory from "./OrderHistory";
import FavoriteProducts from "./FavoriteProducts";
import Addresses from "./Addresses";
import Password from "./Password";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import OrderDetails from "./OrderDetails";

function Profile() {
  const { id } = useParams();
  console.log(13, id);

  const Navigate = useNavigate();
  const Logout = () => {
    localStorage.removeItem("user");
    toast.success("logged Out Successfully...",{   autoClose: 1500});
    Navigate("/");
  };

  return (
    <>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>My Account</li>
          </ul>
          <h2>My Account</h2>
          <p>
            Manage your info, activity, security options, and preferences to
            make Flooring Deals work better for you.
          </p>
        </div>
      </div>

      {/* ***************************************************** */}
      <div className="container pb-50">
        <div className="row d-flex align-items-start">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
            <div
              className="nav flex-column nav-pills me-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className={`nav-link text-start ${
                  id == undefined || id == "" ? "active" : ""
                }`}
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                // aria-selected={`${id==undefined || id==""?"true":"false"}`}
                aria-selected="false"
              >
                <i class="bi bi-person-circle"></i>
                Your Profile
              </button>
              <button
                className={`nav-link text-start ${id === "2" ? "active" : ""}`}
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                <i class="bi bi-clock"></i>
                Order History
              </button>
              <button
                className={`nav-link text-start  ${id === "4" ? "active" : ""}`}
                id="v-pills-favorite-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-favorite"
                type="button"
                role="tab"
                aria-controls="v-pills-favorite"
                // aria-selected={`${id === "4"?"true":"false"}`}
                aria-selected="true"
              >
                <i class="bi bi-heart"></i>
                Favorite Products
              </button>
              <button
                className={`nav-link text-start`}
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                <i class="bi bi-geo-alt-fill"></i>
                Addresses
              </button>
              <button
                className="nav-link text-start"
                id="v-pills-settings-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                <i class="bi bi-key-fill"></i>
                Password
              </button>
              {/* ************** detail */}
              {/* <button
                className="nav-link text-start"
                id="v-pills-detail-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-detail"
                type="button"
                role="tab"
                aria-controls="v-pills-detail"
                aria-selected="false"
              >
                <i class="bi bi-key-fill"></i>
                Order Details
              </button> */}
              {/* **************** detail */}
              <button
                onClick={(e) => Logout()}
                className="nav-link text-start"
                id="v-pills-logOut-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                <i class="bi bi-box-arrow-right"></i>
                Log Out
              </button>
            </div>
          </div>

          {/* *************Tab Content ***************** */}
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
            <div className="tab-content full-box" id="v-pills-tabContent">
              {/* ************** my profile */}
              <div
                className={`tab-pane fade ${
                  id == undefined || id == "" ? "show active" : ""
                }`}
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                tabIndex="0"
              >
                <MyProfile />
              </div>
              {/* ****************** order history */}
              <div
                className={`tab-pane fade ${id === "2" ? "show active" : ""}`}
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex="0"
              >
                <OrderHistory />
              </div>
              {/* ******************** Favorite Products */}
              <div
                className={`tab-pane fade  ${
                  id === "4" ? "show active" : ""
                }  `}
                id="v-pills-favorite"
                role="tabpanel"
                aria-labelledby="v-pills-favorite-tab"
                tabIndex="0"
              >
                <FavoriteProducts />
              </div>
              {/* ****************** addresses */}
              <div
                className="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
                tabIndex="0"
              >
                <Addresses />
                {/* ********************* Password Change */}
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
                tabIndex="0"
              >
                <Password />
              </div>

              {/* ********************** order Details page */}

              {/* <div
                className="tab-pane fade"
                id="v-pills-detail"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
                tabIndex="0"
              >
                <OrderDetails />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
