import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import ApiUtil from '../../utils/apiUtils'
import { getCart } from "./CardSlice";
import Cookies from "js-cookie";


// ... existing code ...

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiUtil.SendAsync({
        url: "/user/signin",
        method: 'POST',
        data: values
      });
      const { data: userData } = response;
      const { token } = userData;
      Cookies.set('token', token, { expires: 7, secure: true });
      localStorage.setItem("user", JSON.stringify(userData));
      dispatch(getCart());
      return userData;
    } catch (err) {
      toast.error("Invalid Username or Email and Password");
      return rejectWithValue(err.response.data);
    }
  }
);

export const signupUser = createAsyncThunk(
  'auth/signupUser',
  async (values, { rejectWithValue }) => {
    try {
      await ApiUtil.SendAsync({
        url: "/user/signup",
        method: "POST",
        data: values
      });
    } catch (err) {
      toast.error(`Signup failed ${err.response.data.error}`);
      return rejectWithValue(err.response.data);
    }
  }
);

export const googleSignIn = createAsyncThunk(
  'auth/googleSignIn',
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiUtil.SendAsync({
        url: '/google-auth',
        method: 'POST',
        data: credentials,
      });
      const { data: userData } = response;
      const { token } = userData;
      Cookies.set('token', token, { expires: 7, secure: true });
      localStorage.setItem("user", JSON.stringify(userData));
      dispatch(getCart());
      return userData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const initialState = {
    user: null,
    loading: false,
    error: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: () => {
      localStorage.removeItem("user")
      toast.error("Logged out Successfully.");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default authSlice.reducer;
