import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import url from "../../Url";
import Pagination01 from "../Pagination01";
import ProductCard from "../ProductCard";
import SidebarFilters from "../SidebarFilters";
import SortBy from "../sortBy";
import "./product.css";

function Product() {
  const { id } = useParams();

  const [Product, setProduct] = useState([]);
  const [showText, setShowText] = useState(false);
  const [fetchedProduct, setFetchedProduct] = useState([]);
  const [CatDetails, setCatDetails] = useState([]);
  const [SingleCat, setSingleCat] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const [selectedSortingOption, setSelectedSortingOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // ********************* filter option check names match the dictionary name/mongodb name *********************************************
  const [filters, setFilters] = useState({
    name: "",
    cat: "",
    sub_cat: "",
    minprice: "",
    maxprice: "",
    color_name: "",
    BrandName: "",
    length_cm: "",
    Collection_Name: "",
    installation_method: "",
    thickness: "",
    widthinch: "",
    lengthinch: "",
    wearlayerthickness: "",
    Species: "",
    Finish: "",
    Surface_Texture: "",
    ApproximateSize: "",
    Material: "",
    Onces: "",
    Density: "",
    Shape: "",
    edgeType: "",
    PEI_Rating: "",
    covrage: "",
    volime: "",
    ac: "",
    isDiscount:true
  });

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 20,
  });
  //*** Pagination next or previous function ***

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo(0, 100);
    getProducts(filters, currentPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    window.scrollTo(0, 100);
    getProducts(filters, currentPage - 1);
  };
  const handlePageSelect = (page) => {
    setCurrentPage(page);
    getProducts(filters, page);
  };
  //*** Sidebar filters function ***
  const handleFilterChange = (filterName, value) => {
    let tempFilter = { ...filters, [filterName]: value };
    setFilters(tempFilter);
    getProducts(tempFilter);
    setCurrentPage(1);
  };
  //*** Price filters function ***
  const priceFilterApply = (minPrice, maxPrice) => {
    let tempFilter = {
      ...filters,
      minprice: minPrice,
      maxprice: maxPrice,
    };
    setFilters(tempFilter);
    getProducts(tempFilter);
    setShowText(true);
  };
  const priceFilterClear = () => {
    setMaxPrice("");
    setMinPrice("");
    let tempFilter = {
      ...filters,
      minprice: "",
      maxprice: "",
    };

    setFilters(tempFilter);
    getProducts(tempFilter);
    setShowText(false);
  };
  useEffect(() => {
    getProducts();
  }, []);

  //*** Get Products function ***
  const getProducts = async (tempFilters = filters, pageNo = 1) => {
    try {
      // Fetch data for the current page
      const response = await axios.get(`${url}/api/product/getAll`, {
        params: {
          ...tempFilters,
          page: pageNo,
          limit: pagination.limit,
        },
      });
      const products = response.data;

      const sortedProducts =
        products.data &&
        products.data.sort((a, b) => a.sale_price - b.sale_price);
      setProduct(products);
      setFetchedProduct(products?.data);
    } catch (error) {
      setProduct([]);
      toast.warn(error?.response?.data?.message,{   autoClose: 1500});
    }
  };
  //*** Get Category by Id function ***
  const getCategory = async () => {
    try {
      const response = await axios.get(`${url}/api/getcategory/get/${id}`);
      setSingleCat(response.data);
      if (response.data[0].Type === "Cat") {
        getSUbCats(response.data[0]._id);
        getProducts({ ...filters, cat: response?.data[0]?.name });
        setFilters((prevState) => ({
          ...prevState,
          cat: response.data[0].name
        }));
      } else {
        getProducts({ ...filters, cat: response?.data[0]?.parentName, sub_cat: response.data[0].name });
        setFilters((prevState) => ({
          ...prevState,
          cat: response.data[0].parentName,
          sub_cat: response.data[0].name,
        }));
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };
  //*** Get Sub Category by Id function ***
  const getSUbCats = (id) => {
    axios
      .get(`https://allinonetables.com/api/category/getCat/${id}`)
      .then((response) => {
        setCatDetails(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //-------- SORTING PRODUCTS START ---------------------------
  const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedSortingOption(selectedOption);

    // Update the Product array based on the selected sorting option
    let sortedProducts = [...fetchedProduct];
    switch (selectedOption) {
      case "lowToHigh":
        sortedProducts = fetchedProduct.sort((a, b) => a.price - b.price);
        break;
      case "highToLow":
        sortedProducts = fetchedProduct.sort((a, b) => b.price - a.price);
        break;
      case "BestSeller":
        let BestSellerTempFilter = {
          ...filters,
          BestSeller:true
        };
        setFilters(BestSellerTempFilter);
        getProducts(BestSellerTempFilter);
        break;
      case "MostView":
        let mostViewTempFilter = {
          ...filters,
          IsmostViewed:true
        };
        setFilters(mostViewTempFilter);
        getProducts(mostViewTempFilter);
        break;
      case "NewProduct":
        sortedProducts = fetchedProduct.filter(
          (item) => item.newProduct === true
        );
        break;
      case "Default":
        {
          if (SingleCat[0]?.Type == "sub_cat") {
            setCurrentPage(1);
            setFilters({
              ...filters,
              sub_cat: SingleCat[0]?.name,
            });
            setCurrentPage(1);
          } else {
            setCurrentPage(1);
            setFilters({
              ...filters,
              cat: SingleCat[0]?.parentName,
            });
            setCurrentPage(1);
          }
        }
        break;
    }
    setFetchedProduct(sortedProducts);
  };

  return (
    <div>
      {/* ************************ breadcrumb ******************** */}
      <div className="breadcrumb mb-0">
        <div className="container">
        <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {">"}
            {}
            <li>Discount Sale</li>
          </ul>
        </div>
      </div>

      {/* ************************** Heading ************************ */}

      <div className="container">
        <div className="heading">
          <h1>Discount Sale</h1>
          <p>
            Explore our extensive collection of flooring products and tiles from
            the comfort of your home.
          </p>
        </div>
      </div>

      <div className="">
        <div className="container">
          <div className="row ">
            <div className="col-lg-3">
              {/* Sidebar Filters should go in this column */}
              <SidebarFilters
                onFilterChange={handleFilterChange}
                getMaxPrice={setMaxPrice}
                maxPrice={maxPrice}
                minPrice={minPrice}
                getMinPrice={setMinPrice}
                applyFunc={() => priceFilterApply(minPrice, maxPrice)}
                clearFunc={() => priceFilterClear()}
                resetPrice={showText}
                catDetails={CatDetails}
              />
            </div>
            <div className="col-lg-9">
              <div className="container ps-4 pe-4">
                <div className="shop-topbar-wrapper pb-2 ps-2 pe-2">
                  <div className="shop-topbar-left">
                    <div className="showing-item">
                      <span>{Product.total} Products listed</span>
                    </div>
                  </div>
                  <SortBy
                    selectedSortingOption={selectedSortingOption}
                    handleSortChange={handleSortChange}
                  />
                </div>
              </div>
              <ProductCard Product={Product.data} />
            </div>
            <Pagination01
              productLength={Product.total} // Pass the total number of items (Product.total)
              onNextPage={handleNextPage} // Pass function to handle next page
              onPreviousPage={handlePrevPage} // Pass function to handle previous page
              currentPage={currentPage} // Pass the current page from state
              pageCount={Product.pageCount} // Pass the total page count
              onPageSelect={handlePageSelect} // Pass the function for page selection
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
