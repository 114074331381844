import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import brandsImg from "../assets/images/stats-img/brands.png";
import calculator from "../assets/images/stats-img/calculatemin.png";
import truck from "../assets/images/stats-img/truck.png";
import useBanners from "../store/customHooks/useBanners";
import { getCart } from "../store/slices/CardSlice";
import { fetchAllCategories } from "../store/slices/Category";
import { getFev } from "../store/slices/FavSlices";
import Banners from "./Banners";
import BrandSlider from "./BrandSlider";
import CardItem from "./common/CardItem";
import axios from "axios";
import url from "../Url";

function Home() {
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);

  const item = localStorage.getItem("user");
  const userData = useMemo(() => JSON.parse(item), [item]); // Memoize userData to avoid unnecessary re-renders

  const { categories, loading: categoriesLoad } = useSelector((state) => state.categories);
  const { banners, loading: bannersLoad, error: bannersErr } = useBanners();

  const filteredCategories = useMemo(() => {
    const tiledata = categories.filter((item) => item.parentName === "Tiles & Siding");
    const catdata = categories.slice(0, 9).filter((item) => item.Type === "Cat");
    const moldingAndTrims = categories.filter((item) => item.name === "Moldings and Trims");
    const installation = categories.filter((item) => item.name === "Installation Supplies");

    return [...catdata, ...tiledata, ...moldingAndTrims, ...installation];
  }, [categories]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    // Fetch categories only if not already loading or loaded
    if (categories.length === 0 && !categoriesLoad) {
      dispatch(fetchAllCategories());
    }

    if (!userData) {
      dispatch(getFev());
      dispatch(getCart());
    }
  }, [userData]);

  useEffect(() => {
    axios
      .get(`${url}/api/brand/getAll`)
      .then((response) => {
        setBrands(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="container-fluid">
      <Banners banners={banners} loading={bannersLoad} error={bannersErr} />
      <div className="row">
        <div className="stats">
          <div className="container p-4">
            <div className="row text-left">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <Link onClick={scrollToTop} to="/ship">
                  <div className="stat-container ">
                    <div className="stat-content bg-light-gray d-flex align-items-center">
                      <h3 className="stat-title text-black">Home Delivery</h3>
                      <img src={truck} className="stat-image" alt="Home Delivery" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <Link onClick={scrollToTop} to="/brand">
                  <div className="stat-container rounded">
                    <div className="stat-content bg-light-gray d-flex align-items-center">
                      <h3 className="stat-title text-black">150+ Brands</h3>
                      <img src={brandsImg} className="stat-image" alt="Brands" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <Link onClick={scrollToTop} to="/costestimationInstallationPage">
                  <div className="stat-container rounded">
                    <div className="stat-content bg-light-gray d-flex align-items-center">
                      <h3 className="stat-title text-black">Installation Calculator</h3>
                      <img src={calculator} className="stat-image" alt="Installation Calculator" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-softPeach py-5">
          <div className="container">
            <h1 className="text-center text-[#e67929] text-4xl mb-4">Shop by Category</h1>
            <div className="row justify-content-center">
              {categoriesLoad
                ? Array.from(new Array(6)).map((_, index) => <CardItem key={index} loading={true} />)
                : filteredCategories.map((cat) => (
                    <CardItem key={cat.id} item={cat} loading={false} scrollToTop={scrollToTop} />
                  ))}
            </div>
          </div>
        </div>
        <BrandSlider brands={brands} />
        <div className="installation ptb-30">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 appearence">
                <div className="install-box">
                  <img
                    className="img-fluid"
                    src="assets/images/product/install.jpg"
                    alt=""
                  />
                  <div className="caption position-absolute p-4">
                    <h3 className="text-white">DIY Installation Guide</h3>
                    <p>
                      Calculate the budget for your specific needs and find your favorite flooring
                    </p>
                  </div>
                  <Link id="view" className="p-4" to="/guide">
                    <h3>
                      View <i className="bi bi-arrow-right-short"></i>
                    </h3>
                  </Link>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 appearence">
                <div className="install-box">
                  <img
                    className="img-fluid"
                    src="assets/images/product/install.jpg"
                    alt=""
                  />
                  <div className="caption position-absolute p-4">
                    <h3 className="text-white">Installation Cost Estimation</h3>
                    <p>
                      Calculate the estimated budget for your specific installation needs
                    </p>
                  </div>
                  <Link id="view" className="p-4" to="/costestimationInstallationPage">
                    <h3>
                      View <i className="bi bi-arrow-right-short"></i>
                    </h3>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
