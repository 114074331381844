import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Product from "./components/flooring/Product";
import Home from "./components/Home";
import Abuotus from "./components/Abuotus";
import Contact from "./components/Contact";
import Faq from "./components/Faq";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Brand from "./components/flooring/Brand";
import Favorite from "./components/flooring/Favorite";
import InstallationCostEstimation from "./components/Installation/InstallationCostEstimation";
import NewQuoteSummary from "./components/flooring/NewQuoteSummary";
import QuoteFinal from "./components/flooring/QuoteFinal";
import ThankYou from "./components/flooring/ThankYou";
import Cart from "./components/Cart";
import ProductDetail from "./components/flooring/ProductDetail";
import Navbar from "./Navbar";
import CheckoutContact from "./components/forCheckoutAndPayment/CheckoutContact";
import CostEstimation from "./components/flooring/CostEstimation";
import AddQuoteSummaryBox from "./components/flooring/AddQuoteSummaryBox";
import BrandProductPage from "./components/flooring/BrandProductPage";
import ProductSearch from "./ProductSearch";
import Testimonials from "./components/Testimonials";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Ship from "./components/Ship";
import Guide from "./components/Guide";
import SavedInstallation from "./components/flooring/SavedInstallation";
import NoQuoteSummary from "./components/flooring/NoQuoteSummary";
import NoProductPage from "./components/flooring/NoProductPage";
import DiscountProductPage from "./components/flooring/DiscountProductPage";
import ClearenceProductPage from "./components/flooring/ClearenceProductPage";
import MonthSpecialProductPage from "./components/flooring/MonthSpecialProductPage";
import { useEffect } from "react";
import axios from "axios";
import Signup from "./components/LoginSignup/Signup";
import Profile from "./components/MyProfile/Profile";
import Login from "./components/LoginSignup/Login";
import UserSignUp from "./components/LoginSignup/UserSignUp";
import UserLogin from "./components/LoginSignup/UserLogin";
import { useDispatch } from "react-redux";
import { getFev } from "./store/slices/FavSlices";
import ContactUs from "./components/flooring/extraInfoPages/ContactUs";
import NotFound404 from "./components/NotFound404";
import OrderDetails from "./components/MyProfile/OrderDetails";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<UserLogin />} />
          <Route exact path="/signUp" element={<UserSignUp />} />
          <Route exact path="/about" element={<Abuotus />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/vinylwood" element={<Product />} />
          <Route exact path="/category/:id" element={<Product />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/brand" element={<Brand />} />
          <Route exact path="/favorite" element={<Favorite />} />
          <Route
            exact
            path="/costestimationInstallationPage"
            element={<InstallationCostEstimation />}
          />
          <Route exact path="/costestimation" element={<CostEstimation />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/newQuoteSummary" element={<NewQuoteSummary />} />
          <Route exact path="/productdetail" element={<ProductDetail />} />
          <Route exact path="/QueatNumber" element={<QuoteFinal />} />
          <Route exact path="/Installation" element={<ThankYou />} />
          <Route
            exact
            path="/SavedInstallation/:id"
            element={<SavedInstallation />}
          />
          <Route
            exact
            path="/brandProductPage/:id"
            element={<BrandProductPage />}
          />
          <Route
            exact
            path="/productdetail/:slug"
            element={<ProductDetail />}
          />
          <Route exact path="/testimonials" element={<Testimonials />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/ship" element={<Ship />} />
          <Route exact path="/guide" element={<Guide />} />
          <Route exact path="/NoCard" element={<NoQuoteSummary />} />
          <Route exact path="/NoProduct" element={<NoProductPage />} />
          <Route exact path="/discountPage" element={<DiscountProductPage />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/profile/fev" element={<Profile />} />
          <Route
            exact
            path="/clearencePage"
            element={<ClearenceProductPage />}
          />
          <Route
            exact
            path="/MonthSpecialPage"
            element={<MonthSpecialProductPage />}
          />
          <Route exact path="/contactUs" element={<ContactUs />} />
          <Route exact path="/orderdetail" element={<OrderDetails />} />
        </Routes>
        <CheckoutContact />

        <Footer />
      </Router>
    </>
  );
}

export default App;
