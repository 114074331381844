// cartSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getCart } from "./CardSlice";

// Utility function to generate random digits for anonymous IDs
const generateRandomDigits = () => {
  return Math.floor(Math.random() * 9000000000 + 1000000000).toString();
};

// Async thunk for adding a product to the cart
export const addToCart = createAsyncThunk(
  "cart/addProduct",
  async ({ item, userData, userCart }, { rejectWithValue, dispatch }) => {
    // Assuming item.boxQuantity is part of item
    const boxQuantity = Math.ceil(1); // Replace with actual box quantity if needed
    const perboxCovrage = item.BoxCoverage || 0;
    const totalBoxCoverage = perboxCovrage * boxQuantity;
    const totalPrice = item.BoxPrice * boxQuantity;

    const data = {
      ProductName: item.name || "",
      email: userData?.user?.email || "", // Use optional chaining for safety
      slug: item.slug || "",
      SKU: item.sku || "",
      boxQuantity,
      perboxCovrage,
      sellPrice: item.price || "",
      perBoxPrice: item.BoxPrice || "",
      boxCovrage: totalBoxCoverage,
      BrandName: item.BrandName || "",
      img: item.productPictures?.[0] || "",
      deliveryCharge: 100,
    };

    try {
      // Check if userData is present
      if (!userData) {
        // For anonymous users
        const storedCart = localStorage.getItem("cart");
        let cart = storedCart ? JSON.parse(storedCart) : [];
        const existingProductIndex = cart.findIndex((i) => i.slug === data.slug);

        if (existingProductIndex !== -1) {
          // Update existing product in cart
          cart[existingProductIndex].boxQuantity += boxQuantity;
          cart[existingProductIndex].boxCovrage += totalBoxCoverage;
          cart[existingProductIndex].total += totalPrice;
          toast.success("Product quantity updated in Quote Summary");
        } else {
          // Add new product for anonymous user
          const uniqueId = `anonymous_${generateRandomDigits()}`;
          const updatedData = { ...data, total: totalPrice, _id: uniqueId };
          cart.push(updatedData);
          toast.success("Product added to Quote Summary");
        }

        // Save updated cart to localStorage
        localStorage.setItem("cart", JSON.stringify(cart));
        // Dispatch event to inform other parts of the app about localStorage change
        window.dispatchEvent(new CustomEvent("onLocalStorageChange", { detail: "cart" }));
      } else {
        // For authenticated users
        const existingProduct = userCart.find((i) => i.slug === data.slug);

        if (existingProduct) {
          // Update existing product in user cart
          const newTotalBoxNeeded = existingProduct.boxQuantity + boxQuantity;
          const newTotalCoverageNeeded = perboxCovrage * newTotalBoxNeeded;

          const updatedExistingData = {
            ...existingProduct,
            boxQuantity: newTotalBoxNeeded,
            boxCovrage: newTotalCoverageNeeded,
            total: existingProduct.total + totalPrice,
          };

          await axios.put(`https://allinonetables.com/api/cart/${existingProduct._id}`, updatedExistingData);
          toast.success("Product updated in cart successfully.");
          dispatch(getCart());
        } else {
          // Add new product for authenticated user
          const updatedData = { ...data, total: totalPrice };
          await axios.post(`https://allinonetables.com/api/cart/create`, updatedData);
          toast.success("Product added to cart successfully.");
          dispatch(getCart());
        }
      }
    } catch (error) {
      // Handle any errors
      toast.error("Failed to add product to cart.");
      return rejectWithValue(error.response?.data?.message || "An error occurred");
    }
  }
);

// Initial state for the cart slice
const initialState = {
  cartData: [],
  loading: false,
  error: null,
};

// Create the cart slice
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Add additional reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cartData = action.payload; // Update cart data if needed
        state.loading = false;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default cartSlice.reducer;

// Export actions if you need them
export const { } = cartSlice.actions;
