import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import Routers from "./Routers";
import PerfectScrollbar from "react-perfect-scrollbar";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <App /> */}
    <BrowserRouter basename={"/"}>
      <PerfectScrollbar>
        <Routers />
      </PerfectScrollbar>
    </BrowserRouter>
    <ToastContainer position="top-right" autoClose={3000} />
  </Provider>
);

reportWebVitals();
