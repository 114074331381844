import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import url from "../../Url";
import ApiUtil from "../../utils/apiUtils";
import Pagination01 from "../Pagination01";
import ProductCard from "../ProductCard";
import ProductFilters from "../ProductFilters";
import SortBy from "../sortBy";
import "./product.css";

function Product() {
  const { id } = useParams();

  const [Product, setProduct] = useState([]);
  const [fetchedProduct, setFetchedProduct] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [SingleCat, setSingleCat] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const [selectedSortingOption, setSelectedSortingOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 20,
  });

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo(0, 100);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    window.scrollTo(0, 100);
  };

  const handlePageSelect = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (filterKey, filterValues, isChecked) => {
    setQueryParams((prev) => {
      const updatedParams = { ...prev };

      if (isChecked && filterValues.length > 0) {
        updatedParams[filterKey] = filterValues;
      } else {
        delete updatedParams[filterKey];
      }

      return updatedParams;
    });
  };

  function createQueryString(params) {
    if (!params) return;
    const queryParts = new URLSearchParams(params).toString();

    return queryParts;
  }

  const getCategory = async () => {
    try {
      const response = await axios.get(`${url}/api/getcategory/get/${id}`);
      setSingleCat(response.data);

      if (response.data[0].Type === "Cat") {
        setQueryParams((prev) => ({ ...prev, cat: response.data[0].name }));
        fetchFilters(response.data[0]?.name);
      } else if (response.data[0].Type === "Sub_cat") {
        setQueryParams((prev) => ({
          ...prev,
          cat: response.data[0].parentName,
          sub_cat: response.data[0].name,
        }));
        debugger;
        fetchFilters(response.data[0]?.parentName);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  const fetchFilters = async (catName) => {
    try {
      setLoading(true);
      const response = await ApiUtil.SendAsync({
        url: `/filter/name/${catName}`,
        method: "GET",
      });

      setFilters(response.data.data);
    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const getProducts = async (pageNo = currentPage) => {
    try {
      setLoading(true);
      const queryString = createQueryString(queryParams);
      const response = await ApiUtil.SendAsync({
        url: `/product/getAll?${queryString}`,
        params: {
          page: pageNo,
          limit: pagination.limit,
        },
      });

      const products = response.data;
      setProduct(products);
      setFetchedProduct(products?.data);
    } catch (error) {
      setProduct([]);
      toast.warn(error?.response?.data?.message || "Failed to fetch products", {
        autoClose: 1500,
      });
    } finally {
      setLoading(false);
    }
  };
  //-------- SORTING PRODUCTS START ---------------------------
  const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedSortingOption(selectedOption);

    // Update the Product array based on the selected sorting option
    let sortedProducts = [...fetchedProduct];
    switch (selectedOption) {
      case "lowToHigh":
        sortedProducts = fetchedProduct.sort((a, b) => a.price - b.price);
        break;
      case "highToLow":
        sortedProducts = fetchedProduct.sort((a, b) => b.price - a.price);
        break;
      case "BestSeller":
        let BestSellerTempFilter = {
          ...filters,
          BestSeller: true,
          IsmostViewed: "",
          newProduct: "",
        };
        setFilters(BestSellerTempFilter);
        getProducts(BestSellerTempFilter);
        break;
      case "MostView":
        let mostViewTempFilter = {
          ...filters,
          BestSeller: "",
          IsmostViewed: true,
          newProduct: "",
        };
        setFilters(mostViewTempFilter);
        getProducts(mostViewTempFilter);
        break;
      case "NewProduct":
        let newProductTempFilter = {
          ...filters,
          BestSeller: "",
          IsmostViewed: "",
          newProduct: true,
        };
        setFilters(newProductTempFilter);
        getProducts(newProductTempFilter);
        break;
      case "Default":
        {
          if (SingleCat[0]?.Type == "sub_cat") {
            setCurrentPage(1);
            setFilters({
              ...filters,
              sub_cat: SingleCat[0]?.name,
            });
            setCurrentPage(1);
          } else {
            setCurrentPage(1);
            setFilters({
              ...filters,
              cat: SingleCat[0]?.parentName,
            });
            setCurrentPage(1);
          }
        }
        break;
    }
    setFetchedProduct(sortedProducts);
  };

  useEffect(() => {
    getCategory();
  }, [id]);

  useEffect(() => {
    if (Object.keys(queryParams).length !== 0) {
      getProducts(currentPage);
    }
  }, [queryParams, currentPage]);

  return (
    <div>
      {/* ************************ breadcrumb ******************** */}
      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {">"}
            {SingleCat[0]?.Type === "Super_Cat" ? (
              <>
                <li>{SingleCat[0]?.name}</li>
              </>
            ) : (
              <>
                {SingleCat[0]?.Type === "Cat" ? (
                  <>
                    <li>{SingleCat[0]?.parentName}</li>
                    {">"}
                    <li>{SingleCat[0]?.name}</li>
                  </>
                ) : (
                  <>
                    <li>{SingleCat[0]?.superCatName}</li>
                    {">"}
                    <li>{SingleCat[0]?.parentName}</li>
                    {">"}
                    <li>{SingleCat[0]?.name}</li>
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </div>

      <div className="container">
        <div className="heading">
          <h1>{SingleCat[0]?.name ? SingleCat[0]?.name : ""}</h1>
          <p>
            Explore our extensive collection of flooring products and tiles from
            the comfort of your home.
          </p>
        </div>
      </div>

      <div className="">
        <div className="container">
          <div className="row">
            {/* Filters and Showing Item on Mobile */}
            <div className="col-12 d-lg-none">
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1">
                  <ProductFilters
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    collapseFilters={5}
                  />
                </div>
                <div className="showing-item ms-3">
                  <span>{Product.total} Products listed</span>
                </div>
              </div>
              <div style={{paddingLeft:"1rem"}} className="mt-3">
                <SortBy
                  selectedSortingOption={selectedSortingOption}
                  handleSortChange={handleSortChange}
                />
              </div>
            </div>

            {/* Desktop Layout */}
            <div className="col-lg-3 d-none d-lg-block">
              <ProductFilters
                filters={filters}
                handleFilterChange={handleFilterChange}
                collapseFilters={5}
              />
            </div>

            <div className="col-lg-9">
              <div className="container ps-4 pe-4">
                <div className="shop-topbar-wrapper pb-2 ps-2 pe-2 d-none d-lg-flex">
                  <div className="shop-topbar-left">
                    <div className="showing-item">
                      <span>{Product.total} Products listed</span>
                    </div>
                  </div>
                  <SortBy
                    selectedSortingOption={selectedSortingOption}
                    handleSortChange={handleSortChange}
                  />
                </div>
              </div>
              <ProductCard Product={Product.data} loading={loading} />
            </div>
            {Product?.pageCount > 0 && (
              <Pagination01
                productLength={Product.total}
                onNextPage={handleNextPage}
                onPreviousPage={handlePrevPage}
                currentPage={currentPage}
                pageCount={Product.pageCount}
                onPageSelect={handlePageSelect}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
