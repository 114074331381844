import React from "react";

function Ship() {
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Shipping Information</li>
          </ul>
        </div>
      </div>

      {/* ************************* FAQ's ************************ */}

      <div className="ship">
        <div className="container">
          <h1 className="text-dark text-center-mobile">Shipping Information</h1>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-7 text-center-mobile">
              <h5>
                <a className="text-secondary">
                  FlooringDeals offers multiple shipping and delivery options to
                  satisfy your needs. If you have specific delivery
                  requirements, please give us a call or use our chat tool to
                  request a custom shipping quote.
                </a>
              </h5>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 mb-4">
              <div className="d-flex align-items-start">
                <img
                  className="img-fluid me-3"
                  src="assets/images/product/box.jpeg"
                  alt="Parcel deliveries"
                  style={{ width: "50px", height: "50px" }}
                />
                <div>
                  <h5>Parcel deliveries – Tools & Accessories</h5>
                  <p>
                    Delivery time: 2 to 4 business days
                    <br />
                    Cost: 9.99$ or FREE for orders over 100$
                    <br />
                    Additional fees of 34.99$ can apply on certain oversize
                    items
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
              <div className="d-flex align-items-start">
                <img
                  className="img-fluid me-3"
                  src="assets/images/product/box.jpeg"
                  alt="Parcel deliveries"
                  style={{ width: "50px", height: "50px" }}
                />
                <div>
                  <h5>Pallet deliveries – Flooring & heavy items</h5>
                  <p>
                    Delivery time: 3 to 5 business days
                    <br />
                    Cost: Starting at 80.00$ depending on regions
                    <br />
                    Enter your address in the shopping cart to obtain your
                    specific delivery cost
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Horizontal Divider with Text */}
          <div
            style={{ display: "flex", alignItems: "center", margin: "20px 0" }}
          >
            <div
              style={{ flex: 1, height: "1px", backgroundColor: "#D3D3D3" }}
            ></div>
            <div
              style={{
                padding: "0 20px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              HOW DOES IT WORK?
            </div>

            <div
              style={{ flex: 1, height: "1px", backgroundColor: "#D3D3D3" }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            {/* Circle with #1 */}
            <div
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "lightGray",
                color: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                position: "relative",
                top: "10px", // Adjust this value to control how much the circle overlaps the box
                zIndex: 1,
              }}
            >
              #1
            </div>

            {/* Gray Background Content Box */}
            <div
              style={{
                marginTop: "-20px", // Adjust this value to control the overlap effect with the circle
                padding: "40px",
                backgroundColor: "#f0f0f0",
                width: "80%", // Adjust width as needed
                textAlign: "center",
                borderRadius: "8px",
                boxSizing: "border-box",
              }}
            >
              {/* Title */}
              <h3>Confirming Your Order</h3>
              {/* Content */}
              <p style={{ fontSize: "15px" }}>
                After finalizing your purchase you will receive a confirmation email. 
                A follow-up email will be sent once your order is prepared for dispatch.
              </p>
            </div>

            {/* Circle with #2 */}
            <div
              style={{
                marginTop: "30px",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "lightGray",
                color: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                position: "relative",
                top: "10px", // Adjust this value to control how much the circle overlaps the box
                zIndex: 1,
              }}
            >
              #2
            </div>

            {/* Gray Background Content Box */}
            <div
              style={{
                marginTop: "-20px", // Adjust this value to control the overlap effect with the circle
                padding: "40px",
                backgroundColor: "#f0f0f0",
                width: "80%", // Adjust width as needed
                textAlign: "center",
                borderRadius: "8px",
                boxSizing: "border-box",
              }}
            >
              {/* Title */}
              <h3>Parcel Delivery Tracking</h3>
              {/* Content */}
              <p style={{ fontSize: "15px" }}>
              As soon as your order leaves our warehouse, we'll send you an email with a tracking number so you can follow its journey.
              </p>
            </div>

            {/* Circle with #3 */}
            <div
              style={{
                marginTop: "30px",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "lightGray",
                color: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                position: "relative",
                top: "10px", // Adjust this value to control how much the circle overlaps the box
                zIndex: 1,
              }}
            >
              #3
            </div>

            {/* Gray Background Content Box */}
            <div
              style={{
                marginTop: "-20px", // Adjust this value to control the overlap effect with the circle
                padding: "40px",
                backgroundColor: "#f0f0f0",
                width: "80%", // Adjust width as needed
                textAlign: "center",
                borderRadius: "8px",
                boxSizing: "border-box",
              }}
            >
              {/* Title */}
              <h3>Pallet Delivery Tracking</h3>
              {/* Content */}
              <p style={{ fontSize: "15px" }}>
              When your pallet order is packed and delivery scheduled, you'll get an email detailing the delivery date. If you have a preferred delivery date, please inform our team when arranging your order. It's important to provide the delivery crew with clear access to your property, including suitable parking. Please make sure paths and interior areas are free of obstacles. Should the crew encounter difficulties due to inadequate clearance, we'll have to arrange another delivery time, and an additional delivery fee will be charged.
              </p>
            </div>

            {/* Circle with #4 */}
            <div
              style={{
                marginTop: "30px",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "lightGray",
                color: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                position: "relative",
                top: "10px", // Adjust this value to control how much the circle overlaps the box
                zIndex: 1,
              }}
            >
              #4
            </div>

            {/* Gray Background Content Box */}
            <div
              style={{
                marginTop: "-20px", // Adjust this value to control the overlap effect with the circle
                padding: "40px",
                backgroundColor: "#f0f0f0",
                width: "80%", // Adjust width as needed
                textAlign: "center",
                borderRadius: "8px",
                boxSizing: "border-box",
              }}
            >
              {/* Title */}
              <h3>Order Inspection</h3>
              {/* Content */}
              <p style={{ fontSize: "15px" }}>
              Upon delivery, verify that all items on your packing slip are present before the delivery crew departs. If any items are missing, alert the delivery team, note the discrepancies on the packing slip, and contact Flooring Deals customer service immediately.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ship;
