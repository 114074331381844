import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

function ThankYou() {
  const myStoredValue = localStorage.getItem("uniqueId");
  const CopyToClipboardButton = () => {
    const textToCopy = myStoredValue; // Replace with the text or number you want to copy
    const textRef = useRef(null);

    const handleCopyClick = () => {
      // Select the text
      textRef.current.select();

      // Copy the selected text to the clipboard
      document.execCommand("copy");

      // Deselect the text (optional)
      window.getSelection().removeAllRanges();

      // You can also provide some feedback to the user (e.g., show a notification)
      alert("Text copied to clipboard!");
    };
  };
  const [validUntilDate, setValidUntilDate] = useState("");

  useEffect(() => {
    // Calculate the date 30 days from now
    const today = new Date();
    const validUntil = new Date(today);
    validUntil.setDate(today.getDate() + 30);

    // Format the date (e.g., January 15, 2024)
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = validUntil.toLocaleDateString("en-US", options);

    // Update state
    setValidUntilDate(formattedDate);
  }, []);
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Thank You</li>
          </ul>
        </div>
      </div>

      {/* ************************** Quote Final *********************** */}
      <div className="quote-final ptb-50">
        <div className="container">
          <div className="row text-center">
            <p>
              <h5>Thank You</h5>
              <br />
              We have saved your Installation Cost Estimation With Your ID:
            </p>
            <h5>
              {myStoredValue}
              <i class="bi bi-copy"></i>
            </h5>
            {/* Will make valid until date dynamic */}
            {/* <p>
              You can visit your link anytime to add or remove to your list,{" "}
              <br />
              Please note that prices are valid until January 15, 2024.
            </p> */}
            <p>
              You can visit your link anytime to add or remove to your list,{" "}
              <br />
              <strong>
                Please note that prices are valid until {validUntilDate}.
              </strong>
            
            </p>
            <div class="checkout-account text-center mt-2">
              <Link to="">
                https://flooringdeals.ca/public-quote?v=kH{" "}
                <i class="bi bi-copy"></i>
              </Link>
            </div>
            <div className="easy-services mt-4">
              <Link to="/costestimationInstallationPage">
                <button className="installtion-buttton text-white">
                  Keep Editing Your Estimation
                </button>
              </Link>
              <span className="ps-3 pe-3">or</span>
              <Link to="/" className="Text-white">
                <button className="installtion-buttton text-white">
                  Keep Exploring Flooring Deals
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
