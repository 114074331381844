import { configureStore } from "@reduxjs/toolkit";
import fevSlice from "./slices/FavSlices"
import cartSlice from "./slices/CardSlice"
import addressSlice from "./slices/Address"
import getuserSlice from "./slices/getuserSlice"
import AuthSlice from "./slices/AuthSlice";
import categorySlice from "./slices/Category";

const store =configureStore({
  reducer:{
    auth: AuthSlice,
    favroite: fevSlice,
    cart: cartSlice,
    address:addressSlice,
    singleUser:getuserSlice,
    categories: categorySlice
  }
})
export default store




