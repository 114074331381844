import { Link as RouterLink } from "react-router-dom";

export const Link = ({to, children, ...props}) => (
    <RouterLink {...props} to={to}>
      {children}
    </RouterLink>
);

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
