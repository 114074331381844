import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const VerifiedEmail = () => {
  const { token } = useParams(); // Get token from URL params
  const [isVerified, setIsVerified] = useState(null); // State to track the verification status
  const [loading, setLoading] = useState(true); // State to track loading state
  const [error, setError] = useState(null); // State to track any error

  useEffect(() => {
    // Hit the API when component is mounted
    const verifyEmail = async () => {
      try {
        const response = await axios.get(
          `https://allinonetables.com/api/user/verify/${token}`
        );
        
        // Handle the response
        console.log(response.data);
        setIsVerified(true); // Set verification status based on the response
      } catch (err) {
        console.error(err);
        setError("Verification failed. Please try again.");
        setIsVerified(false); // Set verification status to false in case of error
      } finally {
        setLoading(false); // Set loading to false once the request is complete
      }
    };

    verifyEmail();
  }, [token]); // The effect runs when the token changes

  if (loading) {
    return <p>Loading...</p>; // You can show a loading indicator while waiting for the API response
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="shadow p-4 rounded bg-white text-center w-75 w-md-50 mt-4">
        <h5 className="fw-bold mb-3" style={{ color: "#E77E32" }}>
          {isVerified ? "Successfully Verified" : "Verification Failed"}
        </h5>
        <p className="mb-0">
          {isVerified
            ? "Your email is successfully verified. Go back and login."
            : error || "There was an issue with email verification."}
        </p>
        <Link to={"/login"}>
          <p className="fw-bold mb-3" style={{ color: "#E77E32" }}>
            Login
          </p>
        </Link>
      </div>
    </div>
  );
};

export default VerifiedEmail;
