import React from "react";
import { Link } from "react-router-dom";

function NotFound404() {
  return (
    <>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container ">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Not Found</li>
          </ul>
        </div>
      </div>
      {/* ************************ error section ******************** */}

      <div className="ptb-50">
        <div className="container">
          <div className="logo text-center pb-4">
            <Link to="/">
              <img
                src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                style={{ width: "100px" }}
                alt=""
              />
            </Link>
          </div>
          <div className="404 pb-3 d-flex justify-content-center align-items-center fs-1 text-circle">
            <i class="bi bi-exclamation-circle myColor"></i>
            <h1 className="text-center ps-3 myColor">404</h1>
          </div>
          <p className="text-center fs-3 pb-3"> Oops! Page Not Found</p>
          <Link to="/" className="btn d-flex justify-content-center myColor">
            Go back to Home
          </Link>
        </div>
      </div>
    </>
  );
}

export default NotFound404;
