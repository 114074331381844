import { Skeleton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function Banners({banners, loading, error}) {
	if(error) {
		return (
			<div className="container w-100">
				<div id="carouselExampleCaptions" className="carousel slide">
					<p>something Went wrong</p>	
				</div>
    </div>
		)
	}

	return (
    <div className="container w-100">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-indicators container">
          {loading ? (
            <Skeleton variant="rectangular" width={40} height={20} />
          ) : (
            banners && banners.map((item, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to={index.toString()}
                className={index === 0 ? "active" : ""}
                aria-current={index === 0 ? "true" : undefined}
                aria-label={`Slide ${index + 1}`}
              ></button>
            ))
          )}
        </div>
        <div className="carousel-inner">
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={371} />
          ) : (
            banners && banners.map((item, index) => (
              <div
                key={index}
                className={`carousel-item gradient ${index === 0 ? "active" : ""}`}
              >
                <img
                  src={item.image}
                  className="d-block w-100 img-fluid"
                  style={{ height: "371px" }}
                  alt="..."
                />
                <div className="carousel-caption image-caption d-md-block">
                  <h1>{item.mainText}</h1>
                  <h5>{item.subText}</h5>
                  <Link to="/discountPage">Explore Discount</Link>
                </div>
              </div>
            ))
          )}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span className="as-bg" aria-hidden="true">
            <i className="bi bi-arrow-left-short"></i>
          </span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span className="as-bg" aria-hidden="true">
            <i className="bi bi-arrow-right-short"></i>
          </span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Banners;
