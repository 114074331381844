export const menuItemsCollectionAll = [
    { id: 1, collection: 'Authentic Ocean' },
    { id: 2, collection: 'Authentic Premium' },
    { id: 3, collection: 'Concerto Edge' },
    { id: 4, collection: 'Crossweave Canvas' },
    { id: 5, collection: 'Crownland' },
    { id: 6, collection: 'Divine' },
    { id: 7, collection: 'Dynamix XL Tile' },
    { id: 8, collection: 'Ice Malena' },
    { id: 9, collection: 'Ivory Malena' },
    { id: 10, collection: 'Smartdrop' }
];

export const menuItemsCollectionLaminate = [
    { id: 1, collection: 'Authentic Ocean' },
    { id: 2, collection: 'Authentic Premium' },
    { id: 3, collection: 'Concerto Edge' },
];
export const menuItemsCollectionVynl = [
    { id: 1, collection: 'Dynamix XL Tile' },
    { id: 2, collection: 'Smartdrop' },
];
export const menuItemsCollectionTile = [
    { id: 1, collection: 'Ice Malena' },
    { id: 2, collection: 'Ivory Malena' },
    { id: 3, collection: 'Crossweave Canvas' },
];
export const menuItemsCollectionWood = [
    { id: 1, collection: 'Crownland' },
    { id: 2, collection: 'Divine' },
];

export const menuItemsCarpetDensity = [
    { id: 1, Density: "5500", Count: 15 },
    { id: 2, Density: "6101.69", Count: 15 },
    { id: 3, Density: "3498", Count: 12 },
    { id: 4, Density: "5000", Count: 12 },
    { id: 5, Density: "5500 oz/yd³, 186481 g/m³", Count: 12 },
    { id: 6, Density: "6510.64", Count: 12 },
    { id: 7, Density: "16 959 oz/yd³ (628 787 g/m³)", Count: 6 },
    { id: 8, Density: "17164 oz/yd³ (636363 g/m³)", Count: 6 },
    { id: 9, Density: "4766 oz/yd³ (176700 g/m³)", Count: 6 },
    { id: 10, Density: "5 000 oz/yd³", Count: 6 },
    { id: 11, Density: "5500 oz /yd3", Count: 6 },
    { id: 12, Density: "8808", Count: 6 },
    { id: 13, Density: "4345", Count: 5 },
    { id: 14, Density: "4445", Count: 5 },
    { id: 15, Density: "3659", Count: 4 },
    { id: 16, Density: "4116", Count: 4 },
    { id: 17, Density: "600g/m²", Count: 4 },
    { id: 18, Density: "800g/m²", Count: 4 },
    { id: 19, Density: "5500 g/m²", Count: 3 },
    { id: 20, Density: "750g/m²", Count: 2 }
];

 /* ************************ SidebarFiltering Data Sport Floor START ******************** */
 export const menuItemsSportProductType= [
    { id: 1, product_type: "Rubber Mats" },
    { id: 2, product_type: "High Impact Flooring" },
    { id: 3, product_type: "Playground Tiles" },
    { id: 4, product_type: "Outdoor Rubber Flooring" },
    { id: 5, product_type: "Horse Stall Mats" },
  ];

  export const menuItemsSportMaterial = [
    { id: 1, Material: "Nylon" },
    { id: 2, Material: "Olefin" },
    { id: 3, Material: "Polyester" },
    { id: 4, Material: "Polypropylene" },
    { id: 5, Material: "Rubber" },
    { id: 6, Material: "Foam" }
];

export const menuItemsSportOunces = [
    { id: 1, Onces: "17", display: "17" },
    { id: 2, Onces: "22oz", display: "22 oz./sq. yard" },
    { id: 3, Onces: "18oz", display: "18 oz. /sq. yard" },
    { id: 4, Onces: "19", display: "19" },
    { id: 5, Onces: "16", display: "16" },
    { id: 6, Onces: "17oz", display: "17 oz./sq. yard" },
    { id: 7, Onces: "18", display: "18" },
    { id: 8, Onces: "18oz", display: "18 oz./sq. yard" },
    { id: 9, Onces: "19oz", display: "19 oz./sq. yard" },
    { id: 10, Onces: "20oz", display: "20 oz./sq. yard" },
    { id: 11, Onces: "21oz", display: "21 oz./sq. yard" },
    { id: 12, Onces: "24", display: "24" },
    { id: 13, Onces: "22.5", display: "22.5" }
    // Add more entries as needed
];





  

 /* ************************ SidebarFiltering Data WOOD START ******************** */
//Species for Wood
export const menuItemsWoodColor = [
  {
    id: 1,
    color_name: "Brown",
  },

  {
    id: 2,
    color_name: "Beige",
  },
  {
    id: 3,
    color_name: "Gray",
  },
  {
    id: 4,
    color_name: "White",
  },
  {
    id: 5,
    color_name: "Black",
  },
  {
    id: 6,
    color_name: "Red",
  },
  {
    id: 7,
    color_name: "Green",
  },
];

export const menuItemsWoodSpecies = [
    { id: 1, Species: 'Acacia' },
    { id: 2, Species: 'Ash' },
    { id: 3, Species: 'Birch' },
    { id: 4, Species: 'Cherry Tree' },
    { id: 5, Species: 'Hickory' },
    { id: 6, Species: 'Jatoba' },
    { id: 7, Species: 'Mahogany' },
    { id: 8, Species: 'Maple' },
    { id: 9, Species: 'Pine' },
    { id: 10, Species: 'Red Oak' },
    { id: 11, Species: 'Sapele' },
    { id: 12, Species: 'Walnut' },
    { id: 13, Species: 'White Oak' },
];

export const menuItemsWoodThickness = [
    { id: 1, thickness: "12.7", display: "1/2" },
    { id: 2, thickness: "6.35", display: "1/4" },
    { id: 3, thickness: "19.05", display: "3/4" },
    { id: 4, thickness: "0.52", display: "3/8" },
    { id: 5, thickness: "15.88", display: "5/8" },
  ];
  
  export const menuItemsWoodWidth = [
    { id: 1, width: "1.5", display: "1-1/2" },
    { id: 2, width: "1.25", display: "1-1/4" },
    { id: 3, width: "1.75", display: "1-3/4" },
    { id: 4, width: "1.625", display: "1-5/8" },
    { id: 5, width: "2", display: "2" },
    { id: 6, width: "2.25", display: "2-1/4" },
    { id: 7, width: "2.125", display: "2-1/8" },
    { id: 8, width: "2.375", display: "2-3/8" },
    { id: 9, width: "3", display: "3" },
    { id: 10, width: "3.5", display: "3-1/2" },
    { id: 11, width: "3.25", display: "3-1/4" },
    { id: 12, width: "3.125", display: "3-1/8" },
    { id: 13, width: "0.75", display: "3/4" },
    { id: 14, width: "4", display: "4" },
    { id: 15, width: "4.5", display: "4-1/2" },
    { id: 16, width: "4.25", display: "4-1/4" },
    { id: 17, width: "4.125", display: "4-1/8" },
    { id: 18, width: "4.875", display: "4-7/8" },
    { id: 19, width: "5", display: "5" },
    { id: 20, width: "5.5", display: "5-1/2" },
    { id: 21, width: "5.25", display: "5-1/4" },
    { id: 22, width: "5.75", display: "5-3/4" },
    { id: 23, width: "5.875", display: "5-7/8" },
    { id: 24, width: "6", display: "6" },
    { id: 25, width: "6.5", display: "6-1/2" },
    { id: 26, width: "6.25", display: "6-1/4" },
    { id: 27, width: "6.75", display: "6-3/4" },
    { id: 28, width: "6.375", display: "6-3/8" },
    { id: 29, width: "7", display: "7" },
    { id: 30, width: "7.5", display: "7-1/2" },
    { id: 31, width: "8", display: "8" },
    { id: 32, width: "8.5", display: "8-1/2" },
    { id: 33, width: "8.25", display: "8-1/4" },
    { id: 34, width: "9", display: "9" },
    { id: 35, width: "9.5", display: "9-1/2" },
  ];
  
  
export const menuItemsWoodFinish = [
    { id: 1, Finish: "Glossy" },
    { id: 2, Finish: "Mattee" },
    { id: 3, Finish: "Semi-Gloss" },
    { id: 4, Finish: "Semi-Polished" },
    { id: 5, Finish: "Satin" },
];

export const menuItemsWoodSurfaceTexture = [
    { id: 1, Surface_Texture: "Handscraped" },
    { id: 2, Surface_Texture: "Smooth" },
    { id: 3, Surface_Texture: "Wirebrushed" },
    { id: 4, Surface_Texture: "Wirebrushed & Handscraped" },
    { id: 5, Surface_Texture: "Distressed" },
    { id: 5, Surface_Texture: "Saw Mark" },
];
/* ************************ SidebarFiltering Data WOOD END ******************** */


/* ************************ SidebarFiltering Data VINYL START ******************** */
export const menuItemsVinylColor = [
    {
      id: 1,
     color_name: "Gray",
    },
  
    {
        id: 2,
       color_name: "Beige",
    },
    {
        id: 3,
       color_name: "Brown",
    },
    {
        id: 4,
       color_name: "Blue",
    },
    {
        id: 5,
       color_name: "White",
    },
    {
        id: 6,
       color_name: "Green",
    },
    {
        id: 7,
       color_name: "Black",
    },
    {
        id: 8,
       color_name: "Yellow",
      },
    
      {
          id: 9,
         color_name: "Red",
      },
      {
          id: 10,
         color_name: "Orange",
      },
      {
          id: 11,
         color_name: "Pink",
      },
      {
          id: 12,
         color_name: "Purple",
      },

  ];

export const menuItemsVinylProductType = [
    { id: 1, product_type: 'Click Vinyl Planks' },
    { id: 2, product_type: 'Click Vinyl Tiles' },
    { id: 3, product_type: 'Floor Welding Rod' },
    { id: 4, product_type: 'Glue Down Vinyl Planks' },
    { id: 5, product_type: 'Glue Down Vinyl Tiles' },
    { id: 6, product_type: 'Heterogeneous Vinyl Roll' },
    { id: 7, product_type: 'Heterogeneous Vinyl Tiles' },
    { id: 8, product_type: 'Homogeneous Vinyl Roll' },
    { id: 9, product_type: 'Homogeneous Vinyl Tiles' },
    { id: 10, product_type: 'Linoleum Sheet' },
    { id: 11, product_type: 'Loose Lay Vinyl Planks' },
    { id: 12, product_type: 'Loose Lay Vinyl Tiles' },
    { id: 13, product_type: 'Marmoleum Roll' },
    { id: 14, product_type: 'Marmoleum Tile' },
    { id: 15, product_type: 'VCT Flooring' },
    { id: 16, product_type: 'VCT Tiles' },
    { id: 17, product_type: 'Vinyl Matching Floor Vent' },
    { id: 18, product_type: 'Vinyl Matching Quarter Round' },
    { id: 19, product_type: 'Vinyl Matching Reducer' },
    { id: 20, product_type: 'Vinyl Matching Slim Transition' },
    { id: 21, product_type: 'Vinyl Matching Stair Kit' },
    { id: 22, product_type: 'Vinyl Matching Stair Nose' },
    { id: 23, product_type: 'Vinyl Matching T-Molding' },
    { id: 24, product_type: 'Vinyl Matching Threshold' },
    { id: 25, product_type: 'Vinyl Matching Wall Base' },
    { id: 26, product_type: 'Vinyl Sheet' },
    { id: 27, product_type: 'Vinyl Tiles' },
    { id: 28, product_type: 'Wall Linoleum' },
    { id: 29, product_type: 'Wall Linoleum Sheet' },
    { id: 30, product_type: 'Wood Veneer SPC' },
];

export const menuItemsVinylThickness = [
    { id: 1, thickness: "1" },
    { id: 2, thickness: "1.5" },
    { id: 3, thickness: "10" },
    { id: 4, thickness: "11" },
    { id: 5, thickness: "12" },
    { id: 6, thickness: "12.5" },
    { id: 7, thickness: "17" },
    { id: 8, thickness: "2" },
    { id: 9, thickness: "2.5" },
    { id: 10, thickness: "25" },
    { id: 11, thickness: "28" },
    { id: 12, thickness: "3" },
    { id: 13, thickness: "3.5" },
    { id: 14, thickness: "35" },
    { id: 15, thickness: "4" },
    { id: 16, thickness: "4.5" },
    { id: 17, thickness: "5" },
    { id: 18, thickness: "5.5" },
    { id: 19, thickness: "6" },
    { id: 20, thickness: "6.5" },
    { id: 21, thickness: "7" },
    { id: 22, thickness: "8" },
    { id: 23, thickness: "8.5" },
    { id: 24, thickness: "9" },
    { id: 25, thickness: "9.5" },
  ];
  

export const menuItemsVinylWidth = [
    { id: 1, width: "0.5" },
    { id: 2, width: "1" },
    { id: 3, width: "1.5" },
    { id: 4, width: "10" },
    { id: 5, width: "11" },
    { id: 6, width: "12" },
    { id: 7, width: "12.5" },
    { id: 8, width: "144" },
    { id: 9, width: "16" },
    { id: 10, width: "18" },
    { id: 11, width: "19.5" },
    { id: 12, width: "2" },
    { id: 13, width: "2.5" },
    { id: 14, width: "24" },
    { id: 15, width: "3" },
    { id: 16, width: "4" },
    { id: 17, width: "4.5" },
    { id: 18, width: "5" },
    { id: 19, width: "6" },
    { id: 20, width: "6.5" },
    { id: 21, width: "7" },
    { id: 22, width: "7.5" },
    { id: 23, width: "72" },
    { id: 24, width: "78" },
    { id: 25, width: "78.5" }
  ];
  

export const menuItemsVinylLength = [
    { id: 1, length: '10' },
    { id: 2, length: '106.5' },
    { id: 3, length: '12' },
    { id: 4, length: '122' },
    { id: 5, length: '1260' },
    { id: 6, length: '144' },
    { id: 7, length: '1440' },
    { id: 8, length: '1575' },
    { id: 9, length: '16' },
    { id: 10, length: '1748' },
    { id: 11, length: '18' },
    { id: 12, length: '19.5' },
    { id: 13, length: '1968.5' },
    { id: 14, length: '23.5' },
    { id: 15, length: '2300' },
    { id: 16, length: '2316.5' },
    { id: 17, length: '24' },
    { id: 18, length: '24.5' },
    { id: 19, length: '2500' },
    { id: 20, length: '28.5' },
    { id: 21, length: '29.5' },
    { id: 22, length: '32' },
    { id: 23, length: '35.5' },
    { id: 24, length: '36' },
    { id: 25, length: '39.5' },
    { id: 26, length: '47' },
    { id: 27, length: '47.5' },
    { id: 28, length: '48' },
    { id: 29, length: '48.5' },
    { id: 30, length: '49' },
    { id: 31, length: '51' },
    { id: 32, length: '52' },
    { id: 33, length: '54' },
    { id: 34, length: '59' },
    { id: 35, length: '59.5' },
    { id: 36, length: '60' },
    { id: 37, length: '60.5' },
    { id: 38, length: '72' },
    { id: 39, length: '78' },
    { id: 40, length: '906' },
    { id: 41, length: '92' },
    { id: 42, length: '94' },
    { id: 43, length: '94.5' },
    { id: 44, length: '95' },
    { id: 45, length: '96' },
    { id: 46, length: '984' },
];

export const menuItemsVinylWearLayerThickness = [
    { id: 1, wearlayerthickness: "0.05" },
    { id: 2, wearlayerthickness: "0.1" },
    { id: 3, wearlayerthickness: "0.15" },
    { id: 4, wearlayerthickness: "0.2" },
    { id: 5, wearlayerthickness: "0.25" },
    { id: 6, wearlayerthickness: "0.3" },
    { id: 7, wearlayerthickness: "0.35" },
    { id: 8, wearlayerthickness: "0.4" },
    { id: 9, wearlayerthickness: "0.5" },
    { id: 10, wearlayerthickness: "0.55" },
    { id: 11, wearlayerthickness: "0.65" },
    { id: 12, wearlayerthickness: "0.7" },
    { id: 13, wearlayerthickness: "0.8" },
    { id: 14, wearlayerthickness: "1" },
    { id: 15, wearlayerthickness: "1.2" },
    { id: 16, wearlayerthickness: "1.3" },
    { id: 17, wearlayerthickness: "1.4" },
    { id: 18, wearlayerthickness: "1.65" },
    { id: 19, wearlayerthickness: "2" },
];

export const menuItemsVinylUnderpadAttached = [
    { id: 1, Material: "Underpad Attached" }, //Underpad_Attached now use Material in database for now
    { id: 2, Material: "Underpad Not Attached" },
];


/* ************************ SidebarFiltering Data laminate START ******************** */
export const menuItemsLaminateProductType = [
    { id: 1, product_type: 'Laminate Flooring' },
    { id: 2, product_type: 'Laminate Matching End Cap' },
    { id: 3, product_type: 'Laminate Matching Quarter Round' },
    { id: 4, product_type: 'Laminate Matching Reducer' },
    { id: 5, product_type: 'Laminate Matching Slim Transition' },
    { id: 6, product_type: 'Laminate Matching Stair Kit' },
    { id: 7, product_type: 'Laminate Matching Stair Nose' },
    { id: 8, product_type: 'Laminate Matching T-Molding' },
    { id: 9, product_type: 'Laminate Matching Threshold' },
];

export const menuItemsACDurabilityRating = [
    { id: 1, ac: 'AC3' },
    { id: 2, ac: 'AC4' },
    { id: 3, ac: 'AC5' },
    { id: 3, ac: 'AC6' }
];


export const menuItemsLaminateColor = [
    { id: 1, color_name: 'Brown' },
    { id: 2, color_name: 'Beige' },
    { id: 3, color_name: 'Gray' },
    { id: 4, color_name: 'White' },
    { id: 5, color_name: 'Black' },
    { id: 6, color_name: 'Red' },
    { id: 7, color_name: 'Blue' }
];

export const menuItemsWaterResistance = [
    { id: 1, Material: 'Water Resistant' },  //use Material in database for now  add Water_Resistance in the future 
    { id: 2, Material: 'Non Water Resistant' }
];

export const menuItemsLaminateSurfaceTexture = [
    { id: 1, Surface_Texture: 'Embossed' },
    { id: 2, Surface_Texture: 'Smooth' },
    { id: 3, Surface_Texture: 'Wirebrushed' }
];

export const menuItemsLaminateThickness = [
    { id: 1, thickness: "10" },
    { id: 2, thickness: "12" },
    { id: 3, thickness: "8" },
];

export const menuItemsLaminateWidth = [
    { id: 1, width: "0.5" },
    { id: 2, width: "1" },
    { id: 3, width: "1.5" },
    { id: 4, width: "12" },
    { id: 5, width: "13" },
    { id: 6, width: "2" },
    { id: 7, width: "2.5" },
    { id: 8, width: "3" },
    { id: 9, width: "3.5" },
    { id: 10, width: "4" },
    { id: 11, width: "4.5" },
    { id: 12, width: "5" },
    { id: 13, width: "5.5" },
    { id: 14, width: "6" },
    { id: 15, width: "6.5" },
    { id: 16, width: "7" },
    { id: 17, width: "7.5" },
    { id: 18, width: "8" },
    { id: 19, width: "9.5" },
    { id: 20, width: "13" }
  ];
  

export const menuItemsLaminateLength = [
    { id: 1, length: "106" },
    { id: 2, length: "110" },
    { id: 3, length: "25" },
    { id: 4, length: "26" },
    { id: 5, length: "35" },
    { id: 6, length: "47" },
    { id: 7, length: "47.5" },
    { id: 8, length: "48" },
    { id: 9, length: "50.5" },
    { id: 10, length: "53" },
    { id: 11, length: "54" },
    { id: 12, length: "54.5" },
    { id: 13, length: "55" },
    { id: 14, length: "60" },
    { id: 15, length: "71.5" },
    { id: 16, length: "72" },
    { id: 17, length: "72.5" },
    { id: 18, length: "87" },
    { id: 19, length: "92" },
    { id: 20, length: "93" },
    { id: 21, length: "94" },
    { id: 22, length: "94.5" },
    { id: 23, length: "95" },
    { id: 24, length: "96" },
  ];

  export const menuItemsLaminateFinish = [
    { id: 1, Finish: "Matte" },
  ];

  /* ************************ SidebarFiltering Data Carpet Color START ******************** */
  
  export const menuItemsCarpetColor = [
    { id: 1, color_name: "Gray", display: "Gray" },
    { id: 2, color_name: "Brown", display: "Brown" },
    { id: 3, color_name: "Beige", display: "Beige" },
    { id: 4, color_name: "Blue", display: "Blue" },
    { id: 5, color_name: "Green", display: "Green" },
    { id: 6, color_name: "Black", display: "Black" },
    { id: 7, color_name: "Red", display: "Red" },
    { id: 8, color_name: "Orange", display: "Orange" },
    { id: 9, color_name: "Purple", display: "Purple" },
    { id: 10, color_name: "Yellow", display: "Yellow" },
    { id: 11, color_name: "Pink", display: "Pink" },
    { id: 12, color_name: "White", display: "White" }
  ];

  export const menuItemsCarpetSize = [
    { id: 1, Approximate_sizeIN: "10x39", display: "10\" x 39\"" },
    { id: 2, Approximate_sizeIN: "10x39.5", display: "10\" x 39.5\"" },
    { id: 3, Approximate_sizeIN: "10x40", display: "10\" x 40\"" },
    { id: 4, Approximate_sizeIN: "13x40", display: "13\" x 40\"" },
    { id: 5, Approximate_sizeIN: "19x19", display: "19\" x 19\"" },
    { id: 6, Approximate_sizeIN: "19x39", display: "19\" x 39\"" },
    { id: 7, Approximate_sizeIN: "19.5x19.5", display: "19.5\" x 19.5\"" },
    { id: 8, Approximate_sizeIN: "20x20", display: "20\" x 20\"" },
    { id: 9, Approximate_sizeIN: "20x40", display: "20\" x 40\"" },
    { id: 10, Approximate_sizeIN: "24x24", display: "24\" x 24\"" },
    { id: 11, Approximate_sizeIN: "39.5x39.5", display: "39.5\" x 39.5\"" },
    { id: 12, Approximate_sizeIN: "9x39", display: "9\" x 39\"" }
  ];

  export const menuItemsCarpetThickness = [
    { id: 1, thickness: "2.1", display: "2.1 mm" },
    { id: 2, thickness: "3.5", display: "3.5 mm" },
    { id: 3, thickness: "3.6", display: "3.6 mm" },
    { id: 4, thickness: "4", display: "4 mm" },
    { id: 5, thickness: "4.3", display: "4.3 mm" },
    { id: 6, thickness: "4.5", display: "4.5 mm" },
    { id: 7, thickness: "5", display: "5 mm" },
    { id: 8, thickness: "5.5", display: "5.5 mm" },
    { id: 9, thickness: "6", display: "6 mm" },
    { id: 10, thickness: "6.5", display: "6.5 mm" },
    { id: 11, thickness: "7", display: "7 mm" },
    { id: 12, thickness: "7.5", display: "7.5 mm" },
    { id: 13, thickness: "8", display: "8 mm" }
    // Add more items as needed
];

export const menuItemsCarpetPileWeight = [  
    { id: 1, Species: "13", display: "13" }, //use Species instead of PileWeight to fill value in data for now 
    { id: 2, Species: "14.6", display: "14.6" },
    { id: 3, Species: "15", display: "15" },
    { id: 4, Species: "15-510", display: "15 oz/sqyd (510 g/m²)" },
    { id: 5, Species: "15.6", display: "15.6" },
    { id: 6, Species: "15.63", display: "15.63" },
    { id: 7, Species: "16-520", display: "16 oz/sqyd (520 g/m²)" },
    { id: 8, Species: "16-540", display: "16 oz/sqyd (540 g/m²)" },
    { id: 9, Species: "16-550", display: "16 oz/yd², 550 g/m²" },
    { id: 10, Species: "17", display: "17" },
    { id: 11, Species: "17.4", display: "17.4" },
    { id: 12, Species: "17.7", display: "17.7" },
    { id: 13, Species: "17.9", display: "17.9" },
    { id: 14, Species: "18", display: "18" },
    { id: 15, Species: "20", display: "20" },
    { id: 16, Species: "21", display: "21" },
    { id: 17, Species: "22", display: "22" },
    { id: 18, Species: "22.12", display: "22.12" },
    { id: 19, Species: "23", display: "23" },
    { id: 20, Species: "23.5", display: "23.5" },
    { id: 21, Species: "23.59", display: "23.59" },
    { id: 22, Species: "23.6", display: "23.6" },
    { id: 23, Species: "25", display: "25" },
    { id: 24, Species: "28", display: "28" },
    { id: 25, Species: "30", display: "30" },
    // More items can be added as needed
];

export const menuItemsCarpetLinearDensity = [
    { id: 1, Surface_Texture: "10", display: "10" }, //use Surface_Texture instead of Surface_Texture to fill value in data for now 
    { id: 2, Surface_Texture: "10.28", display: "10.28" },
    { id: 3, Surface_Texture: "10.3", display: "10.3" },
    { id: 4, Surface_Texture: "10.4", display: "10.4" },
    { id: 5, Surface_Texture: "10.5", display: "10.5" },
    { id: 6, Surface_Texture: "10.63", display: "10.63" },
    { id: 7, Surface_Texture: "10.7", display: "10.7" },
    { id: 8, Surface_Texture: "10.8", display: "10.8" },
    { id: 9, Surface_Texture: "10.9", display: "10.9" },
    { id: 10, Surface_Texture: "10.96", display: "10.96" },
    { id: 11, Surface_Texture: "11", display: "11" },
    { id: 12, Surface_Texture: "11.15", display: "11.15" },
    { id: 13, Surface_Texture: "11.3", display: "11.3" },
    { id: 14, Surface_Texture: "11.5", display: "11.5" },
    { id: 15, Surface_Texture: "11.6", display: "11.6" },
    { id: 16, Surface_Texture: "11.8", display: "11.8" },
    { id: 17, Surface_Texture: "11.9", display: "11.9" },
    { id: 18, Surface_Texture: "12", display: "12" },
    { id: 19, Surface_Texture: "12.1", display: "12.1" },
    { id: 20, Surface_Texture: "12.2", display: "12.2" },
    { id: 21, Surface_Texture: "12.26", display: "12.26" },
    { id: 22, Surface_Texture: "12.3", display: "12.3" },
    { id: 23, Surface_Texture: "12.4", display: "12.4" },
    { id: 24, Surface_Texture: "12.5", display: "12.5" },
    { id: 25, Surface_Texture: "12.6", display: "12.6" },
    // Add more entries as needed
];

export const menuItemsCarpetPileConstructionMethod = [ //edgeType for now
    { id: 1, edgeType: "Artificial Grass", display: "Artificial Grass" },
    { id: 2, edgeType: "Cut Pile Frieze", display: "Cut Pile Frieze" },
    { id: 3, edgeType: "Cut Pile Textured", display: "Cut Pile Textured" },
    { id: 4, edgeType: "Cut pile", display: "Cut pile" },
    { id: 5, edgeType: "Enhanced Textured Loop Pattern", display: "Enhanced Textured Loop Pattern" },
    { id: 6, edgeType: "Level Loop", display: "Level Loop" },
    { id: 7, edgeType: "Level Loop Berber", display: "Level Loop Berber" },
    { id: 8, edgeType: "Level Loop Graphic", display: "Level Loop Graphic" },
    { id: 9, edgeType: "Loop", display: "Loop" },
    { id: 10, edgeType: "Loop Pile Tip Sheared", display: "Loop Pile Tip Sheared" },
    { id: 11, edgeType: "Loop-Cut-Loop", display: "Loop-Cut-Loop" },
    { id: 12, edgeType: "Multi-Level Loop Graphic", display: "Multi-Level Loop Graphic" },
    { id: 13, edgeType: "Multi-Level Loop Pile", display: "Multi-Level Loop Pile" },
    { id: 14, edgeType: "Multi-level loop", display: "Multi-level loop" },
    { id: 15, edgeType: "Multilevel Loop", display: "Multilevel Loop" },
    { id: 16, edgeType: "Multilevel Pattern Loop", display: "Multilevel Pattern Loop" },
    { id: 17, edgeType: "Needled Felt", display: "Needled Felt" },
    { id: 18, edgeType: "Patterned Level Cut & Loop (LCL)", display: "Patterned Level Cut & Loop (LCL)" },
    { id: 19, edgeType: "Plain Loop Pile", display: "Plain Loop Pile" },
    { id: 20, edgeType: "Scroll Loop", display: "Scroll Loop" },
    { id: 21, edgeType: "Textured Loop Berber", display: "Textured Loop Berber" },
    { id: 22, edgeType: "Textured Loop Pattern (Tip Sheared)", display: "Textured Loop Pattern (Tip Sheared)" },
    { id: 23, edgeType: "Textured curl", display: "Textured curl" },
    { id: 24, edgeType: "Variable Loop", display: "Variable Loop" },
    { id: 25, edgeType: "Variable Loop Over Cut", display: "Variable Loop Over Cut" }
    // Add more entries as needed
];

export const menuItemsCarpetOunces = [
    { id: 1, Onces: "15.3", display: "15.3" },
    { id: 2, Onces: "16oz", display: "16 oz./sq. yard" },
    { id: 3, Onces: "17", display: "17" },
    { id: 4, Onces: "22oz", display: "22 oz./sq. yard" },
    { id: 5, Onces: "18oz", display: "18 oz. /sq. yard" },
    { id: 6, Onces: "19", display: "19" },
    { id: 7, Onces: "16", display: "16" },
    { id: 8, Onces: "17oz", display: "17 oz./sq. yard" },
    { id: 9, Onces: "18", display: "18" },
    { id: 10, Onces: "18oz", display: "18 oz./sq. yard" },
    { id: 11, Onces: "19oz", display: "19 oz./sq. yard" },
    { id: 12, Onces: "20oz", display: "20 oz./sq. yard" },
    { id: 13, Onces: "21oz", display: "21 oz./sq. yard" },
    { id: 14, Onces: "24", display: "24" },
    { id: 15, Onces: "22.5", display: "22.5" }
    // Add more entries as needed
];

export const menuItemsSportDensity = [
    { id: 1, Density: "500" },
    { id: 2, Density: "750" },
    { id: 3, Density: "900" }, // Common for Olefin/Polypropylene
    { id: 4, Density: "1150" }, // Common for Nylon
    { id: 5, Density: "1380" }, // Common for Polyester
    { id: 6, Density: "1522" }, // Common for Rubber
    { id: 7, Density: "1600" },
    { id: 8, Density: "1800" },
    { id: 9, Density: "2000" },
    { id: 10, Density: "2200" },
    { id: 11, Density: "2400" },
    { id: 12, Density: "2600" },
    { id: 13, Density: "2800" },
    { id: 14, Density: "3000" },
    { id: 15, Density: "3200" },
    { id: 16, Density: "3400" },
    { id: 17, Density: "3600" },
    { id: 18, Density: "3800" },
    { id: 19, Density: "4000" },
    { id: 20, Density: "4200" },
    { id: 21, Density: "4400" },
    { id: 22, Density: "4600" },
    { id: 23, Density: "4800" },
    { id: 24, Density: "5000" }
];


export const menuItemsSportInstallation = [
    {id: 1, installMethod: "Interlocking",},
    {id: 2, installMethod: "Glue-Free",},
  ];

  /* ************************ SidebarFiltering Data Membranes START ******************** */

  export const menuItemsMembranesCoverage = [
    { id: 1, covrage: "50", display: "50 sqft" },
    { id: 2, covrage: "100", display: "100 sqft" },
    { id: 3, covrage: "150", display: "150 sqft" },
    { id: 4, covrage: "200", display: "200 sqft" },
    { id: 5, covrage: "250", display: "250 sqft" },
    { id: 6, covrage: "300", display: "300 sqft" },
    { id: 7, covrage: "650", display: "650 sqft" }
];

export const menuItemsMembranesOnlyThicknessMM = [
    { id: 1, thickness: "3" },
    { id: 2, thickness: "4"},
    { id: 3, thickness: "5"},
    { id: 4, thickness: "5.5"},
    { id: 5, thickness: "6"},
    { id: 6, thickness: "6.5"},
    { id: 7, thickness: "7"}
];

export const menuItemsMembranesOnyHeatType = [
    { id: 1, edgeType: "Can be customized with of DITRA-HEAT-HK floor heating cables" },
    { id: 2, edgeType: "Electric Heat Only"},
];

export const menuItemsMembranesThicknessFraction = [
    { id: 1, wearlayerthickness: "5/16", display: "5/16" },
    { id: 2, wearlayerthickness: "1/8", display: "1/8" },
    { id: 3, wearlayerthickness: "15/64", display: "15/64" },
    { id: 4, wearlayerthickness: "1/4", display: "1/4" },
    { id: 5, wearlayerthickness: "3/16", display: "3/16" },
    { id: 6, wearlayerthickness: "7/32", display: "7/32" }
];

export const menuItemsMembranesWaterproofVolume = [
    { id: 1, volume: "13.3", display: "13.3" },
    { id: 2, volume: "18.9", display: "18.9" },
    { id: 3, volume: "19", display: "19" },
    { id: 4, volume: "22.7", display: "22.7" },
    { id: 5, volume: "3.8", display: "3.8" },
    { id: 6, volume: "7.6", display: "7.6" },
    { id: 7, volume: "9.5", display: "9.5" }
    // Additional entries can be added as needed
];

export const menuItemsMembranesPlywoodFormat = [
    { id: 1, format: "4x8", display: "4' x 8'" }
    // Additional entries can be added as needed
];

export const menuItemsMembranesPlywoodThickness = [
    { id: 1, thickness: "1/2", display: "1/2" },
    { id: 2, thickness: "1/4", display: "1/4" },
    { id: 3, thickness: "7/16", display: "7/16" },
  ];



  /* ************************ SidebarFiltering Data UnderLay START ******************** */

  export const menuItemsUnderLayCoverage = [
    { id: 1, covrage: "100", display: "100 sq.ft" },
    { id: 2, covrage: "150", display: "150 sq.ft" },
    { id: 3, covrage: "1500", display: "1500 sq.ft" },
    { id: 4, covrage: "200", display: "200 sq.ft" },
    { id: 5, covrage: "250", display: "250 sq.ft" },
    { id: 6, covrage: "300", display: "300 sq.ft" },
    { id: 7, covrage: "3000", display: "3000 sq.ft" },
    { id: 8, covrage: "400", display: "400 sq.ft" },
    { id: 9, covrage: "4500", display: "4500 sq.ft" },
    { id: 10, covrage: "600", display: "600 sq.ft" },
    { id: 11, covrage: "650", display: "650 sq.ft" },
    { id: 12, covrage: "750", display: "750 sq.ft" },
    { id: 13, covrage: "50", display: "50 sq.ft" },
];

export const menuItemsUnderLayThicknessMM = [
    { id: 1, thickness: "0.5", display: "0.5 mm" },
    { id: 2, thickness: "1", display: "1 mm" },
    { id: 3, thickness: "1.5", display: "1.5 mm" },
    { id: 4, thickness: "2", display: "2 mm" },
    { id: 5, thickness: "2.5", display: "2.5 mm" },
    { id: 6, thickness: "3", display: "3 mm" },
    { id: 7, thickness: "3.5", display: "3.5 mm" },
    { id: 8, thickness: "4.5", display: "4.5 mm" },
    { id: 9, thickness: "5", display: "5 mm" },
    { id: 10, thickness: "6", display: "6 mm" },
    { id: 11, thickness: "7", display: "7 mm" },
    { id: 12, thickness: "8", display: "8 mm" },
    { id: 13, thickness: "6.5", display: "6.5 mm" },
    { id: 14, thickness: "9.0", display: "9.0 mm" },
    { id: 15, thickness: "9.5", display: "9.5 mm" },
    { id: 16, thickness: "10.0", display: "10.0 mm" },
    { id: 17, thickness: "10.5", display: "10.5 mm" },
    { id: 18, thickness: "11.0", display: "11.0 mm" },
    { id: 19, thickness: "12.0", display: "12.0 mm" },
    { id: 20, thickness: "13.0", display: "13.0 mm" }
];





export const menuItemsUnderLayVaporBarrier = [
    { id: 1, edgeType: "no", display: "No" },
    { id: 2, edgeType: "yes", display: "Yes" }
    // Additional entries can be added as needed
];

export const menuItemsUnderLayCarpetCoverageYard = [
    { id: 1, format: "50", display: "50 yards" },
    { id: 2, format: "100", display: "100 yards" },
    { id: 3, format: "150", display: "150 yards" },
    { id: 4, format: "200", display: "200 yards" },
    { id: 5, format: "250", display: "250 yards" },
    { id: 6, format: "350", display: "350 yards" }
    // Additional entries can be added as needed
];


  /* ************************ SidebarFiltering Data Tile Setting Supply START ******************** */

  export const menuItemsTileSettingMaterialColor = [
    { id: 1, color_name: "Gray", display: "Gray" },
    { id: 2, color_name: "Beige", display: "Beige" },
    { id: 3, color_name: "Brown", display: "Brown" },
    { id: 4, color_name: "White", display: "White" },
    { id: 5, color_name: "Black", display: "Black" },
    { id: 6, color_name: "Red", display: "Red" },
    { id: 7, color_name: "Blue", display: "Blue" },
    { id: 8, color_name: "Green", display: "Green" },
    { id: 9, color_name: "Orange", display: "Orange" },
    { id: 10, color_name: "Yellow", display: "Yellow" },
    { id: 11, color_name: "Pink", display: "Pink" },
    { id: 12, color_name: "Purple", display: "Purple" },
    { id: 13, color_name: "Translucent", display: "Translucent" },
    { id: 14, color_name: "Transparent", display: "Transparent" }
    // Additional entries can be added as needed
];

export const menuItemsTileSettingMaterialWeight = [
    { id: 1, Species: "0.5", display: "0.5 lbs" },
    { id: 2, Species: "1", display: "1 lb" },
    { id: 3, Species: "1.5", display: "1.5 lbs" },
    { id: 4, Species: "2", display: "2 lbs" },
    { id: 5, Species: "2.5", display: "2.5 lbs" },
    { id: 6, Species: "3", display: "3 lbs" },
    { id: 7, Species: "4", display: "4 lbs" },
    { id: 8, Species: "5", display: "5 lbs" },
    { id: 9, Species: "6", display: "6 lbs" },
    { id: 10, Species: "7", display: "7 lbs" },
    { id: 11, Species: "8", display: "8 lbs" },
    { id: 12, Species: "8.5", display: "8.5 lbs" },
    { id: 13, Species: "9", display: "9 lbs" },
    { id: 14, Species: "9.5", display: "9.5 lbs" },
    { id: 15, Species: "10", display: "10 lbs" },
    { id: 16, Species: "11", display: "11 lbs" },
    { id: 17, Species: "12", display: "12 lbs" },
    { id: 18, Species: "12.5", display: "12.5 lbs" },
    { id: 19, Species: "14", display: "14 lbs" },
    { id: 20, Species: "14.5", display: "14.5 lbs" },
    { id: 21, Species: "15", display: "15 lbs" },
    { id: 22, Species: "15.5", display: "15.5 lbs" },
    { id: 23, Species: "16", display: "16 lbs" },
    { id: 24, Species: "17", display: "17 lbs" },
    { id: 25, Species: "18", display: "18 lbs" },
    { id: 26, Species: "20", display: "20 lbs" },
    { id: 27, Species: "23", display: "23 lbs" },
    { id: 28, Species: "25", display: "25 lbs" },
    { id: 29, Species: "26", display: "26 lbs" },
    { id: 30, Species: "27", display: "27 lbs" },
    { id: 31, Species: "27.5", display: "27.5 lbs" },
    { id: 32, Species: "28", display: "28 lbs" },
    { id: 33, Species: "30", display: "30 lbs" },
    { id: 34, Species: "31", display: "31 lbs" },
    { id: 35, Species: "35", display: "35 lbs" },
    { id: 36, Species: "36", display: "36 lbs" },
    { id: 37, Species: "36.5", display: "36.5 lbs" },
    { id: 38, Species: "39", display: "39 lbs" },
    { id: 39, Species: "39.5", display: "39.5 lbs" },
    { id: 40, Species: "40", display: "40 lbs" },
    { id: 41, Species: "43", display: "43 lbs" },
    { id: 42, Species: "44", display: "44 lbs" },
    { id: 43, Species: "45", display: "45 lbs" },
    { id: 44, Species: "46", display: "46 lbs" },
    { id: 45, Species: "47", display: "47 lbs" },
    { id: 46, Species: "48", display: "48 lbs" },
    { id: 47, Species: "50", display: "50 lbs" },
    { id: 48, Species: "51", display: "51 lbs" },
    { id: 49, Species: "52", display: "52 lbs" },
    { id: 50, Species: "55", display: "55 lbs" },
    { id: 51, Species: "60", display: "60 lbs" },
    { id: 52, Species: "66", display: "66 lbs" },
    { id: 53, Species: "80", display: "80 lbs" },
    { id: 54, Species: "468", display: "468 lbs" },
    { id: 55, Species: "470", display: "470 lbs" },
    { id: 56, Species: "495", display: "495 lbs" }
    // Additional entries can be added as needed
];


export const menuItemsTileSettingMaterialVolume = [
    { id: 1, volume: "100", display: "100 ml" },
    { id: 2, volume: "162.5", display: "162.5 ml" },
    { id: 3, volume: "290", display: "290 ml" },
    { id: 4, volume: "298.5", display: "298.5 ml" },
    { id: 5, volume: "299", display: "299 ml" },
    { id: 6, volume: "300", display: "300 ml" },
    { id: 7, volume: "304.5", display: "304.5 ml" },
    { id: 8, volume: "305", display: "305 ml" },
    { id: 9, volume: "310", display: "310 ml" },
    { id: 10, volume: "590", display: "590 ml" },
    { id: 11, volume: "592", display: "592 ml" }
    // Additional entries can be added as needed
];

  /* ************************ SidebarFiltering Data Floor Patch START ******************** */

export const menuItemsFloorPatchWeight = [
    { id: 1, Species: "10", display: "10 lb" },
    { id: 2, Species: "104", display: "104 lb" },
    { id: 3, Species: "11", display: "11 lb" },
    { id: 4, Species: "20", display: "20 lb" },
    { id: 5, Species: "22", display: "22 lb" },
    { id: 6, Species: "22.5", display: "22.5 lb" },
    { id: 7, Species: "24", display: "24 lb" },
    { id: 8, Species: "25", display: "25 lb" },
    { id: 9, Species: "3", display: "3 lb" },
    { id: 10, Species: "30", display: "30 lb" },
    { id: 11, Species: "33", display: "33 lb" },
    { id: 12, Species: "35", display: "35 lb" },
    { id: 13, Species: "38", display: "38 lb" },
    { id: 14, Species: "4.5", display: "4.5 lb" },
    { id: 15, Species: "40", display: "40 lb" },
    { id: 16, Species: "41", display: "41 lb" },
    { id: 17, Species: "41.5", display: "41.5 lb" },
    { id: 18, Species: "42", display: "42 lb" },
    { id: 19, Species: "42.5", display: "42.5 lb" },
    { id: 20, Species: "45", display: "45 lb" },
    { id: 21, Species: "48", display: "48 lb" },
    { id: 22, Species: "50", display: "50 lb" },
    { id: 23, Species: "55", display: "55 lb" },
    { id: 24, Species: "7", display: "7 lb" },
    { id: 25, Species: "8", display: "8 lb" }
    // Additional entries can be added as needed
];

export const menuItemsFloorPatchVolume = [
    { id: 1, volume: "10.2", display: "10.2 L" },
    { id: 2, volume: "13.2", display: "13.2 L" },
    { id: 3, volume: "15.1", display: "15.1 L" },
    { id: 4, volume: "18.9", display: "18.9 L" },
    { id: 5, volume: "19", display: "19 L" },
    { id: 6, volume: "3", display: "3 L" },
    { id: 7, volume: "3.6", display: "3.6 L" },
    { id: 8, volume: "3.8", display: "3.8 L" },
    { id: 9, volume: "38", display: "38 L" },
    { id: 10, volume: "7.6", display: "7.6 L" },
    { id: 11, volume: "7.8", display: "7.8 L" },
    { id: 12, volume: "8.3", display: "8.3 L" },
    { id: 13, volume: "9.8", display: "9.8 L" },
    { id: 14, volume: "9.9", display: "9.9 L" }
    // Additional entries can be added as needed
];

  /* ************************ SidebarFiltering Data Adhesive START ******************** */

export const menuItemsAdhesivesvolume = [
    { id: 1, Species: "1", display: "1 L" },
    { id: 2, Species: "1.9", display: "1.9 L" },
    { id: 3, Species: "11.4", display: "11.4 L" },
    { id: 4, Species: "11.6", display: "11.6 L" },
    { id: 5, Species: "13.2", display: "13.2 L" },
    { id: 6, Species: "13.3", display: "13.3 L" },
    { id: 7, Species: "15", display: "15 L" },
    { id: 8, Species: "15.1", display: "15.1 L" },
    { id: 9, Species: "18.9", display: "18.9 L" },
    { id: 10, Species: "3", display: "3 L" },
    { id: 11, Species: "3.8", display: "3.8 L" },
    { id: 12, Species: "3.9", display: "3.9 L" },
    { id: 13, Species: "6.8", display: "6.8 L" },
    { id: 14, Species: "7.6", display: "7.6 L" },
    { id: 15, Species: "9.5", display: "9.5 L" }
    // Additional entries can be added as needed
];

export const menuItemsAdhesivesvolumeML = [
    { id: 1, volume: "225", display: "225 mL" },
    { id: 2, volume: "236", display: "236 mL" },
    { id: 3, volume: "296", display: "296 mL" },
    { id: 4, volume: "40", display: "40 mL" },
    { id: 5, volume: "473", display: "473 mL" },
    { id: 6, volume: "500", display: "500 mL" },
    { id: 7, volume: "6", display: "6 mL" },
    { id: 8, volume: "650", display: "650 mL" },
    { id: 9, volume: "660", display: "660 mL" },
    { id: 10, volume: "828", display: "828 mL" },
    { id: 11, volume: "849", display: "849 mL" },
    { id: 12, volume: "850", display: "850 mL" },
    { id: 13, volume: "858", display: "858 mL" },
    { id: 14, volume: "946", display: "946 mL" },
    { id: 15, volume: "950", display: "950 mL" }
    // Additional entries can be added as needed
];


  /* ************************ SidebarFiltering Data Accessories START ******************** */

  export const menuItemsAccessoriesColor = [
    { id: 1, color_name: "Gray", display: "Gray" },
    { id: 2, color_name: "Beige", display: "Beige" },
    { id: 3, color_name: "Brown", display: "Brown" },
    { id: 4, color_name: "Black", display: "Black" },
    { id: 5, color_name: "White", display: "White" },
    { id: 6, color_name: "Yellow", display: "Yellow" },
    { id: 7, color_name: "Blue", display: "Blue" },
    { id: 8, color_name: "Pink", display: "Pink" },
    { id: 9, color_name: "Green", display: "Green" },
    { id: 10, color_name: "Red", display: "Red" },
    { id: 11, color_name: "Purple", display: "Purple" },
    { id: 12, color_name: "Transparent", display: "Transparent" },
    { id: 13, color_name: "Orange", display: "Orange" }
    // Additional entries can be added as needed
];

export const AccessoriesWallBaseMaterial = [
    { id: 1, Material: "Thermoplastic Rubber", display: "Thermoplastic Rubber" },
    { id: 2, Material: "Thermoplastic Vinyl", display: "Thermoplastic Vinyl" },
    { id: 3, Material: "Rubber", display: "Rubber" },
    { id: 4, Material: "Vinyl", display: "Vinyl" },
    { id: 5, Material: "Thermoset Rubber", display: "Thermoset Rubber" },
    { id: 6, Material: "MDF", display: "MDF" },
    { id: 7, Material: "Virgin PVC", display: "Virgin PVC" },
    { id: 8, Material: "Wood", display: "Wood" }
    // Additional entries can be added as needed
];

export const AccessoriesWallBaseHeight = [
    { id: 1, thickness: "4", quantity: 635 },
    { id: 2, thickness: "6", quantity: 471 },
    { id: 3, thickness: "4.25", quantity: 241 },
    { id: 4, thickness: "4.5", quantity: 224 },
    { id: 5, thickness: "2.5", quantity: 209 },
    { id: 6, thickness: "0.125", quantity: 127 },
    { id: 7, thickness: "0.5", quantity: 90 },
    { id: 8, thickness: "3", quantity: 90 },
    { id: 9, thickness: "5.25", quantity: 76 },
    { id: 10, thickness: "0.75", quantity: 63 },
    { id: 11, thickness: "2.625", quantity: 52 },
    { id: 12, thickness: "3.5", quantity: 44 },
    { id: 13, thickness: "1.5", quantity: 39 },
    { id: 14, thickness: "0.375", quantity: 36 },
    { id: 15, thickness: "8", quantity: 28 },
    { id: 16, thickness: "0.25", quantity: 26 },
    { id: 17, thickness: "0.63", quantity: 13 },
    { id: 18, thickness: "6.5", quantity: 12 },
    { id: 19, thickness: "0.08", quantity: 11 },
    { id: 20, thickness: "0.38", quantity: 6 },
    { id: 21, thickness: "1", quantity: 3 },
    { id: 22, thickness: "1.13", quantity: 3 },
    { id: 23, thickness: "0.13", quantity: 2 },
    { id: 24, thickness: "0.3125", quantity: 2 },
    { id: 25, thickness: "0.1875", quantity: 1 }
];

export const AccessoriesWallBaseLength = [
    { id: 1, length: "96" },
    { id: 2, length: "1440" },
    { id: 3, length: "1200" },
    { id: 4, length: "48" },
    { id: 5, length: "3" },
    { id: 6, length: "144" },
    { id: 7, length: "120" },
    { id: 8, length: "243.84" },
    { id: 9, length: "900" },
    { id: 10, length: "19" },
    { id: 11, length: "84" },
    { id: 12, length: "0.33" },
    { id: 13, length: "121.92" },
    { id: 14, length: "300" }
];

export const AccessoriesMoldingLength = [
    { id: 1, length: "0.25" },
    { id: 2, length: "1" },
    { id: 3, length: "10" },
    { id: 4, length: "106.297" },
    { id: 5, length: "106.299" },
    { id: 6, length: "106.3" },
    { id: 7, length: "108" },
    { id: 8, length: "118" },
    { id: 9, length: "118.11" },
    { id: 10, length: "12" },
    { id: 11, length: "120" },
    { id: 12, length: "1200" },
    { id: 13, length: "121.92" },
    { id: 14, length: "13" },
    { id: 15, length: "14.5" },
    { id: 16, length: "144" },
    { id: 17, length: "15" },
    { id: 18, length: "2" },
    { id: 19, length: "216" },
    { id: 20, length: "24" },
    { id: 21, length: "240" },
    { id: 22, length: "243.84" },
    { id: 23, length: "300" },
    { id: 24, length: "32" },
    { id: 25, length: "36" },
    { id: 26, length: "39" },
    { id: 27, length: "420" },
    { id: 28, length: "432" },
    { id: 29, length: "47.125" },
    { id: 30, length: "48" },
    { id: 31, length: "59" },
    { id: 32, length: "590.55" },
    { id: 33, length: "6" },
    { id: 34, length: "60" },
    { id: 35, length: "600" },
    { id: 36, length: "65.35" },
    { id: 37, length: "7" },
    { id: 38, length: "72" },
    { id: 39, length: "720.03" },
    { id: 40, length: "78.734" },
    { id: 41, length: "78.74" },
    { id: 42, length: "78.75" },
    { id: 43, length: "79" },
    { id: 44, length: "8" },
    { id: 45, length: "84" }
];

export const AccessoriesMoldingHeightMM = [
    { id: 1, length: "0.25" },
    { id: 2, HeightMM: "0.3" },
    { id: 3, HeightMM: "1" },
    { id: 4, HeightMM: "1.5" },
    { id: 5, HeightMM: "1.59" },
    { id: 6, HeightMM: "1.6" },
    { id: 7, HeightMM: "1.8" },
    { id: 8, HeightMM: "10" },
    { id: 9, HeightMM: "10.2" },
    { id: 10, HeightMM: "10.92" },
    { id: 11, HeightMM: "100" },
    { id: 12, HeightMM: "102" },
    { id: 13, HeightMM: "11" },
    { id: 14, HeightMM: "11.1" },
    { id: 15, HeightMM: "11.11" },
    { id: 16, HeightMM: "11.5" },
    { id: 17, HeightMM: "110" },
    { id: 18, HeightMM: "111.125" },
    { id: 19, HeightMM: "114.3" },
    { id: 20, HeightMM: "12" },
    { id: 21, HeightMM: "12..5" },
    { id: 22, HeightMM: "12.5" },
    { id: 23, HeightMM: "12.7" },
    { id: 24, HeightMM: "120" },
    { id: 25, HeightMM: "13" },
    { id: 26, HeightMM: "14" },
    { id: 27, HeightMM: "14.5" },
    { id: 28, HeightMM: "15" },
    { id: 29, HeightMM: "15.9" },
    { id: 30, HeightMM: "150" },
    { id: 31, HeightMM: "16" },
    { id: 32, HeightMM: "160" },
    { id: 33, HeightMM: "161.92499999999998" },
    { id: 34, HeightMM: "165.10000000000002" },
    { id: 35, HeightMM: "17" },
    { id: 36, HeightMM: "17.5" },
    { id: 37, HeightMM: "18" },
    { id: 38, HeightMM: "18.5" },
    { id: 39, HeightMM: "19" },
    { id: 40, HeightMM: "19.1" },
    { id: 41, HeightMM: "19.5" },
    { id: 42, HeightMM: "19.7" },
    { id: 43, HeightMM: "2" },
    { id: 44, HeightMM: "2.3" },
    { id: 45, HeightMM: "2.5" },
    { id: 46, HeightMM: "20" },
    { id: 47, HeightMM: "20.6" },
    { id: 48, HeightMM: "21" },
    { id: 49, HeightMM: "22" },
    { id: 50, HeightMM: "22.5" },
    { id: 51, HeightMM: "22.8" },
    { id: 52, HeightMM: "23" },
    { id: 53, HeightMM: "25" },
    { id: 54, HeightMM: "25.4" },
    { id: 55, HeightMM: "27.5" },
    { id: 56, HeightMM: "28" },
    { id: 57, HeightMM: "28.5" },
    { id: 58, HeightMM: "29" },
    { id: 59, HeightMM: "29.4" },
    { id: 60, HeightMM: "3" },
    { id: 61, HeightMM: "3.18" },
    { id: 62, HeightMM: "3.2" },
    { id: 63, HeightMM: "3.5" },
    { id: 64, HeightMM: "3.7" },
    { id: 65, HeightMM: "30" },
    { id: 66, HeightMM: "31" },
    { id: 67, HeightMM: "31.8" },
    { id: 68, HeightMM: "33.2" },
    { id: 69, HeightMM: "33.3" },
    { id: 70, HeightMM: "34.9" },
    { id: 71, HeightMM: "35" },
    { id: 72, HeightMM: "36" },
    { id: 73, HeightMM: "37" },
    { id: 74, HeightMM: "38" },
    { id: 75, HeightMM: "38..1" },
    { id: 76, HeightMM: "38.1" },
    { id: 77, HeightMM: "39.7" },
    { id: 78, HeightMM: "4" },
    { id: 79, HeightMM: "4.5" },
    { id: 80, HeightMM: "4.6" },
    { id: 81, HeightMM: "4.8" },
    { id: 82, HeightMM: "40" },
    { id: 83, HeightMM: "41" },
    { id: 84, HeightMM: "41.3" },
    { id: 85, HeightMM: "44.4" },
    { id: 86, HeightMM: "44.45" },
    { id: 87, HeightMM: "45" },
    { id: 88, HeightMM: "46" },
    { id: 89, HeightMM: "47.5" },
    { id: 90, HeightMM: "47.6" },
    { id: 91, HeightMM: "48" },
    { id: 92, HeightMM: "5" },
    { id: 93, HeightMM: "5.1" },
    { id: 94, HeightMM: "5.5" },
    { id: 95, HeightMM: "50" },
    { id: 96, HeightMM: "50.8" },
    { id: 97, HeightMM: "51" },
    { id: 98, HeightMM: "54" },
    { id: 99, HeightMM: "54.1" },
    { id: 100, HeightMM: "55" },
    { id: 101, HeightMM: "55.56" }
];

export const AccessoriesMoldingHeightInch = [
    { id: 1, thickness: "0.01" },
    { id: 2, thickness: "0.01" },
    { id: 3, thickness: "0.06" },
    { id: 4, thickness: "0.06" },
    { id: 5, thickness: "0.06" },
    { id: 6, thickness: "0.06" },
    { id: 7, thickness: "0.07" },
    { id: 8, thickness: "0.39" },
    { id: 9, thickness: "0.40" },
    { id: 10, thickness: "0.43" },
    { id: 11, thickness: "3.94" },
    { id: 12, thickness: "4.02" },
    { id: 13, thickness: "0.43" },
    { id: 14, thickness: "0.44" },
    { id: 15, thickness: "0.44" },
    { id: 16, thickness: "0.45" },
    { id: 17, thickness: "4.33" },
    { id: 18, thickness: "4.38" },
    { id: 19, thickness: "4.50" },
    { id: 20, thickness: "0.47" },
    { id: 21, thickness: "0.47" },
    { id: 22, thickness: "0.49" },
    { id: 23, thickness: "0.50" },
    { id: 24, thickness: "4.72" },
    { id: 25, thickness: "0.51" },
    { id: 26, thickness: "0.55" },
    { id: 27, thickness: "0.57" },
    { id: 28, thickness: "0.59" },
    { id: 29, thickness: "0.63" },
    { id: 30, thickness: "5.91" },
    { id: 31, thickness: "0.63" },
    { id: 32, thickness: "6.30" },
    { id: 33, thickness: "6.38" },
    { id: 34, thickness: "6.50" },
    { id: 35, thickness: "0.67" },
    { id: 36, thickness: "0.69" },
    { id: 37, thickness: "0.71" },
    { id: 38, thickness: "0.73" },
    { id: 39, thickness: "0.75" },
    { id: 40, thickness: "0.75" },
    { id: 41, thickness: "0.77" },
    { id: 42, thickness: "0.78" },
    { id: 43, thickness: "0.08" },
    { id: 44, thickness: "0.09" },
    { id: 45, thickness: "0.10" },
    { id: 46, thickness: "0.79" },
    { id: 47, thickness: "0.81" },
    { id: 48, thickness: "0.83" },
    { id: 49, thickness: "0.87" },
    { id: 50, thickness: "0.89" },
    { id: 51, thickness: "0.90" },
    { id: 52, thickness: "0.91" },
    { id: 53, thickness: "0.98" },
    { id: 54, thickness: "1.00" },
    { id: 55, thickness: "1.08" },
    { id: 56, thickness: "1.10" },
    { id: 57, thickness: "1.12" },
    { id: 58, thickness: "1.14" },
    { id: 59, thickness: "1.16" },
    { id: 60, thickness: "0.12" },
    { id: 61, thickness: "0.13" },
    { id: 62, thickness: "0.13" },
    { id: 63, thickness: "0.14" },
    { id: 64, thickness: "0.15" },
    { id: 65, thickness: "1.18" },
    { id: 66, thickness: "1.22" },
    { id: 67, thickness: "1.25" },
    { id: 68, thickness: "1.31" },
    { id: 69, thickness: "1.31" },
    { id: 70, thickness: "1.37" },
    { id: 71, thickness: "1.38" },
    { id: 72, thickness: "1.42" },
    { id: 73, thickness: "1.46" },
    { id: 74, thickness: "1.50" },
    { id: 75, thickness: "1.50" },
    { id: 76, thickness: "1.50" },
    { id: 77, thickness: "1.56" },
    { id: 78, thickness: "0.16" },
    { id: 79, thickness: "0.18" },
    { id: 80, thickness: "0.18" },
    { id: 81, thickness: "0.19" },
    { id: 82, thickness: "1.57" },
    { id: 83, thickness: "1.61" },
    { id: 84, thickness: "1.63" },
    { id: 85, thickness: "1.75" },
    { id: 86, thickness: "1.75" },
    { id: 87, thickness: "1.77" },
    { id: 88, thickness: "1.81" },
    { id: 89, thickness: "1.87" },
    { id: 90, thickness: "1.87" },
    { id: 91, thickness: "1.89" },
    { id: 92, thickness: "0.20" },
    { id: 93, thickness: "0.20" },
    { id: 94, thickness: "0.22" },
    { id: 95, thickness: "1.97" },
    { id: 96, thickness: "2.00" },
    { id: 97, thickness: "2.01" },
    { id: 98, thickness: "2.13" },
    { id: 99, thickness: "2.13" },
    { id: 100, thickness: "2.17" },
    { id: 101, thickness: "2.19" },
];



export const menuItemsMoldingMaterial = [
    { id: 1, Material: "Aluminum" },
    { id: 2, Material: "PVC" },
    { id: 3, Material: "Anodized Aluminum" },
    { id: 4, Material: "Wood" },
    { id: 5, Material: "Steel" },
    { id: 6, Material: "Brass" },
    { id: 7, Material: "Copper" },
    { id: 8, Material: "Bronze" },
    { id: 9, Material: "Plastic" },
    { id: 10, Material: "Fiberglass" },
    { id: 11, Material: "Carbon Fiber" },
    { id: 12, Material: "Ceramic" },
    { id: 13, Material: "Glass" },
    { id: 14, Material: "Rubber" },
    { id: 15, Material: "Silicone" },
    { id: 16, Material: "Polyethylene" },
    { id: 17, Material: "Polypropylene" },
    { id: 18, Material: "Polyurethane" },
    { id: 19, Material: "Nylon" },
    { id: 20, Material: "Acrylic" },
    { id: 21, Material: "Vinyl" },
    { id: 22, Material: "Stainless Steel" },
    { id: 23, Material: "Titanium" },
    { id: 24, Material: "Zinc" },
    { id: 25, Material: "Magnesium" },
    { id: 26, Material: "Tungsten" },
    { id: 27, Material: "Nickel" },
    { id: 28, Material: "Lead" },
    { id: 29, Material: "Teflon" },
    { id: 30, Material: "Rubber" },
    { id: 31, Material: "Gold" },
    { id: 32, Material: "Silver" },
    { id: 33, Material: "Platinum" },
    { id: 34, Material: "Graphite" },
    { id: 35, Material: "Alloy" },
    { id: 36, Material: "Composite" },
    { id: 37, Material: "Concrete" },
    { id: 38, Material: "Marble" },
    { id: 39, Material: "Granite" },
    { id: 40, Material: "Sandstone" },
    { id: 41, Material: "Limestone" },
    { id: 42, Material: "Slate" },
    { id: 43, Material: "Terrazzo" },
    { id: 44, Material: "Porcelain" },
    { id: 45, Material: "Varnished Steel" },
    { id: 46, Material: "Varnished Aluminum" },
    { id: 47, Material: "Varnished Brass" },
    { id: 48, Material: "Varnished Copper" },
    { id: 49, Material: "Varnished Bronze" },
    { id: 50, Material: "Varnished Stainless Steel" }
];

  
export const menuItemsMoldingFinish = [
    { id: 1, Finish: "Brushed", display: "Brushed" },
    { id: 2, Finish: "Glossy", display: "Glossy" },
    { id: 3, Finish: "Honed", display: "Honed" },
    { id: 4, Finish: "Matte", display: "Matte" },
    { id: 5, Finish: "Polished", display: "Polished" },
    { id: 6, Finish: "Satin", display: "Satin" },
    { id: 7, Finish: "Textured", display: "Textured" },
    { id: 8, Finish: "Tumbled", display: "Tumbled" },
    { id: 9, Finish: "Twin Polished", display: "Twin Polished" },
    { id: 10, Finish: "Unfinished", display: "Unfinished" },
    { id: 11, Finish: "Wire Brushed", display: "Wire Brushed" },
    { id: 12, Finish: "Wood Grain", display: "Wood Grain" },
    { id: 13, Finish: "3D", display: "3D" },
    { id: 14, Finish: "Antiqued", display: "Antiqued" },
    { id: 15, Finish: "Apian", display: "Apian" },
    { id: 16, Finish: "Arched Herringbone", display: "Arched Herringbone" },
    { id: 17, Finish: "Arched", display: "Arched" },
    { id: 18, Finish: "Armadillo", display: "Armadillo" },
    { id: 19, Finish: "Barrel", display: "Barrel" },
    { id: 20, Finish: "Beveled", display: "Beveled" },
    { id: 21, Finish: "Birdcage", display: "Birdcage" },
    { id: 22, Finish: "Brazed", display: "Brazed" },
    { id: 23, Finish: "Bullnose", display: "Bullnose" },
    { id: 24, Finish: "Bush-Hammered", display: "Bush-Hammered" },
    { id: 25, Finish: "Candle", display: "Candle" },
    { id: 26, Finish: "Carved", display: "Carved" },
    { id: 27, Finish: "Chateau", display: "Chateau" },
    { id: 28, Finish: "Chiseled", display: "Chiseled" },
    { id: 29, Finish: "Cladded", display: "Cladded" },
    { id: 30, Finish: "Crosscut", display: "Crosscut" },
    { id: 31, Finish: "Distressed", display: "Distressed" },
    { id: 32, Finish: "Domed", display: "Domed" },
    { id: 33, Finish: "Driftwood", display: "Driftwood" },
    { id: 34, Finish: "Dry Stack", display: "Dry Stack" },
    { id: 35, Finish: "Eased Edge", display: "Eased Edge" },
    { id: 36, Finish: "Eased", display: "Eased" },
    { id: 37, Finish: "Enameled", display: "Enameled" },
    { id: 38, Finish: "Etched", display: "Etched" },
    { id: 39, Finish: "Faux Brick", display: "Faux Brick" },
    { id: 40, Finish: "Faux Stacked Stone", display: "Faux Stacked Stone" },
    { id: 41, Finish: "Filled", display: "Filled" },
    { id: 42, Finish: "Flat", display: "Flat" },
    { id: 43, Finish: "Flicker", display: "Flicker" },
    { id: 44, Finish: "Fluted", display: "Fluted" },
    { id: 45, Finish: "Gilded", display: "Gilded" },
    { id: 46, Finish: "Granulated", display: "Granulated" },
    { id: 47, Finish: "Ground", display: "Ground" },
    { id: 48, Finish: "Hand Carved", display: "Hand Carved" },
    { id: 49, Finish: "Hand Chiseled", display: "Hand Chiseled" },
    { id: 50, Finish: "Hand Distressed", display: "Hand Distressed" },
    { id: 51, Finish: "Hand Hammered", display: "Hand Hammered" },
    { id: 52, Finish: "Hand Painted", display: "Hand Painted" },
    { id: 53, Finish: "Hand Scraped", display: "Hand Scraped" },
    { id: 54, Finish: "Handmade", display: "Handmade" },
    { id: 55, Finish: "Hand-Molded", display: "Hand-Molded" },
    { id: 56, Finish: "Hand-Sculpted", display: "Hand-Sculpted" },
    { id: 57, Finish: "Hand-Textured", display: "Hand-Textured" },
    { id: 58, Finish: "Heavy Distressed", display: "Heavy Distressed" },
    { id: 59, Finish: "Honed & Filled", display: "Honed & Filled" },
    { id: 60, Finish: "Honed & Unfilled", display: "Honed & Unfilled" },
    { id: 61, Finish: "Honed Filled & Chiseled", display: "Honed Filled & Chiseled" },
    { id: 62, Finish: "Honed, Unfilled & Chiseled", display: "Honed, Unfilled & Chiseled" },
    { id: 63, Finish: "Iridescent", display: "Iridescent" },
    { id: 64, Finish: "Lace", display: "Lace" },
    { id: 65, Finish: "Laminated", display: "Laminated" },
    { id: 66, Finish: "Leather", display: "Leather" },
    { id: 67, Finish: "Linen", display: "Linen" },
    { id: 68, Finish: "Living Finish", display: "Living Finish" },
    { id: 69, Finish: "Marbleized", display: "Marbleized" },
    { id: 70, Finish: "Matted", display: "Matted" },
    { id: 71, Finish: "Metallized", display: "Metallized" },
    { id: 72, Finish: "Micro Beveled", display: "Micro Beveled" },
    { id: 73, Finish: "Mirrored", display: "Mirrored" },
    { id: 74, Finish: "Mitered", display: "Mitered" },
    { id: 75, Finish: "Molded", display: "Molded" },
    { id: 76, Finish: "Mottled", display: "Mottled" },
    { id: 77, Finish: "Mounted", display: "Mounted" },
    { id: 78, Finish: "Nail-up", display: "Nail-up" },
    { id: 79, Finish: "Oil Rubbed", display: "Oil Rubbed" },
    { id: 80, Finish: "Oiled", display: "Oiled" },
    { id: 81, Finish: "Oxidized", display: "Oxidized" },
    { id: 82, Finish: "Pencil Polished", display: "Pencil Polished" },
    { id: 83, Finish: "Perforated", display: "Perforated" },
    { id: 84, Finish: "Pillowed", display: "Pillowed" },
    { id: 85, Finish: "Planked", display: "Planked" },
    { id: 86, Finish: "Plastered", display: "Plastered" },
    { id: 87, Finish: "Pointed", display: "Pointed" },
    { id: 88, Finish: "Pressed", display: "Pressed" },
    { id: 89, Finish: "Pulled", display: "Pulled" },
    { id: 90, Finish: "Quarried", display: "Quarried" },
    { id: 91, Finish: "Raked", display: "Raked" },
    { id: 92, Finish: "Reclaimed", display: "Reclaimed" },
    { id: 93, Finish: "Reptile Skin", display: "Reptile Skin" },
    { id: 94, Finish: "Ribbed", display: "Ribbed" },
    { id: 95, Finish: "Rock Face", display: "Rock Face" },
    { id: 96, Finish: "Rock", display: "Rock" },
    { id: 97, Finish: "Rough Cut", display: "Rough Cut" },
    { id: 98, Finish: "Rugged", display: "Rugged" },
    { id: 99, Finish: "Sawn", display: "Sawn" },
    { id: 100, Finish: "Scalloped", display: "Scalloped" },
    { id: 101, Finish: "Scrubbed", display: "Scrubbed" },
    { id: 102, Finish: "Sculpted", display: "Sculpted" },
    { id: 103, Finish: "Sealed", display: "Sealed" },
    { id: 104, Finish: "Shaped", display: "Shaped" },
    { id: 105, Finish: "Shiny", display: "Shiny" },
    { id: 106, Finish: "Slate", display: "Slate" },
    { id: 107, Finish: "Slightly Textured", display: "Slightly Textured" },
    { id: 108, Finish: "Smooth", display: "Smooth" },
    { id: 109, Finish: "Stamped", display: "Stamped" },
    { id: 110, Finish: "Stippled", display: "Stippled" },
    { id: 111, Finish: "Stony", display: "Stony" },
    { id: 112, Finish: "Striated", display: "Striated" },
    { id: 113, Finish: "Subtle", display: "Subtle" },
    { id: 114, Finish: "Swirl", display: "Swirl" },
    { id: 115, Finish: "Tapered", display: "Tapered" },
    { id: 116, Finish: "Textured", display: "Textured" },
    { id: 117, Finish: "Thermal", display: "Thermal" },
    { id: 118, Finish: "Threaded", display: "Threaded" },
    { id: 119, Finish: "Tiltex", display: "Tiltex" }
    // Add more items as needed
];

    /* ************************ SidebarFiltering Data FloorVent START ******************** */

export const menuItemsFloorVentColor = [
    { id: 1, color_name: "Brown", display: "Brown (90)" },
    { id: 2, color_name: "Beige", display: "Beige (29)" },
    { id: 3, color_name: "Gray", display: "Gray (28)" },
    { id: 4, color_name: "Black", display: "Black (16)" },
    { id: 5, color_name: "White", display: "White (7)" },
    { id: 6, color_name: "Red", display: "Red (1)" },
    { id: 7, color_name: "Yellow", display: "Yellow (1)" }
    // Add more items as needed
];

export const menuItemsFloorVentFormat = [
    { id: 1, Format: "10x14", display: "10'x14'" },
    { id: 2, Format: "2x12", display: "2'x12'" }
    // Add more items as needed
];

export const menuItemsFloorVentMaterial = [
    { id: 1, Material: "Plastic", display: "Plastic (15)" },
    { id: 2, Material: "Steel", display: "Steel (6)" },
    { id: 3, Material: "Wood", display: "Wood (107)" }
    // Add more items as needed
];

  
  
  /* ************************ SidebarFiltering Data Tile START ******************** */

  export const menuItemsWallTileProductType = [
    { id: 1, Product_Type: "Interior Wall Tiles" },
    { id: 2, Product_Type: "Ledger Stone Wall Tiles" },
    { id: 3, Product_Type: "Mosaic Tiles" },
    { id: 4, Product_Type: "Ceramic Wall Tiles" },
    { id: 5, Product_Type: "Large Format Tiles" },
    { id: 6, Product_Type: "Wall Siding" },
];

export const menuItemsWallTileSize = [
    { id: 1, Approximate_sizeIN: "0.5x10", display: "0.5\" x 10\"" },
    { id: 2, Approximate_sizeIN: "0.5x6", display: "0.5\" x 6\"" },
    { id: 3, Approximate_sizeIN: "1x1", display: "1\" x 1\"" },
    { id: 4, Approximate_sizeIN: "1x11", display: "1\" x 11\"" },
    { id: 5, Approximate_sizeIN: "1x12", display: "1\" x 12\"" },
    { id: 6, Approximate_sizeIN: "1x4", display: "1\" x 4\"" },
    { id: 7, Approximate_sizeIN: "1x6", display: "1\" x 6\"" },
    { id: 8, Approximate_sizeIN: "1x8", display: "1\" x 8\"" },
    { id: 9, Approximate_sizeIN: "10x10", display: "10\" x 10\"" },
    { id: 10, Approximate_sizeIN: "10x10.5", display: "10\" x 10.5\"" },
    { id: 11, Approximate_sizeIN: "10x11", display: "10\" x 11\"" },
    { id: 12, Approximate_sizeIN: "10x12", display: "10\" x 12\"" },
    { id: 13, Approximate_sizeIN: "10x13", display: "10\" x 13\"" },
    { id: 14, Approximate_sizeIN: "10x14", display: "10\" x 14\"" },
    { id: 15, Approximate_sizeIN: "10x16", display: "10\" x 16\"" },
    { id: 16, Approximate_sizeIN: "10x18", display: "10\" x 18\"" },
    { id: 17, Approximate_sizeIN: "10x20", display: "10\" x 20\"" },
    { id: 18, Approximate_sizeIN: "10x22", display: "10\" x 22\"" },
    { id: 19, Approximate_sizeIN: "10x24", display: "10\" x 24\"" },
    { id: 20, Approximate_sizeIN: "10x30", display: "10\" x 30\"" },
    { id: 21, Approximate_sizeIN: "10.5x12", display: "10.5\" x 12\"" },
    { id: 22, Approximate_sizeIN: "10.5x14", display: "10.5\" x 14\"" },
    { id: 23, Approximate_sizeIN: "11x11", display: "11\" x 11\"" },
    { id: 24, Approximate_sizeIN: "11x11.5", display: "11\" x 11.5\"" },
    { id: 25, Approximate_sizeIN: "11x12", display: "11\" x 12\"" },
    // Additional items can be added as needed
  ];

  export const menuItemsTileShape = [
    { id: 1, shape: "Circle" },
    { id: 2, shape: "Hexagon" },
    { id: 3, shape: "Rectangle"},
    { id: 4, shape: "Square"},
    { id: 5, shape: "Triangle"},
    { id: 6, shape: "Other"},
  ];

  export const menuItemsTileLook = [
    { id: 1, Species: "Brick", display: "Brick" },
    { id: 2, Species: "Concrete", display: "Concrete" },
    { id: 3, Species: "Contemporary", display: "Contemporary" },
    { id: 4, Species: "Fabric", display: "Fabric" },
    { id: 5, Species: "Geometric", display: "Geometric" },
    { id: 6, Species: "Graffiti", display: "Graffiti" },
    { id: 7, Species: "Marble", display: "Marble" },
    { id: 8, Species: "Metal", display: "Metal" },
    { id: 9, Species: "Slate", display: "Slate" },
    { id: 10, Species: "Stone", display: "Stone" },
    { id: 11, Species: "Subway", display: "Subway" },
    { id: 12, Species: "Terrazzo", display: "Terrazzo" },
    { id: 13, Species: "Traditional", display: "Traditional" },
    { id: 14, Species: "Travertine", display: "Travertine" },
    { id: 15, Species: "Uniform Color", display: "Uniform Color" },
    { id: 16, Species: "Wood", display: "Wood" },
    // Add more items as needed
  ];

  export const menuItemsTileFinish = [
    { id: 1, Finish: "Lappato", display: "Lappato" },
    { id: 2, Finish: "Matte", display: "Matte" },
    { id: 3, Finish: "Mixed", display: "Mixed" },
    { id: 4, Finish: "Natural", display: "Natural" },
    { id: 5, Finish: "Polished", display: "Polished" },
    { id: 6, Finish: "Semi-Polished", display: "Semi-Polished" },
    { id: 7, Finish: "Splitface", display: "Splitface" },
    // Add more items as needed
  ];

  export const menuItemsTileMaterial = [
    { id: 1, Material: "Ceramic", display: "Ceramic" },
    { id: 2, Material: "Concrete", display: "Concrete" },
    { id: 3, Material: "Glass", display: "Glass" },
    { id: 4, Material: "Glass/Metal", display: "Glass/Metal" },
    { id: 5, Material: "Glass/Stone", display: "Glass/Stone" },
    { id: 6, Material: "Glass/Stone/Metal", display: "Glass/Stone/Metal" },
    { id: 7, Material: "Limestone", display: "Limestone" },
    { id: 8, Material: "Marble", display: "Marble" },
    { id: 9, Material: "Porcelain", display: "Porcelain" },
    { id: 10, Material: "Quartz", display: "Quartz" },
    { id: 11, Material: "Sandstone", display: "Sandstone" },
    { id: 12, Material: "Slate", display: "Slate" },
    { id: 13, Material: "Stone", display: "Stone" },
    { id: 14, Material: "Stone/Metal", display: "Stone/Metal" },
    { id: 15, Material: "Stone/Porcelain", display: "Stone/Porcelain" },
    { id: 16, Material: "Travertine", display: "Travertine" },
    // Add more items as needed
  ];

  export const menuItemsTilePEIRating = [
    { id: 1, PEI_Rating: "0 - Never use underfoot" },
    { id: 2, PEI_Rating: "1 - Very Light Duty" },
    { id: 3, PEI_Rating: "2 - Light Duty" },
    { id: 4, PEI_Rating: "3 - Medium Duty" },
    { id: 5, PEI_Rating: "4 - Heavy Duty" },
    { id: 6, PEI_Rating: "5 - Extra Heavy Duty" },
    { id: 7, PEI_Rating: "Not rated" },
  ];

  export const menuItemsTileEdgeType = [
    { id: 1, edgeType: "Beveled", display: "Beveled" },
    { id: 2, edgeType: "Pressed", display: "Pressed" },
    { id: 3, edgeType: "Rectified", display: "Rectified" },
    { id: 4, edgeType: "Other", display: "Other" },
  ];
  



  




  
export const menuItemsColor = [
    {
      id: 1,
     color_name: "Beige",
    },
  
    {
        id: 2,
       color_name: "Grey",
    },
    {
        id: 3,
       color_name: "Brown",
    },
    {
        id: 4,
       color_name: "Red",
    },
    {
        id: 5,
       color_name: "Powder",
    },
    {
        id: 6,
       color_name: "Stone",
    },
    {
        id: 8,
       color_name: "Charcoal",
    },
   
    {
        id: 9,
       color_name: "Emerald",
    },
    {
        id: 10,
       color_name: "Ink",
    },
    {
        id: 11,
       color_name: "White",
    },
   
  ];

  export const menuItemsColor2 = [
    {
      id: 1,
     color_name: "Brown",
    },
  
    {
        id: 2,
       color_name: "Grey",
    },
    {
        id: 3,
       color_name: "Stone",
    },
    {
        id: 4,
       color_name: "Beige",
    },
    {
        id: 5,
       color_name: "White",
    },
    {
        id: 6,
       color_name: "Red",
    },
  ];

  export const menuItemsInstallation = [
    {
      id: 1,
      installMethod: "Click Lock",
    },
  
    {
        id: 2,
        installMethod: "Loose Lay",
    },
    {
        id: 3,
        installMethod: "Normal",
    },
    {
        id: 4,
        installMethod: "Glue Down",
    },
 
    {
        id: 5,
        installMethod: "Unilin",
    },
    {
        id: 6,
        installMethod: "I4F locking system",
    },
 
   
  ];



  export const menuItemswidth = [
    {
      id: 1,
      width: "12",
    },
  
    {
        id: 2,
        width: "5",
    },
    {
        id: 3,
        width: "24",
    },
    {
        id: 4,
        width: "3",
    },
 
    {
        id: 5,
        width: "8",
    },
    {
        id: 6,
        width: "9",
    },
    
   
  ];



  export const menuItemslength = [
    {
      id: 1,
      length: "12",
    },
  
    {
        id: 2,
        length: "24",
    },
    {
        id: 3,
        length: "10",
    },
    {
        id: 4,
        length: "60",
    },
    {
        id: 4,
        length: "120",
    },
  ];

  export const menuItemslengthCheck = [
    {
      id: 1,
      length_cm: "137.16",
    },
  
    {
        id: 2,
        length_cm: "24",
    },
    {
        id: 3,
        length_cm: "10",
    },
    {
        id: 4,
        length_cm: "60",
    },
    {
        id: 4,
        length_cm: "120",
    },
  ];

  export const menuItemsThickness = [
    {
      id: 1,
      thickness: "12",
    },
  
    {
        id: 2,
        thickness: "10",
    },
    {
        id: 3,
        thickness: "8",
    },
    {
        id: 4,
        thickness: "5",
    },
 
    {
        id: 5,
        thickness: "4",
    },
    {
        id: 6,
        thickness: "2",
    },
  ];


  export const menuItemsSpecies = [
    {
      id: 1,
      Species: "Acacia",
    },
  
    {
        id: 2,
       Species: "Ash",
    },
    {
        id: 3,
       Species: "Birch",
    },
    {
        id: 4,
       Species: "Cherry Tree",
    },
 
    {
        id: 5,
       Species: "Hickory",
    },
    {
        id: 6,
       Species:"Jatoba" ,
    },
 
    {
        id: 7,
       Species:"Mahogany" ,
    },
    {
        id: 8,
       Species:"Maple" ,
    },
    {
        id: 9,
       Species:"Pine" ,
    },
    {
        id: 10,
       Species:"Red Oak" ,
    },
    {
        id: 11,
       Species:"Sapele" ,
    },
 
   
  ];
  export const menuItemsACD = [
    {
      id: 1,
      AC: "AC3",
    },
  
    {
        id: 2,
       AC: "AC4",
    },
    {
        id: 3,
       AC: "AC5",
    }, 
  ];
  export const menuItemsFinish = [
    {
      id: 1,
      Finish: "Glossy",
    },
  
    {
        id: 2,
       Finish: "Matte",
    },
    {
        id: 3,
       Finish: "Semi-Gloss",
    },
    {
        id: 4,
       Finish: "Semi-Polished",
    },
 
    {
        id: 5,
       Finish: "Surface Texture",
    },
    {
        id: 6,
       Finish:"Handscraped" ,
    },
 
    {
        id: 7,
       Finish:"Mahogany" ,
    },
    {
        id: 8,
       Finish:"Smooth" ,
    },
    {
        id: 9,
       Finish:"Pine" ,
    },
    {
        id: 10,
       Finish:"Wirebrushed" ,
    },
   
   
  ];
//   Handscraped
// 	Smooth
// 	Polished
// 	Wirebrushed
// 	Honed
// 	Matte
// 	Embossed in Register
// 	UV Cured Polyurethane Finish with Aluminum Oxide
// 	Glossy
  
  export const menuItemsApproximateSize = [
    {
      id: 1,
      Size: "10 x 39.5",
    },
  
    {
        id: 2,
       Size: "10 x 40",
    },
    {
        id: 3,
       Size: "13 x 40",
    },
    {
        id: 4,
       Size: "19.5 x 19.5",
    },
 
    {
        id: 5,
       Size: "20 x 20",
    },
    {
        id: 6,
       Size:"20 x 40" ,
    },
 
    {
        id: 7,
       Size:"24 x 24" ,
    },
  ];

  export const menuItemsCarpetMaterial = [
    {id: 1, Material: "Nylon",},
    {id: 2, Material: "Olefin",},
    {id: 3, Material: "Polyester",},
    {id: 4, Material: "Polypropylene",},
    ];

  export const menuItemsMaterial = [
    {
      id: 1,
      Material: "Nylon",
    },
  
    {
        id: 2,
       Material: "Olefin",
    },
    {
        id: 3,
       Material: "Polyester",
    },
    {
        id: 4,
       Material: "Polypropylene",
    },
  ];
  export const menuItemsOnces = [
    {
      id: 1,
      Onces: "17",
    },
  
    {
        id: 2,
        Onces: "18",
    },
    {
        id: 3,
        Onces: "24",
    },
    {
        id: 4,
       Onces: "26",
    },
  ];
  export const menuItemsDensity = [
    {
      id: 1,
      density: "200",
    },
  
    {
        id: 2,
        density: "350",
    },
    {
        id: 3,
        density: "400",
    },
    {
        id: 4,
        density: "600",
    },
  ];
  export const menuItemsShape = [
    {
      id: 1,
      shape: "Hexagon",
    },
  
    {
        id: 2,
        shape: "Rectangle",
    },
    {
        id: 3,
        shape: "Square",
    },
    {
        id: 4,
        shape: "Triangle",
    },
    {
        id: 5,
        shape: "Other",
    },
  ];
  export const menuItemEdgeType = [
    {
      id: 1,
      edgeType: "Beveled",
    },
  
    {
        id: 2,
        edgeType: "Pressed",
    },
    {
        id: 3,
        edgeType: "Rectified",
    },
    {
        id: 4,
        edgeType: "4-sided microbevel",
    },
    {
        id: 5,
        edgeType: "Painted bevel",
    },
    {
        id: 6,
        edgeType: "Self-Adhesive",
    },
    {
        id: 7,
        edgeType: "Other",
    },
  ];
  export const menuItemLook = [
    {
      id: 1,
      Look: "Brick",
    },
  
    {
        id: 2,
        Look: "Contemporary",
    },
    {
        id: 3,
        Look: "Geometric",
    },
    {
        id: 4,
        Look: "Graffiti",
    },
    {
        id: 5,
        Look: "Stone",
    },
    {
        id: 6,
        Look: "Subway",
    },
    {
        id: 7,
        Look: "Traditional",
    },
    {
        id: 8,
        Look: "Wood",
    },
  ];
  export const menuItemPEIRating = [
    {
      id: 1,
      PEIRating: "Never use underfoot",
    },
  
    {
        id: 2,
        PEIRating: "Very Light Duty",
    },
    {
        id: 3,
        PEIRating: " Light Duty",
    },
    {
        id: 4,
        PEIRating: "Medium Duty",
    },
    {
        id: 5,
        PEIRating: "Heavy Duty",
    },
    {
        id: 6,
        PEIRating: "Extra Heavy Duty",
    },
    {
        id: 7,
        PEIRating: "Not rated",
    },
];
  export const menuItemCovrage = [
    {
      id: 1,
      covrage: "50",
    },
  
    {
        id: 2,
        covrage: "70",
    },
    {
        id: 3,
        covrage: "80",
    },
    {
        id: 4,
        covrage: "90",
    },
    {
        id: 5,
        covrage: "95",
    },
    {
        id: 6,
        covrage: "100",
    },
    {
        id: 7,
        covrage: "120",
    },
];
  export const menuItemVolume = [
    {
      id: 1,
      volume: "3.8",
    },
  
    {
        id: 2,
        volume: "10.5",
    },
    {
        id: 3,
        volume: "30.5",
    },
    {
        id: 4,
        volume: "90.5",
    },
    {
        id: 5,
        volume: "95.30",
    },
    {
        id: 6,
        volume: "29.30",
    },
    {
        id: 7,
        volume: "30.25",
    },
];
  export const menuItemFormat = [
    {
      id: 1,
      format: "1x1",
    },
  
    {
        id: 2,
        format: "2x2",
    },
    {
        id: 3,
        format: "3x3",
    },
    {
        id: 4,
        format: "4x4",
    },
    {
        id: 5,
        format: "2x9",
    },
    {
        id: 6,
        format: "3x6",
    },
    {
        id: 7,
        format: "3x9",
    },
    {
        id: 7,
        format: "4x12",
    },
    {
        id: 7,
        format: "3x9",
    },
    {
        id: 7,
        format: "4x12",
    },
    {
        id: 7,
        format: "4x16",
    },
    {
        id: 7,
        format: "24x48",
    },
    {
        id: 7,
        format: "32x64",
    },
    {
        id: 7,
        format: "36x102",
    },
    {
        id: 7,
        format: "48x72",
    },
    {
        id: 7,
        format: "60x120",
    },
];

export const menuItemsBrandTile = [
    // Brands not clearly falling into Tile, Vinyl, Laminate, or Wood
    { id: 1, BrandName: "Anatolia" },
    { id: 2, BrandName: "Ceratec" },
    { id: 3, BrandName: "Ciot" },
    { id: 4, BrandName: "Daltile" },
    { id: 5, BrandName: "MSI Stone" },
    { id: 6, BrandName: "Sarana Tile" },
    { id: 7, BrandName: "Olympia Tile" },
    { id: 10, BrandName: "Biyork" },
    // Continue for all brands that didn't fit neatly into the first four categories
];

export const menuItemsBrandWoodFloor = [
    { id: 1, BrandName: "1867" },
    { id: 2, BrandName: "Grandeur" },
    { id: 3, BrandName: "Canadian Imported Flooring" },
    { id: 4, BrandName: "Wickham Hardwood" },
    { id: 5, BrandName: "Boen Oak" },
    { id: 6, BrandName: "Biyork" },
    { id: 7, BrandName: "Cashmere Woods" },
    { id: 8, BrandName: "Anderson Tuftex" },
];


export const menuItemsBrandLaminate = [
    { id: 1, BrandName: "1867" },
    { id: 2, BrandName: "Fuzion Flooring" },
    { id: 3, BrandName: "Kronoswiss" },
    { id: 4, BrandName: "Pravada" },
    { id: 5, BrandName: "Boen Oak" },
    { id: 6, BrandName: "Biyork" },
];

export const menuItemsBrandVinyl = [
    { id: 1, BrandName: "Beaulieu" },
    { id: 2, BrandName: "Coretec" },
    { id: 3, BrandName: "Quickstyle" },
    { id: 4, BrandName: "Fuzion Flooring" },
    { id: 5, BrandName: "Vidar Design" },
];

export const menuItemsBrandOther = [
    // Brands not clearly falling into Tile, Vinyl, Laminate, or Wood
    { id: 1, BrandName: "1867 Floors" },
    { id: 2, BrandName: "4 Corners" },
    { id: 3, BrandName: "A Life Floor" },
    { id: 4, BrandName: "Affiliated Weavers" },
    { id: 5, BrandName: "Akhal" },
    { id: 6, BrandName: "Amazing" },
    { id: 7, BrandName: "Anatolia" },
    { id: 8, BrandName: "Anderson Tuftex" },
    { id: 9, BrandName: "Baronwood" },
    { id: 10, BrandName: "Biyork" },
    { id: 11, BrandName: "Boen Oak" },
    { id: 12, BrandName: "Brand Coverings" },
    { id: 13, BrandName: "Brand Surfaces" },
    { id: 14, BrandName: "Canaan Wood" },
    { id: 15, BrandName: "Carlton" },
    { id: 16, BrandName: "Cashmere Woods" },
    { id: 17, BrandName: "Centura" },
    { id: 18, BrandName: "Citiflor" },
    { id: 19, BrandName: "Dream Living" },
    { id: 20, BrandName: "DZN Concepts" },
    { id: 21, BrandName: "ETM Design Flooring" },
    { id: 22, BrandName: "Euro EAC" },
    { id: 23, BrandName: "Euro Tile and Stone" },
    { id: 24, BrandName: "Evergreen Building Supplies" },
    { id: 25, BrandName: "Falcon Floors" },
    // Continue for all brands that didn't fit neatly into the first four categories
];

export const menuItemsBrandCarpet = [
    { id: 1, BrandName: "TBD" },
    { id: 2, BrandName: "TBD" },
    { id: 3, BrandName: "TBD" },
    { id: 4, BrandName: "TBD" },

];

export const menuItemsBrandCorkFlooring = [
    { id: 1, BrandName: "TBD" },
    { id: 2, BrandName: "TBD" },
    { id: 3, BrandName: "TBD" },
    { id: 4, BrandName: "TBD" },

];

export const menuItemsBrandSportFlooring = [
    { id: 1, BrandName: "TBD" },
    { id: 2, BrandName: "TBD" },
    { id: 3, BrandName: "TBD" },
    { id: 4, BrandName: "TBD" },

];

// export const menuItemsFormatTile = [
//     { id: 1, BrandName: "1867" },
//     { id: 2, BrandName: "In Haus" },
//     { id: 3, BrandName: "Kronoswiss" },
//     { id: 4, BrandName: "Pravada" },
//     { id: 5, BrandName: "Boen Oak" },
//     { id: 6, BrandName: "Biyork" },
// ];





