import React, { useEffect, useState } from "react";
import "./profile.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Addresses() {
  const Navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("Select Country");
  const [Address, setAddress] = useState();
  const [provinces, setProvinces] = useState([]);
  const [Addressdelete_id, setAddressdelete_id] = useState();
  const [Refresh, setrefresh] = useState(false);
  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);

  useEffect(() => {
    // dispatch(getCart());
    if (userData == null || userData === undefined || userData == "") {
      Navigate("/login");
    } else {
      axios.get("https://allinonetables.com/api/provinces/getAll")
        .then((response) => {
          console.log(28, response.data);
          setProvinces(response.data)
        })
        .catch((error) => {
          console.log(error.message)
        })
    }
  }, []);
  const [data, setData] = useState({
    user: userData?.user?.email || "",
    firstName: "",
    lastName: "",
    cName: "",
    address: "",
    address2: "",
    nearByLocation: "",
    state: "",
    stateId: "",
    StatetaxRate: "",
    city: "",
    country: "Canada",
    state: "",
    postalCode: "",
    mNo: "",
    email: userData?.user?.email,
    isDefault: true,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const pervinsetoData = ({ item }) => {
    console.log(63, item);
  }
  const SaveAddress = () => {
    console.log(data)
    if ( data.firstName !="" &&
    data.lastName !=""&&
    data.address !="" &&
    data.city !="" &&
    data.country !="" &&
    data.state !="" &&
    data.postalCode !="" &&
    data.mNo !=""
    ) {
       
    axios
      .post("https://allinonetables.com/api/address/create", data)
      .then((res) => {
        console.log(res);
        toast.success("Addres Save SucceFully...",{   autoClose: 1500});
        setrefresh(!Refresh);
      })
      .catch((err) => {
        console.log(err);
      });
    }else{
      toast.error("Please fill all fields..")
    }
  };

  useEffect(() => {
    if (userData == null || userData === undefined || userData == "") {
      Navigate("/login");
    } else {
      axios
        .get(`https://allinonetables.com/api/address/get/${userData.user.email}`)
        .then((res) => {
          console.log(55, res.data);
          const response = res.data[0];
          setAddress(response);
          if (response) {
            setData({
              user: userData.user.email,
              firstName: response.firstName,
              lastName: response.lastName,
              cName: response.cName,
              address: response.address,
              address2: response.address2,
              nearByLocation: response.nearByLocation,
              city: response.city,
              country: response.country,
              state: response.state,
              postalCode: response.postalCode,
              mNo: response.mNo,
              email: userData.user.email,
              isDefault: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

    }
  }, [Refresh]);
  const deleteAddress = () => {
    axios
      .delete(`https://allinonetables.com/api/address/${Addressdelete_id}`)
      .then(() => {
        toast.success("Address Deleted Successfully..",{   autoClose: 1500});
        setData({
          user: userData.user.email || "",
          firstName: "",
          lastName: "",
          cName: "",
          address: "",
          address2: "",
          nearByLocation: "",
          city: "",
          country: "canada",
          state: "",
          postalCode: "",
          mNo: "",
          email: userData.user.email,
          isDefault: true,
        });
        setrefresh(!Refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateAddress = (id) => {
    if (
      data.firstName != "" &&
      data.lastName !=" "&&
      data.address != "" &&
      data.city != "" &&
      data.country != "" &&
      data.state != "" &&
      data.postalCode != "" &&
      data.mNo !=""

    ){

      axios
        .put(`https://allinonetables.com/api/address/${id}`, data)
        .then((res) => {
          toast.success("Address Updated SUccessfully..",{   autoClose: 1500});
          setrefresh(!Refresh);
        })
        .catch((err) => {
          console.log(err);
        });
    }else{
      toast.error("Please fill all fields..")
    }
    console.log(id);
  };
  return (
    <>
      {/* /***************************************** Modal start */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered  modal-lg ">
          <div className="modal-content ps-2 pe-2 bg-color">
            <div className="modal-header p-2">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                <i className="bi bi-geo-alt pe-1"></i>
                Create address
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3">
                <div className="col-md-6">
                  <label for="inputEmail4" className="form-label m-0">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    name="firstName"
                    value={data.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label for="inputPassword4" className="form-label m-0">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="lastName"
                    value={data.lastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label for="inputEmail4" className="form-label m-0">
                    Company
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    name="cName"
                    value={data.cName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label for="inputPassword4" className="form-label m-0">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="mNo"
                    value={data.mNo}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label for="inputPassword4" className="form-label m-0">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="address"
                    value={data.address}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label for="inputEmail4" className="form-label m-0">
                    Address 2
                  </label>
                  <input
                    placeholder="Apt, Suite, Etc"
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    name="address2"
                    value={data.address2}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label for="inputPassword4" className="form-label m-0">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="city"
                    value={data.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="country" className="form-label m-0">
                    Country
                  </label>
                  <select
                    className="form-select"
                    id="country"
                    aria-label="Select Country"
                    // value={selectedCountry}
                    name="country"
                    // onChange={(e) => {
                    //   handleCountryChange(e);
                    //   // handleChange(e);
                    // }}
                  >
                    {/* <option value="Select Country" disabled>
                      Select Country
                    </option> */}
                    <option value="Canada" selected>
                      Canada
                    </option>
                    {/* <option value="United States">United States</option> */}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="province" className="form-label m-0">
                    Province
                  </label>
                  {/* {selectedCountry === "Select Country" && (
                    <p className="text-danger">Please select a country first</p>
                  )} */}
                  {/* {selectedCountry === "Canada" && ( */}
                  <select
                    className="form-select"
                    id="province"
                    aria-label="Select Province for Canada"
                    name="state"
                    onChange={(e) => {
                      const selectedProvince = e.target.value;
                      const findPro = provinces.find((province) => province.provinces_name === selectedProvince);
                      setData({ ...data, state: selectedProvince, stateId: findPro._id, StatetaxRate: findPro })
                    }}
                    value={data.state}
                  >
                    <option value="Select Province">
                      Select Province
                    </option>
                    {/* Options for Canada */}
                    {
                      provinces.map((item) => {
                        return (<>
                          <option value={item.provinces_name}>{item.provinces_name}</option>
                        </>)
                      })
                    }

                    {/* Add more options as needed */}
                  </select>
                  {/* )} */}
                  {/* {selectedCountry === "United States" && (
                    <select
                      className="form-select"
                      id="province"
                      aria-label="Select State for United States"
                      name="state"
                      value={data.state}
                      onChange={handleChange}
                    >
                      <option value="Select State" disabled>
                        Select State
                      </option>
                      <option value="New York">New York</option>
                      <option value="California">California</option>
                    </select>
                  )} */}
                </div>
                <div className="col-md-6">
                  <label for="inputPassword4" className="form-label m-0">
                    Zip
                  </label>
                  <input
                    name="postalCode"
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    value={data.postalCode}
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {!Address ? (
                <>
                  <button
                    type="button"
                    className="btn  text-start address-modal"
                    data-bs-dismiss="modal"
                    onClick={(e) => SaveAddress()}
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn  text-start address-modal"
                    data-bs-dismiss="modal"
                    onClick={(e) => UpdateAddress(Address?._id)}
                  >
                    Update
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* *********************************************************** Address modal end */}
      {/* ******************************************* Remove Modal Starts here */}

      <div
        class="modal"
        tabIndex="-1"
        id="staticBackdrop12"
        aria-labelledby="exampleModalLabel"
      >
        <div class="modal-dialog modal-dialog-centered justify-content-center">
          <div class="modal-content remove-box">
            <div className="row justify-content-center ">
              <div className="col-8 col-md-11 p-2 p-md-4 ">
                <div className="row py-4 justify-content-between align-items-center">
                  <div className="col-2">
                    <i class="bi bi-exclamation-circle text-danger fs-1"></i>
                  </div>
                  <div className="col-10">
                    Are you sure that you want to remove the address?
                  </div>
                </div>
                <div className="row justify-content-between align-items-center py-3">
                  <div className="col-6">
                    <button
                      className="w-100 p-1 text-white yes-remove"
                      data-bs-dismiss="modal"
                      onClick={(e) => deleteAddress()}
                    >
                      Yes, Remove
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 p-1 yes-cancel"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************* Remove modal end here */}
      <div className="row">
        <h3 className="text-start">Billing Address</h3>
        {/* <p>
          Allows you to easily upload addresses that you often ship from or to.
        </p> */}
        {/* ************************** leave for design and modal ************ */}
        {!Address && (
          <>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3">
              <div
                className="addressModal"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <i className="bi bi-person-vcard text-center"></i>
                <p className="text-center">Add Address</p>
              </div>
            </div>
          </>
        )}
        {/* /************************** Add address here  */}

        {Address && (
          <>
            <div
              // key={index}
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3"
            >
              <div className="viewAddress position-relative">
                <div className="forDefault border-bottom">
                  <p className="defaultMark mb-1">Default</p>
                </div>
                <div className="usrName">
                  <p>
                    <b>
                      {Address?.firstName} {Address?.lastName}
                    </b>
                  </p>
                </div>
                <div className="usrAddress">
                  <div className="companyName">
                    <p className="m-0">{Address?.cName}</p>
                  </div>
                  <div className="addressBelow">
                    <p className="m-0">{Address?.address || ""}</p>
                  </div>
                  <div className="PhoneNo">
                    <p className="m-0">+12 {Address?.mNo}</p>
                  </div>
                  <div className="PhoneNo">
                    <p className="m-0">{Address?.city}</p>
                  </div>
                  <div className="PhoneNo mb-2">
                    <p className="m-0">{Address?.postalCode}</p>
                  </div>
                </div>

                <div className="Options position-absolute">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Edit
                  </a>{" "}
                  |{" "}
                  <a
                    href="#!"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop12"
                    onClick={(e) => {
                      setAddressdelete_id(Address?._id);
                    }}
                  >
                    Remove
                  </a>{" "}
                  {/* | <a href="#">Mark as Default</a> */}
                </div>
              </div>
            </div>
          </>
        )}
        {/* ))} */}

        {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3">
                <div className="viewAddress position-relative">
                  <div className="forDefault border-bottom">
                    <p className="defaultMark mb-1 ">Default</p>
                  </div>
                  <div className="usrName">
                    <p>
                      <b>chetana Gawali</b>
                    </p>
                  </div>
                  <div className="usrAddress">
                    <div className="companyName">
                      <p className="m-0">qwertyu</p>
                    </div>
                    <div className="addressBelow">
                      <p className="m-0">
                        274 Bank Street, qwet 12 Ottawa, ON, CA, K2P1X6
                      </p>
                    </div>
                    <div className="PhoneNo mt-3 mb-4">
                      <p className="m-0">+12 1234567890</p>
                    </div>
                  </div>

                  <div className="Options position-absolute">
                    <a href="">Edit</a> |{" "}
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop12"
                    >
                      Remove
                    </a>{" "}
                    | <a href="">Mark as Default</a>
                  </div>
                </div>
              </div> */}
      </div>
    </>
  );
}

export default Addresses;
