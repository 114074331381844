import React, { useRef, useEffect, useState } from "react";
import "../css/askForQoute.css";
import * as Yup from "yup";
import { Formik } from "formik";
import ApiUtil from "../utils/apiUtils";
import { toast } from "react-toastify";
import PlacesAutocomplete from "react-places-autocomplete";

const AskForQuote = ({
  isOpen,
  onClose,
  slug,
  productName,
  totalQuantity,
  boxCoverage,
}) => {
  console.log(totalQuantity, "quANTITY");
  console.log(boxCoverage, "cover................");
  const popupRef = useRef(null);
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);

  const initialValues = {
    productName: productName || "",
    quantity: totalQuantity <= boxCoverage 
    ? 1 
    : Math.ceil(totalQuantity / boxCoverage),
    productSlug: slug,
    firstName: userData?.user?.firstName || "",
    lastName: userData?.user?.lastName || "",
    email: userData?.user?.email || "",
    phoneNumber: "",
    delivery: "",
    additionalNotes: "",
    shippingAddress: "",
    isContractor: false, // New field
    agreeToTerms: false, // New field
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const validationSchema = Yup.object().shape({
    productName: Yup.string().required("Product Name is required"),
    quantity: Yup.number()
      .required("Quantity is required")
      .min(1, "Quantity must be at least 1"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(
        /^\d{10,15}$/,
        "Phone number must be a valid US or Canadian number"
      ),
    delivery: Yup.string().required("Please select a delivery option"),
    shippingAddress: Yup.string().required("Shipping address is required"),
    additionalNotes: Yup.string(),
    isContractor: Yup.boolean().oneOf(
      [true],
      "You must confirm you are a contractor"
    ), // Validation for contractor checkbox
    agreeToTerms: Yup.boolean().oneOf([true], "You must agree to the terms"), // Validation for terms checkbox
  });

  const submitQuoteRequest = async (values, actions) => {
    const data = { ...values }; // no need for fullAddress anymore
    console.log(data, "Data");
    try {
      const response = await ApiUtil.SendAsync({
        url: "/quotes",
        method: "POST",
        data,
      });
      if (response.status !== 201) {
        const errorMessage =
          response.data?.error || "Network response was not ok";
        throw new Error(errorMessage);
      }
      toast.success("Quote request submitted successfully!");
      actions.resetForm();
      onClose();
    } catch (error) {
      toast.error(
        error.message || "Failed to submit quote request. Please try again."
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup" ref={popupRef}>
            <button className="close-btn" onClick={onClose}>
              &times;
            </button>

            <div className="quote-header">
              <div className="quote-text">
                <h2>Request a personalized quote for your project</h2>
                <p>
                  Fill out the form below to receive a personalized quote or
                  contact our customer service directly by email or phone.
                </p>
              </div>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitQuoteRequest}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit} className="quote-form">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Product Name</label>
                      <input
                        className="modal-input form-control"
                        value={values.productName}
                        type="text"
                        name="productName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly
                      />
                      {errors.productName && touched.productName && (
                        <div className="error">{errors.productName}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>
                        Quantity{" "}
                        <span
                          style={{
                            fontSize: "14px",
                            color: "grey",
                            marginLeft: "3px",
                          }}
                        >
                          (Minimum Required 1 box covers {boxCoverage} sq.ft.)
                        </span>
                      </label>
                      <input
                        className="modal-input form-control"
                        value={values.quantity}
                        type="number"
                        name="quantity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.quantity && touched.quantity && (
                        <div className="error">{errors.quantity}</div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>First Name</label>
                      <input
                        className="modal-input form-control"
                        value={values.firstName}
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.firstName && touched.firstName && (
                        <div className="error">{errors.firstName}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Last Name</label>
                      <input
                        className="modal-input form-control"
                        value={values.lastName}
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.lastName && touched.lastName && (
                        <div className="error">{errors.lastName}</div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Email</label>
                      <input
                        className="modal-input form-control"
                        value={values.email}
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {errors.email && touched.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Phone Number</label>
                      <input
                        className="modal-input form-control"
                        value={values.phoneNumber}
                        type="tel"
                        name="phoneNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        placeholder="e.g. +1 (555) 123-4567"
                      />
                      {errors.phoneNumber && touched.phoneNumber && (
                        <div className="error">{errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Delivery</label>
                      <select
                        className="modal-input form-control"
                        name="delivery"
                        value={values.delivery}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      >
                        <option value="">Select an option</option>
                        <option value="Ship to location">
                          Ship to location
                        </option>
                        <option value="Pick up">Pick up</option>
                      </select>
                      {errors.delivery && touched.delivery && (
                        <div className="error">{errors.delivery}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Shipping Address</label>
                      <PlacesAutocomplete
                        value={values.shippingAddress}
                        onChange={(address) =>
                          handleChange({
                            target: { name: "shippingAddress", value: address },
                          })
                        }
                        onSelect={(address) =>
                          handleChange({
                            target: { name: "shippingAddress", value: address },
                          })
                        }
                        searchOptions={{
                          componentRestrictions: { country: "CA" },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <input
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid #F0F0F0",
                              }}
                              {...getInputProps({
                                placeholder: "Enter address",
                              })}
                            />
                            <div
                              style={{
                                maxHeight: "200px",
                                overflowY: "auto",
                                position: "absolute",
                                width: "100%",
                                border: "none",
                              }}
                            >
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion, index) => (
                                <div
                                  key={index}
                                  {...getSuggestionItemProps(suggestion)}
                                  style={{
                                    backgroundColor: suggestion.active
                                      ? "#f0f0f0"
                                      : "#ffffff",
                                    padding: "10px",
                                  }}
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {errors.shippingAddress && touched.shippingAddress && (
                        <div className="error">{errors.shippingAddress}</div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <label>Additional Notes</label>
                      <textarea
                        className="modal-input form-control"
                        value={values.additionalNotes}
                        name="additionalNotes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isContractor"
                          name="isContractor"
                          checked={values.isContractor}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isContractor"
                        >
                          I am a contractor
                        </label>
                      </div>
                      {errors.isContractor && touched.isContractor && (
                        <div className="error">{errors.isContractor}</div>
                      )}
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="agreeToTerms"
                          name="agreeToTerms"
                          checked={values.agreeToTerms}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="agreeToTerms"
                        >
                          I agree to receive information via email or text
                          message
                        </label>
                      </div>
                      {errors.agreeToTerms && touched.agreeToTerms && (
                        <div className="error">{errors.agreeToTerms}</div>
                      )}
                    </div>
                  </div>
                  <div className="mt-4">
                    {" "}
                    <button
                      type="submit"
                      className="add-quote-summery-btn mt-3 text-light mr-3 w-100"
                    >
                      {" "}
                      Send Request{" "}
                    </button>{" "}
                  </div>
                  <div className="mt-4">
                    <p style={{ fontSize: "10px" }}>
                      By sharing your address, you enable us to provide a
                      personalized quote tailored to your location, ensuring the
                      most accurate pricing possible. Your privacy is important
                      to us, and we guarantee that your information will remain
                      confidential and used solely for generating your quote. By
                      opting into the FlooringDeals.ca mobile messaging and
                      email programs, you consent to receive up messages from
                      FlooringDeals.ca and agree to resolve disputes through
                      binding arbitration. To unsubscribe from emails, follow
                      the instructions provided in the email. For more details,
                      review our Privacy Policy and Terms & Conditions.F
                    </p>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default AskForQuote;
