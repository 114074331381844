import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import "./components/Navbar.css";
import _ from "lodash";
import axios from "axios";
import "./productsearch.css";
import { useDispatch, useSelector } from "react-redux";
import FreeSample from "./components/freeSample/FreeSample";
import url from "./Url";
import { getCart } from "./store/slices/CardSlice";
import { FaBars } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { LuTags } from "react-icons/lu";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronBackCircle } from "react-icons/io5";
import { IoCartOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa";

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

function Navbar() {
  const triggerRef = useRef(null);
  const [value, setValue] = useState("");
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const FevProduct = useSelector((state) => state.favroite);
  const cart = useSelector((state) => state.cart.data);
  const [cartNoUser, setCartNoUser] = useLocalStorage("cart", []); // Local storage cart for non-signed-in users
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [menuVisibility, setMenuVisibility] = useState({});
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [isSubSecondMenuVisible, setIsSubSecondMenuVisible] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(0); // Tracks the current menu level
  const [selectedPath, setSelectedPath] = useState([]); // Tracks selected menu/submenu indices
  const [selectedMenu, setSelectedMenu] = useState(null);
  const searchContainerRef = useRef(null);
  const menuRef = useRef(); // Create a ref for the menu
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCart());
  }, []);

  const handleMenuClick = (level, index, item) => {
    console.log(item, "myitem");
    const newPath = selectedPath.slice(0, level); // Retain path up to the current level
    newPath[level] = index; // Update the selected index for the level
    setSelectedPath(newPath);

    if (level === 2) {
      const categoryId = item._id; // Get the ID from the last level
      setCurrentLevel(0); // Reset to level 0 items
      setSelectedPath([]); // Optional: Reset the path completely
      navigate(`/category/${categoryId}`); // Navigate to the category page with the ID

      // Close the sidebar
      const offcanvas = document.querySelector('[data-bs-dismiss="offcanvas"]');
      if (offcanvas) offcanvas.click();
    } else {
      setCurrentLevel(level + 1); // Move to the next level
    }
  };

  console.log(currentLevel, "current");
  const handleBackClick = () => {
    setCurrentLevel((prev) => Math.max(prev - 1, 0)); // Move back one level
    setSelectedPath((prev) => prev.slice(0, -1)); // Remove the last selected index
  };

  const renderMenu = (menu, level = 0) => {
    if (level !== currentLevel) return null; // Show only the menu for the current level

    return (
      <ul style={{ width: "100%" }} className="menu-level">
        {/* Back Button */}
        {level > 0 && (
          <li
            onClick={handleBackClick}
            style={{ display: "flex", alignItems: "center" }}
            className=""
          >
            <IoChevronBackCircle
              style={{ marginRight: "10px" }}
              color="#E67929"
              size={26}
            />
            <button
              className=""
              style={{
                display: "block",
                textAlign: "start",
                cursor: "pointer",
                background: "none",
                border: "none",
                color: "#E57929",
              }}
            >
              Back
            </button>
          </li>
        )}
        {level === 0 && (
          <div
            onClick={() => homeNavigate()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <IoHomeOutline style={{ marginRight: "10px" }} size={24} />
            <li
              className="menu-item-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              Home
            </li>
          </div>
        )}

        {/* Menu Items */}
        {menu.map((item, index) => (
          <li
            key={index}
            className="menu-item-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "12px",
              marginBottom: "12px",
            }}
            onClick={() =>
              item.children
                ? handleMenuClick(level, index, item)
                : console.log(`Navigate to ${item.name}`)
            }
          >
            <div>
              <LuTags style={{ marginRight: "10px" }} size={24} />
              <span>{item.name}</span>
            </div>
            {item.children && <MdKeyboardArrowRight size={18} color="grey" />}
          </li>
        ))}
      </ul>
    );
  };

  // Get the current menu level to display
  const getCurrentMenu = () => {
    let menu = mainCat;
    for (const index of selectedPath) {
      menu = menu[index]?.children || [];
    }
    return menu;
  };
  const navigateTo = (path) => {
    navigate(path);
    setMenuVisibility({}); // Close all menus after navigation
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/product/getAll`, {
          params: {
            // page: 0,
            // limit: 10,
          },
        });
        console.log(response, "res");
        setData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const cartItems = userData ? cart : cartNoUser;
  // Throttle the handleChange function to be called at most once every 500 milliseconds
  const throttledHandleChange = _.throttle((inputValue) => {
    // Perform your logic here, like updating state or making API calls
    axios
      .get(
        `https://allinonetables.com/api/product/getBysearch?search=${inputValue}`
      )
      .then((res) => {
        if (inputValue === " ") {
          setData([]);
        } else {
          console.log(res?.data?.products, "searchdata");
          setData(res?.data?.products);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, 500);
  const handleSearchIconClick = () => {
    setShowSearchResults(!showSearchResults);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
      }
    };
    const handleScroll = () => {
      setShowSearchResults(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [searchContainerRef, triggerRef]);

  const handleChange = async (event) => {
    setIsInputFocused(true);
    const inputValue = event.target.value;
    setValue(inputValue);

    if (inputValue === "") {
        try {
            // Await the response of the axios GET call
            const response = await axios.get(`${url}/api/product/getAll`, {
                params: {
                    // Add additional parameters if needed
                },
            });
            console.log(response.data, "res"); // Log the response data
            setData(response?.data?.data); // Update state with fetched data
        } catch (error) {
            console.error("Error fetching all products:", error);
        }
    } else {
        throttledHandleChange(inputValue);
    }
};

  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };
  const handleRowClick = (slug) => {
    navigate(`productdetail/${slug}`);
    setIsInputFocused(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsInputFocused(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [triggerRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.querySelector("body").addEventListener("click", handleInputFocus);

    return () => {
      document
        .querySelector("body")
        .removeEventListener("click", handleInputBlur);
    };
  }, []);
  // *************************************************
  useEffect(() => {
    // Enable Bootstrap tooltips
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltips.forEach((tooltip) => {
      new window.bootstrap.Tooltip(tooltip);
    });
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log("Received event:", event);
      if (event.detail === "cart") {
        const newCart = JSON.parse(localStorage.getItem("cart") || "[]");
        setCartNoUser(newCart);
        console.log("Cart updated from custom event:", newCart);
      }
    };

    window.addEventListener("onLocalStorageChange", handleStorageChange);
    return () =>
      window.removeEventListener("onLocalStorageChange", handleStorageChange);
  }, []);

  const [mainCat, setMaincat] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/api/category/getCat`)
      .then((response) => {
        console.log(100, response.data.categoryList);
        console.log(7, mainCat);
        setMaincat(response.data.categoryList);
      })
      .catch((err) => {
        console.log(17, err);
      });
  }, []);

  const catNavigate = (id) => {
    console.log(17, "faq clicked try refresh");
    navigate(`/category/${id}`);
  };
  const homeNavigate = () => {
    setMenuVisibility({}); // This line hides all menus
    navigate(`/`);
    // Close the sidebar
    const offcanvas = document.querySelector('[data-bs-dismiss="offcanvas"]');
    if (offcanvas) offcanvas.click();
  };
  const faqNavigate = () => {
    console.log(17, "faq clicked try refresh");
    navigate(`/faq`);
  };
  const shipNavigate = () => {
    navigate(`/ship`);
  };
  const contactNavigate = () => {
    navigate(`/contactUs`);
  };
  const aboutNavigate = () => {
    navigate(`/about`);
  };
  const brandNavigate = () => {
    navigate(`/brand`);
    window.location.reload();
  };
  const cartNavigate = () => {
    navigate(`/cart/${userData?.user?.email}`, { replace: true });
  };
  const accountNavigate = () => {
    userData == undefined || userData === null
      ? navigate(`/login`)
      : navigate(`/profile`);
  };
  const wishlistNavigate = () => {
    userData == undefined || userData === null
      ? navigate(`/login`)
      : navigate(`/profile/4`);
  };
  const monthNavigate = () => {
    navigate(`/MonthSpecialPage`);
  };
  const clearNavigate = () => {
    navigate(`/clearencePage`);
  };
  const discountNavigate = () => {
    navigate(`/discoutPage`);
  };
  // Creating a date object
  var today = new Date();

  // Getting full month name (e.g. "June")
  var month = today.toLocaleString("default", { month: "long" });
  return (
    <div>
      {/* *********Top Header ***************/}
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="top">
                <p>
                  <Link to="/contactUs">
                    <i className="fa-sharp fa-solid fa-location-dot"></i>{" "}
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="phone">
                <p>
                  <a href="tel:905 277 2227">
                    <i className="fa-solid fa-phone"></i> (905) 302 6683
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**************** Second Header ***************/}

      <div className="content">
        <div className="second-header pt-2 for-mobile-sticky">
          <div className="container">
            <div className="row v-center pb-2 ">
              <div className="col-6 col-sm-6 col-md-1 col-lg-1">
                <div className="header-item item-left">
                  <nav className="navbar  responsive-nav-for-sm-screen mobile-navbar-spacing">
                    <div className="container">
                      <button
                        className="custom-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar"
                        aria-controls="offcanvasDarkNavbar"
                        aria-label="Toggle navigation"
                        style={{
                          background: "transparent", // Transparent background
                          border: "none", // No border
                          padding: "0", // Remove default padding
                          outline: "none", // Ensure no outline
                          cursor: "pointer", // Pointer cursor
                        }}
                      >
                        <FaBars size={24} color="currentColor" />{" "}
                        {/* Use the icon */}
                      </button>

                      <div
                        className="offcanvas offcanvas-start bg-color-for-canvas"
                        style={{ width: "85%" }}
                        tabIndex="-1"
                        id="offcanvasDarkNavbar"
                        aria-labelledby="offcanvasDarkNavbarLabel"
                      >
                        <div className="offcanvas-header">
                          <div className="logo">
                            <Link to="/">
                              <img
                                src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                                style={{ width: "100px" }}
                                alt=""
                              />
                            </Link>
                          </div>
                          <button
                            type="button"
                            className="btn-close btn-close-dark"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "86%",
                              height: "2px",
                              backgroundColor: "#D3D3D3",
                            }}
                          ></div>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav justify-content-end flex-grow-1 pe-1">
                            <nav className="menu" ref={menuRef}>
                              {renderMenu(getCurrentMenu(), currentLevel)}
                            </nav>

                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "5%",
                              }}
                            >
                              <div
                                style={{
                                  width: "97%",
                                  height: "2px",
                                  backgroundColor: "#D3D3D3",
                                }}
                              ></div>
                            </div>
                            <li className="nav-item">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <IoCartOutline
                                  style={{ marginRight: "10px" }}
                                  size={22}
                                />
                                <Link
                                  to={`/cart/${userData?.user?.email}`}
                                  className="nav-link"
                                  onClick={() => cartNavigate()}
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  Cart
                                </Link>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaRegUser
                                  style={{ marginRight: "10px" }}
                                  size={18}
                                />
                                <Link
                                  to="/profile"
                                  className="nav-link"
                                  onClick={() => accountNavigate()}
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  My Account
                                </Link>
                              </div>
                            </li>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FaRegHeart
                                style={{ marginRight: "10px" }}
                                size={22}
                              />
                              <Link
                                to="/profile/4"
                                className="nav-link"
                                onClick={() => wishlistNavigate()}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Wish List
                              </Link>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                        style={{ width: "100px" }}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5 hide-on-mobile">
                <div className="header-item item-center py-1 py-md-1 ms-md-5 position-relative">
                  <div className="searching">
                    <i className="fas fa-search"></i>
                    <input
                      type="text"
                      name=""
                      ref={triggerRef}
                      value={value}
                      id=""
                      onChange={(e) => handleChange(e)}
                      placeholder="Search Your Product"
                      onFocus={handleInputFocus}
                      // onBlur={handleInputBlur}
                    />
                  </div>
                  {isInputFocused && (
                    <div className="container ">
                      <div className="search-bar-for-search-option">
                        <div className="row">
                          <>
                            {data?.length > 0 ? (
                              <>
                                {data?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className="search-grid "
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          //  navigate(`productdetail/${item.item.slug}`);
                                          handleRowClick(item?.slug);
                                          //  setIsInputFocused(false);
                                        }}
                                      >
                                        <div className="imageSearchProduct mt-2">
                                          <img
                                            className="img-fluid rounded-2"
                                            src={item?.productPictures[0]}
                                            alt=""
                                          />
                                        </div>
                                        <div className=" d-flex align-item-center">
                                          <div className="discription-search mt-2">
                                            <p className="m-0 search-head">
                                              {item?.name}
                                            </p>
                                            {/* <p className="m-0 search-number">
                                              BYKRCHY50CA
                                            </p> */}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <p>No Product Match</p>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* ********************************************** */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="header-item item-right">
                  {/* **************************** */}

                  {/* ************************** */}
                  {userData == undefined || userData === null ? (
                    <>
                      <Link
                        to="/login"
                        // data-bs-toggle="modal"
                        // data-bs-target="#loginSignupForm"
                        className="user"
                      >
                        <i className="bi bi-person"></i>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link className="userwithName" to="/profile">
                        <p className="m-0 ">
                          <i className="bi bi-person-fill-check pe-1"></i>
                          {userData.user.firstName}&nbsp;
                        </p>
                      </Link>
                    </>
                  )}
                  <i
                    className="bi bi-search hide-on-large-screen"
                    onClick={handleSearchIconClick}
                    style={{ cursor: "pointer" }}
                  ></i>
                  {/* ******************** After sign up *************** */}
                  {/* ********************** */}
                  <Link to="/profile/4">
                    <i className="bi bi-suit-heart"></i>
                    {/* <p> Favorites</p> */}
                    {FevProduct?.data?.length > 0 ? (
                      <>
                        <span>{FevProduct?.data?.length || 0}</span>
                      </>
                    ) : (
                      ""
                    )}
                  </Link>

                  {/* **************************** */}
                  <Link to={`/cart/${userData?.user?.email}`}>
                    <i className="bi bi-cart3"></i>
                    {cartItems?.length > 0 ? (
                      <>
                        <span>{cartItems?.length || 0}</span> <br />
                      </>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    className="hide-on-mobile"
                    to="/costestimationInstallationPage"
                  >
                    <i
                      className="bi bi-calculator"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Installation Calculator"
                    ></i>
                  </Link>
                  <Link
                    className="hide-on-mobile"
                    to=""
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <i
                      className="bi bi-columns"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Free Samples"
                    ></i>
                  </Link>
                  <FreeSample />
                </div>
              </div>
              {showSearchResults && (
                <div
                  className="col-sm-12 col-md-5 col-lg-5 hide-on-large-screen"
                  ref={searchContainerRef}
                >
                  <div className="header-item item-center py-1 py-md-1 ms-md-5 position-relative">
                    <div className="searching">
                      <i className="fas fa-search"></i>
                      <input
                        type="text"
                        name=""
                        ref={triggerRef}
                        value={value}
                        id=""
                        onChange={(e) => handleChange(e)}
                        placeholder="Search Your Product"
                        onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                      />
                    </div>
                    {isInputFocused && (
                      <div className="container ms-md-5">
                        <div className="search-bar-for-search-option">
                          <div className="row">
                            <>
                              {data?.length > 0 ? (
                                <>
                                  {data?.map((item) => {
                                    return (
                                      <>
                                        <div
                                          className="search-grid "
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            //  navigate(`productdetail/${item.item.slug}`);
                                            handleRowClick(item?.slug);
                                            //  setIsInputFocused(false);
                                          }}
                                        >
                                          <div className="imageSearchProduct mt-2">
                                            <img
                                              className="img-fluid rounded-4"
                                              src={item?.productPictures[0]}
                                              alt=""
                                            />
                                          </div>
                                          <div className=" d-flex align-item-center">
                                            <div className="discription-search mt-2">
                                              <p className="m-0 search-head">
                                                {item?.name}
                                              </p>
                                              {/* <p className="m-0 search-number">
                                              BYKRCHY50CA
                                            </p> */}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <p>No Product Match</p>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* **************************** Mune Nav  **************************/}
      <Header />
    </div>
  );
}
export default Navbar;
