import React from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const location = useLocation();
  const { signupData } = location.state || {};
  const email = signupData?.email; // Access email from signupData
  
  const handler = async () => {
    if (!email) {
      console.error("Email is not available!");
      return;
    }

    try {
      const response = await axios.get(`https://allinonetables.com/api/user/resend-verification/${email}`);
      toast.success("Verification link resent successfully:", response.data);
    } catch (error) {
      console.error("Error resending verification link:", error.response?.data || error.message);
      toast.error("Failed to resend verification link. Please try again.");
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="shadow p-4 rounded bg-white text-center w-75 w-md-50 mt-4">
        <h5 className="fw-bold mb-3" style={{ color: "#E77E32" }}>
          Password Reset Link Sent
        </h5>
        <p className="mb-0">
          A password reset link has been sent to your email. Please check your
          inbox and follow the instructions to reset your password.
        </p>
        <p
          onClick={handler}
          className="fw-bold mb-3"
          style={{ color: "#E77E32", cursor: "pointer" }}
        >
          Resend Verification Link
        </p>
      </div>
    </div>
  );
};

export default VerifyEmail;
