import Tooltip from '@mui/material/Tooltip';
import React from "react";
import { useNavigate } from "react-router-dom";
import NoProductPage from "../components/flooring/NoProductPage";
import { scrollToTop } from "../utils/util";

const ProductImagesDisplay = ({ Product }) => {
  const navigate = useNavigate();
  const handleImageClick = (slug) => {
    scrollToTop();
    navigate(`/productdetail/${slug}`);
  };

  if (!Product?.length > 0) {
    return (<NoProductPage />)
  }

  return (
    <div className="shop-bottom-area">
      <div className="tab-content jump">
        <div id="shop-1" className="tab-pane active">
          <div className="row">
            {Product.map((item) => (
              <Tooltip title={item.name} placement="top" arrow>
                <div
                  style={{ height: "120px", marginBottom: "10px" }}
                  className="col-6 col-lg-3 col-md-3 col-sm-6 product-image-container"
                  key={item._id}
                >
                  <img
                    src={item?.productPictures[0] ||
                      "https://images.homedepot.ca/productimages/p_1001708289.jpg?product-images=m"
                    }
                    loading='lazy'
                    alt={item.name}
                    className="img-fluid h-100 rounded-10"
                    onClick={() => handleImageClick(item.slug)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductImagesDisplay;
