import React, { useState } from "react";

const ProductFilters = ({ filters, handleFilterChange, collapseFilters }) => {
  const [showFilters, setShowFilters] = useState(false); // State to toggle filters
  const [showFiltersModal, setShowFiltersModal] = useState(false); 

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const toggleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal);
  };


  const renderFilterSection = (filter, index) => {
    const isCollapsed = index !== 0; // Only the first filter is open by default

    return (
      <div
        className="accordion-item pb-3 border-bottom" // Adds space and border-bottom after each filter
        key={index}
      >
        <h2 className="accordion-header">
          <button
            className={`accordion-button ${isCollapsed ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#accordion-${index}`}
            aria-expanded={!isCollapsed}
            aria-controls={`accordion-${index}`}
          >
            {filter.name}
          </button>
        </h2>
        <div
          id={`accordion-${index}`}
          className={`accordion-collapse collapse ${
            !isCollapsed ? "show" : ""
          }`}
          aria-labelledby={`accordion-${index}`}
        >
          <div className="accordion-body">
            {filter.type === "checkbox" && (
              <div className="filter-options">
                {filter.values.map((value, i) => (
                  <div key={i} className="checkbox-widget d-flex gap-2">
                    <input
                      type="checkbox"
                      id={`filter-${filter.name}-${value}`}
                      value={value}
                      onChange={(e) =>
                        handleFilterChange(
                          filter.query_param,
                          e.target.value,
                          e.target.checked
                        )
                      }
                    />
                    <label style={{fontSize:"14px"}} htmlFor={`filter-${filter.name}-${value}`}>
                      {value}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="sidebar-widget sidebar-widget-border"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        {/* Filter Toggle Button */}
      <div className="container d-block d-lg-none">
      <button
          type="button"
          class="btn btn-primary show-filter"
      onClick={toggleFiltersModal}
        >
          Show Filters <i class="bi bi-funnel"></i>
        </button>
      </div>

        {/* Modal for Filters */}
        {showFiltersModal && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Filters</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={toggleFiltersModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    {filters &&
                      filters?.allFilters.map((filter, index) =>
                        renderFilterSection(filter, index)
                      )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={toggleFiltersModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Desktop Filter */}
      <div
        className="sidebar-widget sidebar-widget-border"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        {/* Filters Section */}
        <div
          className={`sidebar-list-style ${
            showFilters ? "" : "d-none d-lg-block"
          }`} // Hide on small screens unless toggled
        >
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {filters &&
              filters?.allFilters.map((filter, index) =>
                renderFilterSection(filter, index)
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductFilters;
