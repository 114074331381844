import { Field, Formik, Form as BaseForm, ErrorMessage } from "formik";
import React from "react";
import Button from "../Button";

const Form = ({
  initialValues,
  validationSchema,
  onSubmitHandler,
  fields,
  formClasses,
  loading,
  children,
  extraButtons,  // New prop for extra buttons like "Continue with Google"
  submittingText,
  submitText,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {({ handleChange }) => (
        <BaseForm className={formClasses}>
          {fields.map(({ name, type, label, component, rest, classes }) => (
            <div key={name} className={classes}>
              <label htmlFor={name}>{label}</label>
              {component ? (
                <Field
                  className="w-100"
                  type={type}
                  name={name}
                  component={component}
                  onChange={handleChange}
                  {...rest}
                />
              ) : (
                <Field className="w-100" type={type} name={name} />
              )}
              <ErrorMessage
                name={name}
                className="flex justify-start text-danger"
                component="span"
              />
            </div>
          ))}
          {children}
          <Button
            loading={loading}
            loadingText={submittingText}
            text={submitText}
            type="submit"
            style={{
              backgroundColor: "#E77E32",
              border: "none",
              width: "100%",
            }}
          />
          <p className="pt-3  text-center">OR</p>
          <div className="d-flex justify-content-center w-full">
          {extraButtons}
          </div>
        </BaseForm>
      )}
    </Formik>
  );
};

export default Form;
