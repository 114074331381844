import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function FreeSample() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h4 className="offcanvas-title" id="offcanvasRightLabel">
            <b>
              <i className="bi bi-palette2 pe-2 myColor fs-5"></i>
            </b>
            <b>Get your free samples</b>
          </h4>

          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <hr className="m-0" />
        <div className="offcanvas-body p-3">
          <h5>
            <b>How does it Work?</b>
          </h5>
          <div className="freeSampleList">
            <ul className="fs-6">
              <li>• Select up to 7 free samples.</li>
              <li>• Shipped within the next 48 hours.</li>
              <li>• No need to return, they’re yours to keep!</li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="offcanvas-footer">
          <hr />
          <div className="addMoreSamples d-flex align-items-center">
            <i className="bi bi-box-seam-fill myColor p-3 fs-2"></i>
            <div>
              {" "}
              <h6>
                <b>You can add more than 7 samples</b>
                <p className="m-0">Get up to 7 free samples</p>
              </h6>
            </div>
          </div>
          <hr className="mb-0" />
          <div className="float-end d-flex p-2">
            {" "}
            <Link
              className="btn btn-secondary text-white me-2 fs-6"
              data-bs-dismiss="offcanvas"
              onClick={() => navigate("/getSamples")}
              aria-label="Close"
            >
              Close
            </Link>
            <Link
              className="btn mybgColor text-white confirm fs-6"
              onClick={() => navigate("/contactUs")}
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default FreeSample;
