import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("The email field is requied"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("The password field is required"),
});

export const signupValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Please confirm your password"),
  Contact: Yup.string()
    .test("is-numeric", "Contact number must be numeric", (value) => {
      if (!value) return true;
      const isNumeric = /^[0-9]+$/.test(value);
      return isNumeric;
    })
    .max(10, "Contact number must be at most 10 digits long"),
});
