import React, { useState } from "react";
import "./signup.css";
import Login from "./Login";

function Signup() {
  const [showLoginForm, setShowLoginForm] = useState(true);

  const toggleForm = () => {
    setShowLoginForm(!showLoginForm);
  };
  return (
    <>
      <div
        className="modal fade"
        id="loginSignupForm"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> */}
            <div className="modal-body justify-content-center bg-color rounded">
              {showLoginForm ? (
                <div className="login-box bg-color">
                  <img
                    src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                    style={{ width: "100px" }}
                    alt=""
                  />
                  <form className="signUpForm bg-transparent">
                    <label>Email Address</label>
                    <input
                      className="LoginSignupInput"
                      type="email"
                      placeholder=""
                    />
                    <label>Password</label>
                    <input
                      className="LoginSignupInput"
                      type="password"
                      placeholder=""
                    />
                    <input
                      className="LofinSignupSubmit"
                      type="button"
                      value="Submit"
                    />
                  </form>
                  <div className="para-2 bg-color">
                    <p className="d-flex justify-content-center mt-3 text-dark">
                      Not have an account?{" "}
                      <a className="forSignup" onClick={toggleForm}>
                        Sign Up Here
                      </a>
                    </p>
                  </div>
                </div>
              ) : (
                <Login />
              )}
            </div>
            <div className="modal-footer bg-color">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Skip
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
