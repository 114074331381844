import React from "react";

function GetSamples() {
  return (
    <>
      {/* ************************ breadcrumb ******************** */}
      <br />
      <br />

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Free Samples</li>
          </ul>
        </div>
      </div>
      {/* *********************************************** */}
      <section id="sample-products" className="container">
        <div className="stats">
          <div className="container">
            <div className="row text-center">
              <div className="col-12 text-center">
                <span className="myColor">
                  <b>FREE SAMPLES</b>
                </span>
                <h2 className="text-center text-dark">
                  <b> Make the right choice, the easy way.</b>
                </h2>
                <p className="text-center fs-6">
                  Get up to 7 free samples, shipped within the next 48 hours.{" "}
                  <br />
                  Order in 3 easy steps. No need to return.
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 mt-5">
                <div className="stat">
                  <i className="bi bi-tag-fill freeSamples"></i>

                  <h3>FREE SAMPLES</h3>
                  <p className="text-center pt-3">
                    Samples are free. We only charge a small flat fee for
                    shipping costs
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 mt-5">
                <div className="stat">
                  <i className="bi bi-palette2 freeSamples"></i>
                  <h3>7 SAMPLES</h3>
                  <p className="text-center pt-3">
                    Get up to 7 free samples per order. Chose your samples
                    across all our product categories.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 mt-5">
                <div className="stat ">
                  <i class="fa-solid fa-truck freeSamples"></i>

                  <h3>FAST SHIPPING</h3>
                  <p className="text-center pt-3">
                    Samples are shipped within the next 48 hours. Your dream
                    floors will arrive at your door soon.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-3 col-lg-3 mt-5">
                <div className="stat">
                  <i className="bi bi-emoji-smile freeSamples"></i>
                  <h3>YOURS TO KEEP</h3>
                  <p className="text-center pt-3">
                    Appreciate your samples under different lighting. No time
                    constraints; they’re yours to keep
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ************************* */}
      <section>
        <div className="container">
          <div className="row">
            <h3 className="myColor text-center">
              <b>HOW DOES IT WORK ?</b>
            </h3>
          </div>
        </div>
      </section>
      {/* ******************* */}
      <section>
        <div className="container mb-5">
          <div class="row ">
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-5">
              <div className="step text-center">
                <p className="text-center">Step 1 :</p>
                <h6 className="text-center pb-2">CHOOSE YOUR SAMPLES</h6>
                <img
                  src="https://cdn.theFlooring Deals.ca/free-samples.jpg"
                  className="img-fluid"
                  alt=""
                />
                <p className="text-center pt-3">
                  Click on the yellow “Get Your Free Sample” button on any
                  product photo. A free sample will be automatically added to
                  your cart. Add up to 7 free samples.
                </p>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-5">
              <div className="step text-center">
                <p className="text-center">Step 2 :</p>
                <h6 className="text-center pb-2">ENTER YOUR ADDRESS</h6>
                <img src="https://cdn.theFlooring Deals.ca/checkout.jpg" alt="" />
                <p className="text-center pt-3">
                  Go to your cart and click on “Proceed to Checkout”. Complete
                  the address form and we’ll ship the samples within 24 hours.
                  Samples are free.
                </p>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-5">
              <div className="step text-center">
                <p className="text-center">Step 3 :</p>
                <h6 className="text-center pb-2">RECEIVE YOUR SAMPLES</h6>
                <img src="https://cdn.theFlooring Deals.ca/order.jpg" alt="" />
                <p className="text-center pt-3">
                  Enjoy your samples. Appreciate their texture and appearance
                  under different types of lighting. Once you’ve made a choice,
                  your can place your order.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GetSamples;
