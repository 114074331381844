import React from "react";
import "./extrainfopages.css";
import { Link } from "react-router-dom";

function ExtraPagesLinks() {
  return (
    <div>
      <div className="extraInfo">
        <div className="row">
          <div className="col-1">
            <i class="bi bi-coin fs-3 myColor"></i>
          </div>
          <div className="col-11 pb-3">
            <h6 className="m-0">
              <b>Best price guarantee</b>
            </h6>
            <Link to="/contactUs">
              <p>
                <u>If you find a lower price, we will gladly match it!</u>
              </p>
            </Link>
          </div>
          <div className="col-1">
            <i className="bi bi-palette2 fs-3 myColor"></i>
          </div>
          <div className="col-11 pb-3">
            <h6 className="m-0">
              <b>Get your free samples</b>
            </h6>
            <Link to="/getSamples">
              <p>
                <u>Order up to 7 different samples, yours to keep.</u>
              </p>
            </Link>
          </div>

          <div className="col-1">
            <i class="bi bi-shield-fill-check fs-3 myColor"></i>
          </div>
          <div className="col-11">
            <h6 className="m-0">
              <b>30 days money back guarantee</b>
            </h6>
            <Link to="/moneyBack">
              <p className="m-0">
                <u>See our return policy.</u>
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtraPagesLinks;
