import { Skeleton } from "@mui/material";

const ProductSkeleton = () => (
  <div
    className="col-12 col-xxl-3 col-lg-4 col-md-4 col-sm-6 mb-35"
    key="skeleton"
  >
    <div className="product-wrap mb-3 ">
      <div className="product-img img-zoom">
        <div className="position-relative">
          <Skeleton variant="rectangular" width={250} height={200} />
          <Skeleton variant="circle" width={40} height={40} className="position-absolute h4 z-10 top-0 end-0 mt-2 me-2" /> {/* Fav icon skeleton */}
        </div>
        <Skeleton variant="text" width={80} />
        <Skeleton variant="text" width={50} />
      </div>
      <div className="product-content ">
        <Skeleton variant="text" width={200} />
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", height: "30px" }}>
          <Skeleton variant="text" width={50} />
          <Skeleton variant="text" width={10} />
          <Skeleton variant="text" width={100} />
        </div>
        <Skeleton variant="text" width={150} />
        <Skeleton variant="text" width={100} />
      </div>
    </div>
  </div>
);

export default ProductSkeleton;
