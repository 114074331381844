import React from "react";
import "./addquotesummarybox.css";
function AddQuoteSummaryBox() {
  return (
    <>
      <div className="quote">
        <div className="container d-flex flex-row-reverse">
          <div
            className="card mb-3 position-relative p-3"
            style={{ maxWidth: "440px" }}
          >
            <button
              type="button"
              className="btn btn-light position-absolute quote-btn"
            >
              <i class="bi bi-basket3-fill pe-2"></i> Quote Summary
            </button>
            <h6>Added to Quote Summary</h6>
            <div className="row g-0">
              <div className="col-ms-2 col-md-2 d-none d-sm-block">
                <img
                  src="assets/images/product/resize.jpg"
                  className="img-fluid rounded w-100 "
                  alt="..."
                />
              </div>
              <div className="col-sm-10 col-md-10">
                <div className="card-body">
                  <p className="card-text ps-2">
                    Next Floor Vinyl Planks StoneCast Incredible 525 Light Oak
                    Click Lock 7" * 48" <br />
                    Box: 5
                  </p>
                  <p className="card-text float-end">
                    <a
                      href="#"
                      className="btn bg-color-for-canvas1 text-dark ps-5 pe-5 me-2"
                    >
                      Close
                    </a>
                    <a href="#" className="btn bg-color1 text-white">
                      View Summary
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddQuoteSummaryBox;
