import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getFev } from "../../store/slices/FavSlices";

function Favorite() {
  const dispatch = useDispatch();
  const fevProduct = useSelector((state) => state.favroite.data);
  // const [fevProduct, setFevProduct] = useState(FevProduct);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    dispatch(getFev());
  }, [refresh]);

  const removeFaverites = (id) => {
    axios
      .delete(`https://allinonetables.com/api/fev/delete/${id}`)
      .then((res) => {
        dispatch(getFev());
        setRefresh(!refresh);
        toast.error("Favorite Product Deleted From List..");
      })
      .catch((err) => console.log(err));
  };

  const excludedCategories = [
    "Mortars", "Grout", "Caulk", "Sealants", "Floor Patch",
    "Levelers", "Primers", "Adhesives", "Adhesive", "Wall Base",
    "Moldings", "Floor Vent", "Underlayments", "Underlayment", "Membrane", "Membranes"
  ];
  
  // Helper function to determine if the name includes any excluded category
  const includesExcludedCategory = name => {
    return name && excludedCategories.some(category => name.includes(category));
  };
  
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}
      <br />
      <br />

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {">"}
            <li>Favorites List</li>
          </ul>
        </div>
      </div>

      {/* ********************** favorites **************************** */}

      <div className="favorites">
        <div className="container">
          <div className="row">
            <div className="row">
              {}
              {fevProduct && fevProduct.length > 0 ? (
                fevProduct.map((item) => (
                  <div
                    className="col-lg-2 col-md-2 col-sm-6 col-12"
                    key={item._id}
                  >
                    <div className="product-wrap mb-35">
                      <div className="product-img img-zoom">
                      <Link to={`/productdetail/${item.product[0].slug}`}>
                          <img
                            src={
                              item.product[0].productPictures[0] ||
                              "assets/images/product/first.jpg"
                            }
                            alt=""
                          />
                          <img
                            id="change-img"
                            src={
                              item.product[0].productPictures[1] ||
                              "assets/images/product/change.jpg"
                            }
                            alt=""
                          />
                        </Link>
                        <div className="product-badge badge-top badge-right badge-pink">
                          <span>Best Seller</span>
                        </div>
                        <div className="new-badge">
                          <span>
                            <img src="assets/images/icons/new.png" alt="" />
                          </span>
                        </div>
                      </div>
                      <div className="product-content">
                        <h3>
                          <Link to={`/productdetail/${item.product[0].slug}`}>
                            {item.product[0].name}
                          </Link>
                        </h3>
                        <p>{item.product[0].short_desc}</p>
                        <div className="product-price">
                          <span className="new-price">
                            ${item.product[0].sale_price} / <sub>sq. ft.</sub>
                          </span>
                          <i
                            className="bi bi-heart-fill text-danger"
                            role="button"
                            title="Add To Favorite"
                            onClick={(e) => removeFaverites(item._id)}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="py-5">
                  <h4>No favorite products are added</h4>
                  <Link className="default-button w-50 w-md-25 my-5" to="/">
                    Back to Home
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Favorite;
