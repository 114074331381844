import { useEffect, useState } from 'react';
import ApiUtil from '../../utils/apiUtils';

function useBanners() {
	const [banners, setBanners] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchBanners = async () => {
			try {
				setLoading(true);
				const response = await ApiUtil.SendAsync({
					url: "/banner/getAll",
					method: 'GET',
				});
				console.log('banners',response.data)
				setBanners(response.data);
			} catch (err) {
				setError(err.message || 'An error occurred');
			} finally {
				setLoading(false);
			}
		};

		fetchBanners();
	}, []);

	return { banners, loading, error};
};

export default useBanners;
