import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getFev = createAsyncThunk(
    "fev/userFevProduct",
    async () => {
        const item = localStorage.getItem("user");
        const userData = JSON.parse(item);
        try {
            const response = await axios.get(`https://allinonetables.com/api/fev/get/${userData.user.email}`);
            console.log(25, response.data);
            return response.data;
        } catch (error) {
            console.error(error);
            // toast.error(error.response?.data?.error || "An error occurred");
            throw error;
        }
    }
);

const initialState = {
    data: null,
    error: null,
    loading: false
};

const fevSlice = createSlice({
    name: 'fev',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFev.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(getFev.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(getFev.rejected, (state, action) => {
                state.error = action.error;
                state.loading = false;
            });
    },
});

export default fevSlice.reducer;
