import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CostEstimation() {
  const navigate = useNavigate();
  const [installationCost, setInstallationCost] = useState("");

  const handleInputChange = (event) => {
    setInstallationCost(event.target.value);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (!installationCost) {
      toast.error("Please enter a value before previewing.");
    } else {
      navigate(`/savedInstallation/${installationCost}`);
    }
  };

  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="">Home</a>
            </li>
            {">"}
            <li>Installation Cost Estimation</li>
          </ul>
        </div>
      </div>

      {/* *********************** Installation Cost Estimation ************************ */}

      <div className="cost-estimation pb-50">
        <div className="container">
          <div className="row">
            <h2>Installation Cost Estimation</h2>
            <div className="col-md-5 col-lg-5 col-sm-12">
              <div className="cost-img mb-15 row justify-content-center">
                <img
                  className="img-fluid"
                  src="assets/images/cost-esimation/2.jpg"
                  alt=""
                />
              </div>
              <div className="new-estimation ptb-20">
                <a
                  className="default-button"
                  onClick={(e) => navigate("/costestimationInstallationPage")}
                >
                  Create a New Estimation
                </a>
                {/* <p>OR</p> */}
                {}
                {}
              </div>
            </div>

            <div className="col-md-6 col-lg-6 col-sm-12">
              <div className="para ">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
                  possimus illum et fugit ab nemo itaque id vitae excepturi,
                  autem commodi quidem ut enim facere officia doloremque,
                  perferendis velit cupiditate consequatur. Inventore impedit
                  error asperiores ea praesentium reprehenderit velit
                  voluptatum! Adipisci, vel eos. Assumenda cum et unde facere
                  maxime non corporis id repellendus accusamus doloribus
                  reprehenderit itaque, modi, natus architecto omnis sit
                  voluptatibus sapiente recusandae aliquid consectetur.
                  <p className="pt-2">
                    Ad quas quia enim quibusdam explicabo totam. Nihil, nostrum
                    quos placeat dolore tenetur porro ipsam vel dicta qui beatae
                    tempore quam totam doloremque necessitatibus accusamus
                    blanditiis nobis distinctio laboriosam molestias. Itaque
                    quasi incidunt ipsam animi repudiandae nemo, sunt eum omnis
                    placeat autem dolores eaque ullam! Perspiciatis ab
                    aspernatur nihil qui sunt. Iste, voluptate fuga voluptates
                    voluptatem doloribus mollitia at, necessitatibus assumenda
                    expedita ipsam debitis rerum deserunt!
                  </p>
                  <p className="pt-2">
                    Nam sed, quisquam consequuntur quidem dolores voluptas hic
                    corporis facilis quibusdam excepturi harum mollitia
                    voluptatem quis distinctio dolorem aperiam officia ut soluta
                    eveniet nulla in cupiditate id eum!
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CostEstimation;
