import React from 'react';

const SortBy = ({ selectedSortingOption, handleSortChange }) => {
  return (
    <div className="shop-topbar-right">
      <div className="shop-sorting-area">
        <label className="text-dark">
          <b>Sort by :</b>
        </label>
        <select
          className="nice-select nice-select-style-1"
          onChange={handleSortChange}
          value={selectedSortingOption}
        >
          <option value="">--select--</option>
          <option value="lowToHigh">Price: Low to High</option>
          <option value="highToLow">Price: High to Low</option>
          <option value="BestSeller">Best Seller First</option>
          <option value="MostView">Most Viewed First</option>
          <option value="NewProduct">New Products First</option>
        </select>
      </div>
    </div>
  );
};

export default SortBy;
