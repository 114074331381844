import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getUserAddress = createAsyncThunk(
    "user/address",
    async () => {
        const myStoredValue = localStorage.getItem("user");
        try {
            const response = await axios.get(`https://allinonetables.com/api/cart/${myStoredValue}`);
            return response.data;
        } catch (error) {
            console.error(error);
            // toast.error(error.response?.data?.error || "An error occurred");
            throw error;
        }
    }
);

const initialState = {
    data: null,
    error: null,
    loading: false
};

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserAddress.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(getUserAddress.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(getUserAddress.rejected, (state, action) => {
                state.error = action.error;
                state.loading = false;
            });
    },
});

export default addressSlice.reducer;
