import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Password() {
  const Navigate = useNavigate();
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);

  useEffect(() => {
    if (userData == null || userData === undefined || userData == "") {
      Navigate("/login");
    }
  }, []);
  const [data, setData] = useState({
    email: userData?.user?.email,
    oldPass: "",
    newPassword: "",
    confPass: "",
  });
  const ChangePass = (e) => {
    e.preventDefault();
    if (data.newPassword === data.confPass) {
      axios
        .put(`https://allinonetables.com/api/user/updatePass`, data)
        .then((data) => {
          console.log(2000,data);
          toast.success("Password Change Successfully....",{   autoClose: 1500});
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Password Not Same");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  return (
    <>
      {" "}
      <div className="row">
        <div className="col-12">
          <form className="signUpForm">
            <h3 className="text-start">Change Your Password</h3>
            <p>
              You can change your password for security reasons or reset it if
              you forget it.
            </p>
            {/* <label>Current password</label>
            <input
              type="password"
              placeholder=""
              name="oldPass"
              className="border LoginSignupInput"
              onChange={(e) => handleChange(e)}
            /> */}
            <label>New password</label>
            <input
              type="password"
              placeholder=""
              name="newPassword"
              onChange={(e) => handleChange(e)}
              className="border LoginSignupInput"
            />
            <label>Confirm new password</label>
            <input
              type="password"
              name="confPass"
              onChange={(e) => handleChange(e)}
              placeholder=""
              className="border LoginSignupInput"
            />

            <div className="text-end border-top">
              <input
                type="button"
                value="Submit"
                className="w-25 LofinSignupSubmit text-center"
                onClick={(e) => {
                  ChangePass(e);
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Password;
