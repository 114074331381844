import React from 'react'

const Button = ({ text, loadingText ,loading, ...props }) => {
  return (
    <button {...props} className='mt-3 bg-[#e67929] text-white w-full py-2 rounded hover:bg-[#ff8a36]'>
        {loading ? loadingText : text}
    </button>
  )
}

export default Button