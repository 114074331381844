import { Route, Routes } from "react-router-dom";
import LayoutRoutes from "./LayoutRoutes";
import Login from "../components/LoginSignup/Login";
import { ToastContainer } from "react-toastify";
import UserLogin from "../components/LoginSignup/UserLogin";
import UserSignUp from "../components/LoginSignup/UserSignUp";
import '../App.css';
import '../index.css';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFev } from "../store/slices/FavSlices";
import { getCart } from "../store/slices/CardSlice";
import ForgotPassword from "../components/LoginSignup/ForgotPassword";
// import ForgotPassword from "../components/Not";
import NotFound404 from "../components/NotFound404";
import VerifyEmail from "../components/LoginSignup/VerifyEmail";
import VerifiedEmail from "../components/LoginSignup/VerifiedEmail";

const Routers = () => {
  return (
    <>
      <Routes>
        <Route exact path="/login" element={<UserLogin />} />
        <Route exact path="/signUp" element={<UserSignUp />} />
        <Route exact path="/verifyEmail" element={<VerifyEmail />} />
        <Route exact path="/user/verify/:token" element={<VerifiedEmail />} />
        <Route exact path="/forgot-password" element={<ForgotPassword/>} />
        <Route path={`/*`} element={<LayoutRoutes />} />
       
      </Routes>
    </>
  );
};

export default Routers;
